import React, { useState } from "react";
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';
import * as notificationsActions from '../../store/actions/Notifications';

import { Popover } from "@idui/react-popover";

import { track } from "@amplitude/analytics-browser";

const PopoverNotifications = (props) => {  

  let notification = props.notification;
  let theme = props.theme

  const [focus, setFocus] = useState(false);  
  const [show, setShow] = useState(false);  

  const handlerClose = (state) => {
    setShow(state);
    props.setShowBlackScreenDrawer(state);
    setFocus(state); 
    show && track('Notification opened', {Notification_ID: notification?.category})
  }

  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={'click'}
      placement={'bottomRight'}      
      className={`p-0 surface-bg-surface ${theme}`}
      isOpen={show}
      width={200}
      zIndex={10000}      
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 }
      }}
      onChangeOpen={(state) => {handlerClose(state)}}
      withArrow={false} 
      content={
        <div className="column-start w-100 p-2">
          <div className={`row-center align-items-center p-2 rounded-8 pe-pointer ${theme === 'dark' ? 'bg-gray-800-hover' : 'bg-gray-50-hover'}`}
            onClick={() => {
              handlerClose(false);
              props.updateNotificationAsync({
                id: notification.id,
                read: !notification.read
              })
            }}>
            <div className="col-3 row-center">
              <i className={`fa-light fa-check-double txt-8 content-color-green ${theme}`}></i>
            </div>
            <p className={`col-9 label-medium-regular content-default-primary ${theme}`}>{notification.read ? props.language.notifications_notification_set_unread : props.language.notifications_notification_set_read}</p>            
          </div>
          <div className={`row-center align-items-center p-2 rounded-8 pe-pointer mt-2 ${theme === 'dark' ? 'bg-gray-800-hover' : 'bg-gray-50-hover'}`} 
            onClick={() => {
              handlerClose(false);
              props.setVisualization(false);
              props.deleteNotificationAsync(notification.id)
            }}>
            <div className="col-3 row-center">
              <i className={`fa-light fa-trash txt-8 content-color-red ${theme}`}></i>
            </div>
            <p className={`col-9 label-medium-regular content-default-primary ${theme}`}>{props.language.notifications_notification_delete}</p>            
          </div>
        </div>
      }>
      <div className="row-center align-items-center w-100">
        <i className={`fa-light fa-ellipsis-vertical txt-10 content-default-primary pe-pointer ${focus && "zindex-5"} ${theme}`}></i>
      </div>
    </Popover>
  );
};

const mapStateToProps = (state, ownProps) => ({  
  language: state.shared.language,
  notification: ownProps.notification,
  setVisualization: ownProps.setVisualization,
  theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({  
  setShowBlackScreenDrawer: (payload) => dispatch(sharedActions.setShowBlackScreenDrawer(payload)),
  deleteNotificationAsync: (payload) => dispatch(notificationsActions.deleteNotificationAsync(payload)),
  updateNotificationAsync: (payload) => dispatch(notificationsActions.updateNotificationAsync(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PopoverNotifications);