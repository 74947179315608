import React, { useEffect } from "react";
import { connect } from 'react-redux';

import * as servicesActions from '../../../../store/actions/Services';
import * as plusActions from '../../../../store/actions/Plus';

import Header from "../../../shared/Header";
import Graphs from "./Graphs";
import Cards from "./Cards";
import PowerFactor from "./PowerFactor";
import Radar from "../modules/Radar";
import ServicesPlus from "../shared/ServicesContainer";

const QualityEnergy = (props) => {
    const { theme } = props;
    let account = props.account;
    const loadServicesAsync = props.loadServicesAsync;
    const setOpenQualityEnergy = props.setOpenQualityEnergy;    

    useEffect(() => {
        if (!props.activeServicesPlus.length) {
            account && loadServicesAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        if (props.dataGraph.length) {
            props.resetData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activeServicesPlus, account]);

    useEffect(() => { setOpenQualityEnergy(true) }, [setOpenQualityEnergy]);

    return (
        <section className="column-start align-items-center vh-100 w-100">
            <Header moduleName={props.language.plus_radar_title} backToButton={true} />
            <div className="column-center align-items-center flex-grow-1 w-100">
                <ServicesPlus />
            </div>
            <div className="row-between w-100 p-3">
                <div className="column-start align-items-start" style={{ width: 300, minWidth: 300 }}>
                    <Radar indexVariable={2}/>
                    <PowerFactor />
                </div>
                <div className="column-start align-items-start ms-3 pe-3" style={{ width: "calc(100% - 300px)" }}>
                    {/* <Cards /> */}
                    <div className="position-relative w-100 mt-0">
                        <div className={`position-relative w-100 surface-bg-surface border-fill-medium rounded-16 py-3 px-4 ${theme}`}>
                            <Graphs />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    dataGraph: state.plus.dataGraph,
    account: state.accounts.account,
    language: state.shared.language,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
    setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
    setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
    loadServicesAsync: () => dispatch(servicesActions.loadServicesAsync()),
    setOpenQualityEnergy: (payload) => dispatch(plusActions.setOpenQualityEnergy(payload)),
    resetData: () => dispatch(plusActions.resetData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QualityEnergy);