import React from "react"
import { connect } from "react-redux"

import * as energyActions from "../../../store/actions/Energy"

import { Popover } from "@idui/react-popover"

import ContentLoader from "react-content-loader"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { far } from "@fortawesome/pro-regular-svg-icons"
import { fas } from "@fortawesome/pro-solid-svg-icons"

const servicesLoading = (theme) => { return (
  <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader seed={2} backgroundColor={theme === "light" ? "#ffffff" : "#141414"} foregroundColor={theme === "light" ? "#d4d4d4" : "#262626"} style={{ width: "100%", height: "100%" }}>
      <rect x="0" y="0" width="220" height="33" rx="17" ry="17"></rect>
    </ContentLoader>
  </div>
)}

const ActiveServicesEnergy = (props) => {

  let activeServices = props.activeServices
  let setActiveServices = props.setActiveServices
  let showActiveServices = props.showActiveServices
  let showDetailService = props.showDetailService
  let activeServiceDetail = props.activeServiceDetail
  let setActiveServiceDetail = props.setActiveServiceDetail

  const setShowDetailService = props.setShowDetailService

  const { theme } = props;

  return (
    activeServices.length ?
      <div className="row-start align-items-center gap-3">
        {activeServices.slice(0, showActiveServices).map((service) => (
          <Popover
            key={service.idService}
            lazy
            closeOnEnter
            fitMaxHeightToBounds
            fitMaxWidthToBounds
            trigger={"hover"}
            placement={"bottomLeft"}
            arrowColor={"#00000000"}
            className={"bg-black-80 p-0"}
            arrowSize={0}
            offset={[0, 0]}
            mouseEnterDelay={1000}
            openingAnimationTranslateDistance={0}
            animation={{
              animate: { opacity: 1, scale: 1 },
              exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } },
              initial: { opacity: 0, scale: 0.9 },
            }}
            content={
              <div className="w-100">
                <div className="column-start align-items-center">
                  <div className="row-between w-100 align-items-center px-3 py-2">
                    <div className="txt-phrase txt-6 text-nowrap txt-white">{service.name}</div>
                  </div>
                </div>
              </div>
            }>
            <button
              id="active_service"
              key={service.idService}
              className={`row-between h-100 align-items-center pe-pointer rounded-pill gap-3 p-2 max-width-200 min-height-33 surface-bg-on-surface ${theme}`}
              onClick={() => {
                if (!showDetailService) {
                  setShowDetailService(true)
                  setActiveServiceDetail(service)
                } else if (showDetailService && activeServiceDetail?.idService !== service.idService) {
                  setActiveServiceDetail(service)
                } else if (showDetailService && activeServiceDetail?.idService === service.idService) {
                  setShowDetailService(false)
                }
              }}>
              <i className={`far fa-circle-chevron-down txt-8 content-default-tertiary transition-transform ${showDetailService && activeServiceDetail?.idService === service.idService ? "rotate-180" : ""} ${theme}`}></i>
              <p className={`text-nowrap text-truncate label-small-regular content-default-primary ${theme}`}>{service.name}</p>
              {activeServices.length > 1 && (
                <i
                  className={`fal fa-circle-xmark txt-8 content-default-tertiary zindex-5 ${theme}`}
                  onClick={(e) => { 
                    e.stopPropagation()
                    setActiveServices(activeServices.filter((i) => i.idService !== service.idService))
                    if (showDetailService && activeServiceDetail?.idService === service.idService) {
                      setShowDetailService(false)
                    }
                  }}></i>
              )}
            </button>
          </Popover>
        ))}
        {activeServices.length > showActiveServices && (
          <Popover
            lazy
            closeOnEnter
            fitMaxHeightToBounds
            fitMaxWidthToBounds
            trigger={"hover"}
            placement={"bottom"}
            arrowColor={"#161617"}
            className={"bg-black-80 p-0"}
            arrowSize={20}
            offset={[0, 0]}
            openingAnimationTranslateDistance={0}
            animation={{
              animate: { opacity: 1, scale: 1 },
              exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } },
              initial: { opacity: 0, scale: 0.9 },
            }}
            content={
              <div className="w-100 p-3">
                <div className="column-start align-items-center">
                  {activeServices.slice(showActiveServices).map((item) => (
                    <div key={item.idService} className="row-between w-100 align-items-center px-3 py-1">
                      <FontAwesomeIcon icon={far[item.style ? item.style.icon : "faBuilding"]} className="txt-8" color={item.style ? item.style.color : "#cefc34"} />
                      <div className="ms-3 txt-phrase txt-6 text-nowrap" style={{ color: item.style ? item.style.color : "#CEFC34" }}>
                        {item.name}
                      </div>
                      <FontAwesomeIcon
                        icon={fas["faClose"]}
                        className="txt-8 ms-3 pe-pointer"
                        color={item.style ? item.style.color : "#CEFC34"}
                        onClick={() => {
                          props.setActiveServices(activeServices.filter((i) => i.idService !== item.idService))
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            }>
            <div className="row-center align-items-center border-2 border-color-white rounded-pill px-3 py-1 mx-2">
              <i className="fa-solid fa-plus txt-6 txt-white"></i>
              <div className="ms-2 txt-phrase txt-6 txt-white">{activeServices.length - showActiveServices}</div>
            </div>
          </Popover>
        )}
      </div> :
      <div className="position-relative" style={{ height: 33, width: 280 }}>{servicesLoading(theme)}</div>
  )
}

const mapStateToProps = (state) => ({
  language: state.shared.language,
  showDetailService: state.energy.showDetailService,
  theme: state.theme.theme,
})

const mapDispatchToProps = (dispatch) => ({
  setShowDetailService: (payload) => dispatch(energyActions.setShowDetailService(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActiveServicesEnergy)
