import React, { Fragment } from "react";

import TextTooltip from "../../genius/TextTooltip";

import { connect } from "react-redux"


const ButtonCircle = ({
  focus,
  icon,
  onClick,
  background = "surface-bg-on-surface",
  borderColor = "gray-800",
  color = "gray-700",
  borderWidth = 1,
  size = 32,
  sizeIcon = 12,
  additionalStyle = "",
  showPulse = false,
  disabled = false,
  language,
  theme,
}) => {

  return (
    <Fragment>
      {disabled ? (
        <TextTooltip
          bgColor={`surface-bg-surface ${theme}`}
          content={
            <div className="px-3 py-2">
              <p
                className={`rounded-pill py-1 px-2 label-small-regular content-default-primary ${theme}`}
              >
                {language.genius_tooltip_button_graphs}
              </p>
            </div>
          }
          text={
            <button
              disabled={disabled}
              className={`column-center align-items-center position-relative rounded-circle ${
                showPulse && "pulse"
              } ${
                focus ? "zindex-8" : ""
              } content-default-tertiary surface-bg-on-surface-alpha border-fill-light ${additionalStyle} ${theme}`}
              style={{ height: size, width: size }}
              onClick={onClick}
            >
              <i
                className={`button-circle-icon position-absolute text-center fa-light fa-${icon} txt-${sizeIcon} content-default-tertiary ${theme}`}
              ></i>
            </button>
          }
        />
      ) : (
        <button
          disabled={disabled}
          className={`column-center align-items-center position-relative rounded-circle ${
            showPulse && "pulse"
          } ${
            focus ? "zindex-8" : ""
          } content-default-secondary surface-bg-on-surface-alpha border-fill-light ${additionalStyle} ${theme}`}
          style={{ height: size, width: size }}
          onClick={onClick}
        >
          <i
            className={`button-circle-icon position-absolute text-center fa-light fa-${icon} txt-${sizeIcon} content-default-secondary ${theme}`}
          ></i>
        </button>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme.theme,
})

export default connect(mapStateToProps)(ButtonCircle);
