import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as servicesActions from "../../../store/actions/Services";
import * as sharedActions from "../../../store/actions/Shared";
import * as themeActions from "../../../store/actions/Theme";

import { Popover } from "@idui/react-popover";

import ButtonCircle from "../../shared/utilities/ButtonCircle";

import { track } from "@amplitude/analytics-browser";

import { useHistory, useLocation } from "react-router-dom";

const ServicesPopover = (props) => {
  let totalServicesGrouped = props.totalServicesGrouped;
  let activeServices = props.activeServices;
  let servicePicker = props.servicePicker;
  let selectFunction = props.selectFunction;
  let popoverPosition = props.popoverPosition;
  let allowAllServices = props.limitSelectServices == null;
  let limitSelectServices = props.limitSelectServices || 1000;
  let availableTypeServices = props.availableTypeServices;

  const history = useHistory();
  const location = useLocation();

  const setControlledOnboarding = props.setControlledOnboarding;
  const setShowBlackScreen = props.setShowBlackScreen;
  const setShowBlackScreenDrawer = props.setShowBlackScreenDrawer;

  const [focus, setFocus] = useState(false);
  const [services, setServices] = useState(totalServicesGrouped);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [servicesSelected, setServicesSelected] = useState(activeServices);
  const { theme } = props;

  useEffect(() => {
    const filterServices = totalServicesGrouped
      .map((account) => {
        const filteredServices = account.services.filter(
          (service) =>
            service.name.toLowerCase().includes(search.toLowerCase()) &&
            availableTypeServices.includes(service.type?.name),
        );
        return { ...account, services: filteredServices };
      })
      .filter((account) => account.services.length > 0);

    setServices([...filterServices]);
  }, [search, totalServicesGrouped, availableTypeServices]);

  useEffect(() => {
    if (show && servicePicker) {
      setControlledOnboarding({ key: "service_picker", value: true });
    } else {
      setControlledOnboarding({ key: "service_picker", value: false });
    }
  }, [show, setControlledOnboarding, servicePicker]);

  useEffect(() => {
    if (activeServices?.length > 1) {
      track("Project selected", {
        Project_ID: activeServices.map(function (service) {
          return service?.name;
        }),
        Project_Quantity: activeServices?.length,
        Project_Type: activeServices.map(function (service) {
          return service?.type?.name;
        }),
      });
    }
  }, [activeServices]);

  return (
    <Popover
      zIndex={10000}
      lazy
      closeOnEnter
      closeOnRemoteClick={servicePicker ? false : true}
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"click"}
      placement={popoverPosition || "bottomRight"}
      className={`p-0 border-fill-medium surface-bg-surface ${theme}`}
      isOpen={show}
      withArrow={false}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      onChangeOpen={(state) => {
        setShow(state);
        props.setShowBlackScreen(state);
        props.setShowBlackScreenDrawer(state);
        setFocus(state);
        show &&
          track("Project selected", {
            Project_ID: activeServices.map(function (service) {
              return service?.name;
            }),
            Project_Quantity: activeServices?.length,
            Project_Type: activeServices.map(function (service) {
              return service?.type?.name;
            }),
          });
      }}
      content={
        <div className="w-100">
          <div
            className={`column-center py-3 mx-3 gap-3 border-fill-medium-bottom ${theme}`}
          >
            <div
              className={`border-1 border-color-gray-600 sticky-top py-2 px-3 rounded-16 surface-bg-surface ${theme}`}
            >
              <div className="row-start align-items-center">
                <i className="fal fa-search txt-10 txt-gray-650 me-2" />
                <input
                  type="text"
                  className={`border-0 w-100 surface-bg-surface label-small-medium content-default-primary ${theme}`}
                  placeholder={props.language.services_input_label_search}
                  value={search}
                  onChange={({ target }) => setSearch(target.value)}
                />
              </div>
            </div>
          </div>
          <div
            id="services_list"
            className="column-start services-container max-height-270 max-height-320-xl max-height-450-2xl max-width-400 ms-3 py-3"
            style={{ overflowY: "scroll" }}
          >
            {services.map((account) => {
              let allServicesSelected = account.services.every((item) =>
                servicesSelected
                  .map((i) => i.idService)
                  .includes(item.idService),
              );

              return (
                <div
                  key={account.account.idAccount}
                  className="column-start align-items-start gap-1"
                >
                  <div className="w-100 row-start align-items-center gap-2">
                    <div>
                      <p
                        className={`label-small-regular content-default-secondary ${theme}`}
                      >
                        {account.account.name}
                      </p>
                    </div>
                    <div className="row-start flex-grow-1 align-items-center">
                      <div className="flex-grow-1 border-top-1 border-color-gray-600"></div>
                    </div>
                    {allowAllServices && (
                      <div
                        className="pe-pointer"
                        onClick={() => {
                          let services = servicesSelected;

                          if (allServicesSelected) {
                            if (services.length > 1) {
                              services = services.filter(
                                (item) =>
                                  !account.services
                                    .map((i) => i.idService)
                                    .includes(item.idService),
                              );

                              setServicesSelected(services);
                            }
                          } else {
                            services = [...services, ...account.services];

                            if (services.length <= limitSelectServices)
                              setServicesSelected(services);
                          }
                        }}
                      >
                        <p
                          className={`text-end label-small-regular content-color-green ${theme}`}
                        >
                          {!allServicesSelected
                            ? props.language.services_picker_select_all
                            : props.language.services_picker_descelect_all}
                        </p>
                      </div>
                    )}
                  </div>
                  {account.services.map((item) => {
                    const isSelected =
                      servicesSelected.findIndex(
                        (i) => i.idService === item.idService,
                      ) >= 0;

                    return (
                      <div
                        key={item.idService}
                        className={`w-100 row-between align-items-center gap-2 pe-pointer py-2 px-3 my-2 rounded-8 ${
                          isSelected
                            ? "surface-bg-on-surface"
                            : "surface-bg-surface"
                        } ${theme}`}
                        onClick={() => {
                          let services = servicesSelected;

                          if (isSelected) {
                            if (services.length > 1) {
                              services = services.filter(
                                (i) => i.idService !== item.idService,
                              );

                              setServicesSelected(services);
                            }
                          } else {
                            services = [...services, item];

                            if (services.length <= limitSelectServices)
                              setServicesSelected(services);
                          }
                        }}
                      >
                        <div className="col-10 column-start">
                          <div
                            className={`text-truncate w-100 pe-pointer label-small-medium ${
                              isSelected || props.activeServices.length < 3
                                ? "content-default-primary"
                                : "txt-gray-600"
                            } ${theme}`}
                          >
                            {item.name}
                          </div>
                          <div
                            className={`text-truncate pe-pointer label-small-medium ${
                              isSelected || props.activeServices.length < 3
                                ? "content-default-secondary"
                                : "txt-gray-400"
                            } ${theme}`}
                          >
                            {item.address}
                          </div>
                        </div>
                        <div className="row-end align-items-center col-2">
                          <i
                            className={`fal fa-check-circle ${
                              isSelected ? "content-color-green" : "content-default-secondary"
                            } txt-10 ${theme}`}
                          ></i>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div
            className={`row-end py-3 px-1 mx-3 border-fill-medium-top ${theme}`}
          >
            <button
              className={`p-2 rounded-16 label-small-regular button-content-equal button-fill-brand ${theme}`}
              onClick={() => {
                if (servicesSelected.length) selectFunction(servicesSelected);

                setShowBlackScreen(false);
                setShowBlackScreenDrawer(false);

                setShow(false);
                setFocus(false);

                if (
                  props.typeComponent === "chatbot" &&
                  location.pathname === "/home"
                )
                  history.push("home/detail");
              }}
            >
              {props.language.energy_done_button}
            </button>
          </div>
        </div>
      }
    >
      {props.typeComponent === "energy" ? (
        <ButtonCircle
          focus={focus}
          icon={focus ? "circle-xmark" : "circle-plus"}
          showPulse={servicePicker && props.showPulseAnimation}
          theme={theme}
        />
      ) : (
        <div
          className={`chatBot-selector-header row-between align-items-center py-2 px-3 rounded-30 w-100 surface-bg-on-surface-alpha border-fill-medium ${theme}`}
          style={{ zIndex: focus ? 1030 : 0 }}
        >
          <div className="column-start">
            <p className={`label-small-medium content-default-primary ${theme}`}>{`${props.language.lumi_consulting} ${activeServices?.length} ${props.language.lumi_projects}`}</p>
            <p className={`label-XSmall-regular content-default-secondary mt-0 ${theme}`}>
              {props.language.energy_click_to_change}
            </p>
          </div>
          <i
            className={`fal fa-${
              focus ? "chevron-up" : "chevron-down"
            } content-default-primary ${theme} txt-8`}
          ></i>
        </div>
      )}
    </Popover>
  );
};

const mapStateToProps = (state, ownProps) => ({
  account: state.accounts.account,
  servicesTypes: state.services.servicesTypes,
  totalServices: state.services.totalServices,
  totalServicesGrouped: state.services.totalServicesGrouped,
  language: state.shared.language,
  showPulseAnimation: state.shared.showPulseAnimation,
  servicePicker: state.shared.startOnboarding.service_picker,
  selectFunction: ownProps.selectFunction,
  activeServices: ownProps.activeServices,
  limitSelectServices: ownProps.limitSelectServices,
  availableTypeServices: ownProps.availableTypeServices,
  typeComponent: ownProps.typeComponent,
  popoverPosition: ownProps.popoverPosition,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  loadServicesAsync: (payload) =>
    dispatch(servicesActions.loadServicesAsync(payload)),
  setShowBlackScreen: (payload) =>
    dispatch(sharedActions.setShowBlackScreen(payload)),
  setControlledOnboarding: (payload) =>
    dispatch(sharedActions.setControlledOnboarding(payload)),
  setShowBlackScreenDrawer: (payload) =>
    dispatch(sharedActions.setShowBlackScreenDrawer(payload)),
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPopover);
