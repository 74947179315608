import React, { useState } from "react"
import { DateRange } from "react-date-range"
import { es, enUS, pt } from "react-date-range/dist/locale"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file

import { Transition } from "react-transition-group"

import { startOfToday, startOfWeek, endOfWeek, isDate, isSameWeek } from "date-fns"

import { calendarIntervals, setCalendarDates, setCalendarDatesEnergy } from "../../../services/CalendarHandler"
import { Calendar } from "react-calendar"

const defaultRangeDate = [
  {
    id: 1,
    interval: 1,
    labelName: "energy_graph_calendar_week_range",
  },
  {
    id: 2,
    interval: 2,
    labelName: "energy_graph_calendar_month_range",
  },
]

const CalendarRangePicker = (props) => {
  const [previewDates, setPreviewDates] = useState({ startDate: null, endDate: null })

  let show = props.show
  let setShow = props.setShow
  let interval = props.interval
  let setDates = props.setDates
  let setIntervalGraph = props.setIntervalGraph
  let dateRangeEnergy = props.dateRangeEnergy
  let language = props.language
  let theme = props.theme

  return (
    <Transition in={show} timeout={50}>
      {(state) => (
        <>
          {interval < 2 ? (
            <div className={`position-absolute column-start align-items-center top-100 zindex-15 mt-2 trans-fade trans-fade-${state}`} style={{ right: -50 }}>
              <DateRange
                className="energy-calendar"
                locale={language.language_locale === "es" ? es : language.language_locale === "en" ? enUS : pt}
                rangeColors={["rgba(127, 127, 127, 0.8)", "rgba(194, 194, 194, 0.6)", "#ffffff"]}
                dateDisplayFormat="eee. dd, LLLL"
                onChange={(date) => {
                  setDates(setCalendarDatesEnergy(date.selection, interval))
                }}
                showSelectionPreview={false}
                ranges={dateRangeEnergy}
                minDate={new Date("2019-07-01T19:21:00.000Z")}
                maxDate={startOfToday()}
                showDateDisplay={false}
                showPreview={isDate(previewDates.startDate)}
                preview={{ startDate: previewDates.startDate, endDate: previewDates.endDate, color: theme === 'dark' ? "#9ecc14" : "#7da60c" }}
                onPreviewChange={(previewDate) => {
                  if (previewDate === null) return
                  if (interval === 0) {
                    setPreviewDates({ startDate: previewDate, endDate: previewDate })
                  }
                  if (interval === 1) {
                    const startDate = startOfWeek(previewDate, { weekStartsOn: 1 })
                    const endDate = endOfWeek(previewDate, { weekStartsOn: 1 })
                    const previewDateObject = { startDate, endDate }
                    setPreviewDates(previewDateObject)
                  }
                }}
              />
              {interval === 1 && (
                <div id="calendar_fast_access" className={`w-100 px-2 py-3 surface-bg-surface ${theme}`}>
                  <p className={`label-small-medium ps-2 content-default-secondary ${theme}`}>{language.energy_graph_calendar_fast_access}</p>
                  <div className="row-start align-items-center gap-2 pt-2">
                    {defaultRangeDate.map((range) => {
                      const isSelected = range.interval === 1 && isSameWeek(startOfToday(), dateRangeEnergy[0].startDate, { weekStartsOn: 1 })
                      const lastDates = { startDate: startOfToday(), endDate: startOfToday() }

                      return (
                        <button
                          key={range.id}
                          className={`row-center align-items-center py-1 px-3 rounded-pill surface-bg-on-surface-alpha ${theme} ${isSelected ? "border-fill-medium pe-default" : "pe-pointer opacity-5"}`}
                          disabled={isSelected}
                          onClick={() => {
                            setIntervalGraph(range.interval)
                            setDates(setCalendarDatesEnergy(lastDates, range.interval))
                          }}>
                          <p className={`label-XSmall-regular ${isSelected ? "content-default-tertiary" : "content-default-primary"} ${theme} text-nowrap`}>{language[range.labelName]}</p>
                        </button>
                      )
                    })}
                  </div>
                </div>
              )}
              <div className={`d-flex w-full pb-3 pe-2 justify-content-end surface-bg-surface ${theme}`} style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
                <button className={`p-2 rounded-16 button-fill-brand button-content-equal label-small-regular ${theme}`} onClick={() => setShow(false)}>
                  {language.energy_graph_calendar_button}
                </button>
              </div>
            </div>
          ) : (
            <div className={`position-absolute column-start align-items-center top-80 -right-20 zindex-6 trans-fade trans-fade-${state}`} style={{ width: 300, right: -50 }}>
              <Calendar
                next2Label={null}
                prev2Label={null}
                value={dateRangeEnergy[0].startDate}
                minDetail={calendarIntervals[interval]}
                maxDetail={calendarIntervals[interval]}
                maxDate={new Date()}
                minDate={new Date("2019-07-01T19:21:00.000Z")}
                className={`surface-bg-surface txt-phrase txt-8 p-2 rounded-8 ${theme}`}
                nextLabel={<div className={`column-center align-items-center surface-bg-on-surface rounded-30 p-2 ${theme}`} style={{width: 30, height: 30}}><i className={`fa-regular fa-circle-chevron-right txt-10 content-default-tertiary pe-pointer ${theme}`}></i></div>}
                prevLabel={<div className={`column-center align-items-center surface-bg-on-surface rounded-30 p-2 ${theme}`} style={{width: 30, height: 30}}><i className={`fa-regular fa-circle-chevron-left txt-10 content-default-tertiary pe-pointer ${theme}`}></i></div>}
                onChange={(value) => {
                  setDates(setCalendarDates(value, interval))
                }}
              />
            </div>
          )}
        </>
      )}
    </Transition>
  )
}

export default CalendarRangePicker
