import * as creators from '../creators/Invoices';

import { addMonths } from 'date-fns';

const initialState = {
    invoices: [],
    invoicesSummary: {
        total_bills: 0,
        pending_bills: 0,
        total_consumption: 0,
        total_payment: 0,
        id_bills_array: []
    },
    invoicesSummaryLoading: false,
    invoicesLoading: true,
    delayedInvoicesLoading: true,
    moreInvoicesLoading: false,
    invoicesDate: addMonths(new Date(), -1),
    selectedInvoices: [],
    banks: [],
    invoicesToPay: [],
    invoicesContent: [],
    invoicesContentLoading: true,
    invoiceDetails: null,
    invoiceDetailsContent: null,
    invoiceDetailsIndex: 0,
    invoicesPeriods: [],
    invoicesPeriodsFiltered: [],
    billsStatus: [
        {
            id: 'paid',
            name: 'Pagada',
            color: 'green',
            text_color: 'green-500'
        },
        {
            id: 'pending',
            name: 'Pendiente',
            color: 'orange',
            text_color: 'orange-200'
        },
        {
            id: 'overdue',
            name: 'Vencida',
            color: 'red',
            text_color: 'red-200'
        }
    ],
    filterStatus: null,
    totalInvoicesByPeriod: [],
    currentPage: 0,
    sortField: 'date_expiration',
    sortOrder: 0,
    showPay: false,
    emptyPaginationResult: false
}

export const invoicesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_INVOICES:
            return { ...state, invoices: payload };
        case creators.SET_INVOICES_SUMMARY:
            return { ...state, invoicesSummary: payload };
        case creators.SET_INVOICES_SUMMARY_LOADING:
            return { ...state, invoicesSummaryLoading: payload };
        case creators.SET_MORE_INVOICES:
            return { ...state, invoices: [...state.invoices, ...payload] };
        case creators.UPDATE_INVOICES:
            const newInvoices = state.invoices.map(currentInvoice => {
                const foundInvoice = payload.find(i => {
                    return (i.id_bill === currentInvoice.id_bill)
                })
                if(!foundInvoice) return currentInvoice;
                return { 
                    ...currentInvoice, 
                    ...foundInvoice,
                    loadingDelay: false
                }
            })
            return { ...state, invoices: newInvoices };
        case creators.SET_INVOICES_LOADING:
            return { ...state, invoicesLoading: payload };
        case creators.SET_DELAYED_INVOICES_LOADING:
            return { ...state, delayedInvoicesLoading: payload };
        case creators.SET_MORE_INVOICES_LOADING:
            return { ...state, moreInvoicesLoading: payload };
        case creators.SET_INVOICES_DATE:
            return { ...state, invoicesDate: payload };
        case creators.SET_SELECTED_INVOICES:
            return { ...state, selectedInvoices: payload };
        case creators.SET_INVOICES_BANKS:
            return { ...state, banks: payload };
        case creators.SET_INVOICES_TO_PAY:
            return { ...state, invoicesToPay: payload };
        case creators.SET_INVOICES_CONTENT:
            return { ...state, invoicesContent: payload };
        case creators.SET_INVOICES_CONTENT_LOADING:
            return { ...state, invoicesContentLoading: payload };
        case creators.SET_SHOW_INVOICE_DETAILS:
            return { ...state, invoiceDetails: payload };
        case creators.SET_SHOW_INVOICE_DETAILS_CONTENT:
            return { ...state, invoiceDetailsContent: payload };
        case creators.SET_SHOW_INVOICE_DETAILS_INDEX:
            return { ...state, invoiceDetailsIndex: payload };
        case creators.SET_INVOICES_PERIODS:
            return { ...state, invoicesPeriods: payload };
        case creators.SET_INVOICES_FILTER_STATUS:
            let invoicesPeriodsFiltered = state.invoicesPeriods.filter(ip => {
                switch (payload) {
                    case 'pending':
                        return ip.pending_bills > 0
                    case 'paid':
                        return ip.paid_bills > 0
                    case 'overdue':
                        return ip.due_bills > 0
                    default:
                        return null;
                }
            });

            return { ...state, filterStatus: payload, invoicesPeriodsFiltered };
        case creators.SET_INVOICES_TOTAL_INVOICES_BY_PERIOD:
            return { ...state, totalInvoicesByPeriod: payload };
        case creators.SET_INVOICES_CURRENT_PAGE:
            return { ...state, currentPage: payload };
        case creators.SET_INVOICES_SORT_FIELD:
            return { ...state, sortField: payload };
        case creators.SET_INVOICES_SORT_ORDER:
            return { ...state, sortOrder: payload };
        case creators.SET_SHOW_PAY:
            return { ...state, showPay: payload };
        case creators.SET_EMPTY_PAGINATION_RESULT:
            return { ...state, emptyPaginationResult: payload };
        default:
            return state;
    }
}
