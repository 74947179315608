import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { track } from '@amplitude/analytics-browser';

import * as paysActions from '../../../store/actions/Pays';
import * as sharedActions from '../../../store/actions/Shared';
import * as invoicesActions from '../../../store/actions/Invoices';

import { Alert, Drawer, Loader } from 'rsuite';

import CurrencyFormat from 'react-currency-format';

import PaymentMethod from './PaymentMethod';
import CardPayment from './CardPayment';
import PsePayment from './PsePayment';
import BackButton from '../../shared/utilities/BackButton';

const Component = ({ type, setEnable, totalPayment }) => {

    switch ((type || 'METHOD').toUpperCase()) {
        case 'CARD':
            return <CardPayment setEnable={setEnable} totalPayment={totalPayment} />
        case 'PSE':
            return <PsePayment setEnable={setEnable} totalPayment={totalPayment} />
        default:
            return <PaymentMethod />
    }

}

const Pay = (props) => {

    let _invoices = props._invoices;
    let infoPayment = props.infoPayment;
    let typeComponent = props.typeComponent;
    let paymentMethods = props.paymentMethods;
    let activeServicesInvoices = props.activeServicesInvoices
    let pseProvider = props.paymentProvider;
    let showPay = props.showPay;
    let invoicesSummary = props.invoicesSummary;
    let selectAll = props.selectAll;
    let theme = props.theme;
    let notSelectedPedingInvoices = _invoices.filter(currentInvoice => !currentInvoice.loadingDelay && currentInvoice.state === 0 && !currentInvoice.isSelected)
    let selectedPedingInvoicesArray = _invoices.filter(currentInvoice => !currentInvoice.loadingDelay && currentInvoice.state === 0 && currentInvoice.isSelected)
    let dynamicSelectedPedingInvoices = selectAll ? (invoicesSummary.total_bills - notSelectedPedingInvoices.length) : (selectedPedingInvoicesArray.length);

    let totalPayment = selectAll ? invoicesSummary.total_payment : selectedPedingInvoicesArray.reduce((accum, i) => accum + parseFloat(i.total_payment), 0);

    const setCardPaymentAsync = props.setCardPaymentAsync;
    const setTypeComponent = props.setTypeComponent;
    const setPsePaymentAsync = props.setPsePaymentAsync;
    const loadPaymentMethodsAsync = props.loadPaymentMethodsAsync;

    const [isEnable, setEnable] = useState(false);
    const [loadingOpenPay, setLoadingOpenPay] = useState(false);

    const isValidateEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const isValidatePseString = val => /^[ñíóáéú a-zA-Z ]+$/.test(val);

    const isValidateCardString = val => /^(?=.*[a-zA-Zñíóáéú])[ñíóáéú a-zA-Z]+$/.test(val);

    const handleClose = () => {
        if (typeComponent === 'METHOD') {
            props.setStartOnboarding({ key: 'payment', value: false })
            props.setShowPay(false);
        } else {
            setTypeComponent('METHOD')
        }
    }

    const isValidUserByCard = (data) => {
        if (data.phone === null || data.phone === "") return false;
        if (data.address === null || data.address === "") return false;
        if (data.id_document === null || data.id_document === "") return false;
        if (data.card_name === null || data.card_name === "" || !isValidateCardString(data.card_name)) {
            return false;
        }
        if (data.card_number === null || data.card_number === "") return false;
        if (data.card_expiration_month === null || data.card_expiration_month === "") return false;
        if (data.card_expiration_year === null || data.card_expiration_year === "") return false;
        if (data.card_cvv === null || data.card_cvv === "") return false;
        return true;
    }

    const isValidUserByPse = (user, pseProvider) => {
        if (user.name === null || user.name === "" || !isValidatePseString(user.name)) return false;
        if (!isValidateEmail(user.email)) return false;
        if (user.phone === null || user.phone === "") return false;
        if (user.address === null || user.address === "") return false;
        if (user.id_document === null || user.id_document === "") return false;
        if (
            (user.financial_institution_code === null || user.financial_institution_code === "0")
            && pseProvider === 'payvalida'
        ) return false;
        return true;
    }

    const isValidUser = (infoPayment) => {
        switch (typeComponent) {
            case 'CARD':
                return isValidUserByCard(infoPayment)
            case 'PSE':
                return isValidUserByPse()
            default:
                return false;
        }
    }

    const showPseError = (user, props) => {

        if (user.name === null || user.name === "" || !isValidatePseString(user.name)) {
            Alert.error(props.language.alert_user_popup_name);
            return;
        }

        if (user.address === null || user.address === "") {
            Alert.error(props.language.alert_user_popup_address);
            return;
        }

        if (!isValidateEmail(user.email)) {
            Alert.error(props.language.alert_user_popup_email);
            return;
        }

        if (user.phone === null || user.phone === "") {
            Alert.error(props.language.alert_user_popup_phone);
            return;
        }

        if (user.id_document === null || user.id_document === "") {
            Alert.error(props.language.alert_user_popup_id_document);
            return;
        }

        if (user.financial_institution_code === null || user.financial_institution_code === "0") {
            Alert.error(props.language.alert_pay_popup_banks);
            return;
        }

    }

    const showCardError = (data, props) => {
        if (data.address === null || data.address === "") {
            Alert.error(props.language.alert_user_popup_address);
            return;
        }

        if (data.phone === null || data.phone === "") {
            Alert.error(props.language.alert_user_popup_phone);
            return;
        }

        if (data.id_document === null || data.id_document === "") {
            Alert.error(props.language.alert_user_popup_id_document);
            return;
        }

        if (data.card_name === null || data.card_name === "" || !isValidateCardString(data.card_name)) {
            Alert.error(props.language.alert_pay_popup_card_name);
            return;
        }

        if (data.card_number === null || data.card_number === "") {
            Alert.error(props.language.alert_pay_popup_card_number);
            return;
        }

        if (data.card_expiration_month === null || data.card_expiration_month === "") {
            Alert.error(props.language.alert_pay_popup_card_expiration_month);
            return;
        }

        if (data.card_expiration_year === null || data.card_expiration_year === "") {
            Alert.error(props.language.alert_pay_popup_card_expiration_year);
            return;
        }

        if (data.card_cvv === null || data.card_cvv === "") {
            Alert.error(props.language.alert_pay_popup_card_cvv);
            return;
        }
    }

    // eslint-disable-next-line
    useEffect(() => setEnable(isValidUser(infoPayment)), [setEnable, infoPayment, typeComponent]);

    useEffect(() => {
        if (!paymentMethods.length) {
            loadPaymentMethodsAsync();
        }
    }, [paymentMethods, loadPaymentMethodsAsync]);

    return (
        <Drawer
            show={showPay}
            onHide={() => handleClose()}
            size={'xs'}
            className='invoices-pay-drawer'
            placement={'right'}
            backdropClassName={`surface-bg-blackout pe-pointer ${theme}`}>
            <div id='payment' className={`column-between align-items-center h-100 w-100 overflow-auto surface-bg-surface border-fill-light ${theme}`}>
                <div className={`w-100 p-3`}>
                    <div className='row-between align-items-center gap-2 w-100 max-height-100'>
                        <div className='row-between align-items-center w-100'>
                            <BackButton theme={theme} onClick={() => handleClose()}></BackButton>
                            <div className={`label-large-semibold content-default-primary text-wrap text-left ${theme}`}>{typeComponent === 'METHOD' ? props.language.invoices_card_payment_methods : 'Pago de facturas'}</div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex-grow-1 overflow-auto w-100"
                >
                    <Component type={typeComponent} setEnable={setEnable} totalPayment={totalPayment} />
                </div>
                <div className={`w-100`}>
                    <div className="row-between align-items-center py-3 px-4 w-100">
                        <p className={`label-medium-semibold content-default-primary ${theme}`}>Facturas a pagar</p>
                        <span className={`px-3 py-1 border-color-green content-color-green bg-alpha-primary-brand-32 rounded-pill ${theme}`}>{dynamicSelectedPedingInvoices} facturas</span>
                    </div>
                    <div className="w-100 px-4">
                        <div className={`w-100 border-fill-regular-top ${theme}`} />
                    </div>
                    <div className={`row-${ typeComponent === 'METHOD' ? 'center' : 'between'} align-items-center py-3 px-4 w-100`}>
                        <div className={`flex-gow-1 column-center align-items-${typeComponent === 'METHOD' ? 'center' : 'start'}`}>
                            <p className={`mb-1 label-small-semibold content-default-secondary ${theme}`}>
                                <i className={`far fa-circle-bolt me-2 txt-8 content-default-secondary ${theme}`}></i>
                                Pago total
                            </p>
                            <div className="row-center align-items-center gap-2">
                                <CurrencyFormat
                                    className={`content-color-green heading-h5-semibold ${theme}`}
                                    value={typeComponent !== 'METHOD' ? totalPayment / (1 - infoPayment.commission) : totalPayment}
                                    prefix="$"
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                                <p className={`label-small-regular content-color-green ${theme}`}>
                                    COP
                                </p>
                            </div>
                        </div>
                        {
                            typeComponent !== 'METHOD' && (
                                <button
                                    className={`row-center gap-2 align-items-center pe-pointer py-2 px-3 button-fill-brand rounded-pill ${theme}`}
                                    onClick={() => {
                                        if (isEnable) {
                                            setLoadingOpenPay(true);
                                            if (typeComponent.toUpperCase() === 'CARD') {
                                                setCardPaymentAsync({ id_bills_array: invoicesSummary.id_bills_array })
                                            } else {
                                                setPsePaymentAsync({ pseProvider, id_bills_array: invoicesSummary.id_bills_array })
                                            }
                                            track('Bills payed', {
                                                Payment_Method: typeComponent,
                                                Bill_Quantity: activeServicesInvoices.map((service) => service?.name).length
                                            })
                                        } else {
                                            if (typeComponent.toUpperCase() === 'CARD') {
                                                showCardError(infoPayment, props)
                                            } else {
                                                showPseError(infoPayment, props)
                                            }
                                        }
                                    }}
                                >
                                    {
                                        loadingOpenPay ? (
                                            <>
                                                <Loader inverse size="xs" />
                                                <p className={`label-medium-regular button-content-equal pe-pointer ${theme}`}>
                                                    Cargando
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <i className={`fal fa-wallet button-content-equal txt-8 ${theme}`}></i>
                                                <p className={`label-small-regular button-content-equal ${theme}`}>Pagar</p>
                                            </>
                                        )
                                    }
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    infoPayment: state.pays.infoPayment,
    paymentMethods: state.pays.paymentMethods,
    typeComponent: state.pays.typeComponent,
    paymentProvider: state.pays.paymentProvider,
    showPay: state.invoices.showPay,
    invoicesSummary: state.invoices.invoicesSummary,
    language: state.shared.language,
    payment: state.shared.startOnboarding.payment,
    activeServicesInvoices: state.services.activeServicesInvoices,
    user: state.users.user,
    invoicesDate: state.invoices.invoicesDate,
    theme: state.theme.theme,
    ...ownProps
})

const mapDispatchToProps = dispatch => ({
    loadPaymentMethodsAsync: () => dispatch(paysActions.loadPaymentMethodsAsync()),
    setTypeComponent: (payload) => dispatch(paysActions.setTypeComponent(payload)),
    setPsePaymentAsync: (payload) => dispatch(paysActions.setPsePaymentAsync(payload)),
    setCardPaymentAsync: (payload) => dispatch(paysActions.setCardPaymentAsync(payload)),
    setStartOnboarding: (payload) => dispatch(sharedActions.setStartOnboarding(payload)),
    setShowPay: (payload) => dispatch(invoicesActions.setShowPay(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Pay)