import React from "react";
import { connect } from "react-redux";

import * as themeActions from "../../../store/actions/Theme";


const Input = ({ label, value = "", type, required, help = null, disabled = false, onChange, onPressEnter, focus = false, error, theme, placeholder = '' }) => {
    return (
        <div className='column-start w-100 gap-2'>
            {
              label && (
                <div className="w-100 row-between align-items-center">
                  <label htmlFor="inputField" className={`row-start label-small-medium content-default-primary ${theme}`}>
                    {label}
                    {required ? <span className={`ms-2 content-color-green ${theme}`}>*</span> : <></>}
                  </label>
                  {
                    help ? (
                      <p className={`content-color-green paragraph-XSmall-regular ${theme}`}>{ help }</p>
                    ) : <></>
                  }
                </div>
              )
            }
            <div className="w-100">
                <input disabled={disabled} type={type} autoFocus={focus} className={`w-100 py-2 px-3 rounded-30 border-fill-medium surface-bg-surface label-small-medium content-default-secondary ${theme}`} id="inputField" placeholder={placeholder} required={required} value={value ?? ""} onChange={(e) => onChange(e)} onClick={(e) => e.stopPropagation()}  onKeyDown={({ keyCode }) => keyCode === 13 && onPressEnter && onPressEnter()} />
            </div>
            {error ? <p className='txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap'>{error}</p> : null}
        </div>
    );
}

const mapStateToProps = (state) => ({
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Input);
