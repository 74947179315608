export const SET_INVOICES = 'SET_INVOICES';
export const SET_INVOICES_SUMMARY = 'SET_INVOICES_SUMMARY';
export const SET_INVOICES_SUMMARY_LOADING = 'SET_INVOICES_SUMMARY_LOADING';
export const SET_MORE_INVOICES = 'SET_MORE_INVOICES';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';
export const SET_INVOICES_LOADING = 'SET_INVOICES_LOADING';
export const SET_DELAYED_INVOICES_LOADING = 'SET_DELAYED_INVOICES_LOADING';
export const SET_MORE_INVOICES_LOADING = 'SET_MORE_INVOICES_LOADING';
export const SET_INVOICES_DATE = 'SET_INVOICES_DATE';
export const SET_SELECTED_INVOICES = 'SET_SELECTED_INVOICES';
export const SET_INVOICES_BANKS = "SET_INVOICES_BANKS";
export const SET_INVOICES_TO_PAY = "SET_INVOICES_TO_PAY";
export const SET_INVOICES_CONTENT = "SET_INVOICES_CONTENT";
export const SET_INVOICES_CONTENT_LOADING = "SET_INVOICES_CONTENT_LOADING";
export const SET_SHOW_INVOICE_DETAILS = "SET_SHOW_INVOICE_DETAILS";
export const SET_SHOW_INVOICE_DETAILS_CONTENT = "SET_SHOW_INVOICE_DETAILS_CONTENT";
export const SET_SHOW_INVOICE_DETAILS_INDEX = "SET_SHOW_INVOICE_DETAILS_INDEX";
export const SET_INVOICES_PERIODS = "SET_INVOICES_PERIODS";
export const SET_INVOICES_FILTER_STATUS = "SET_INVOICES_FILTER_STATUS";
export const SET_INVOICES_TOTAL_INVOICES_BY_PERIOD = "SET_INVOICES_TOTAL_INVOICES_BY_PERIOD";
export const SET_INVOICES_CURRENT_PAGE = "SET_INVOICES_CURRENT_PAGE";
export const SET_INVOICES_SORT_FIELD = "SET_INVOICES_SORT_FIELD";
export const SET_INVOICES_SORT_ORDER = "SET_INVOICES_SORT_ORDER";
export const SET_SHOW_PAY = "SET_SHOW_PAY";
export const SET_EMPTY_PAGINATION_RESULT = "SET_EMPTY_PAGINATION_RESULT";