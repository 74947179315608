import React from "react";

import { connect } from "react-redux";

import ContentLoader from "react-content-loader";

import PseIconDark from '../../imgs/pse-logo-dark.svg'
import PseIconLight from '../../imgs/pse-logo-light.svg'
import VisaIconDark from '../../imgs/visa-logo-dark.svg'
import VisaIconLight from '../../imgs/visa-logo-light.svg'

import * as invoicesActions from "../../store/actions/Invoices";
import { themes } from "../../utils/enums";
import { track } from "@amplitude/analytics-browser";

const InvoiceSummary = (props) => {

    let theme = props.theme;
    let _invoices = props.invoices;
    let invoicesSummary = props.invoicesSummary;
    let invoicesSummaryLoading = props.invoicesSummaryLoading;
    let invoicesLoading = props.invoicesLoading;
    let selectAll = props.selectAll;
    let allAccountsSelected = props.allAccountsSelected;
    let filterPendingInvoices = props.filterPendingInvoices;
    let notSelectedPedingInvoices = _invoices.filter(currentInvoice => !currentInvoice.loadingDelay && currentInvoice.state === 0 && !currentInvoice.isSelected).length
    let selectedPedingInvoicesArray = _invoices.filter(currentInvoice => !currentInvoice.loadingDelay && currentInvoice.state === 0 && currentInvoice.isSelected)
    let selectedPedingInvoices = selectedPedingInvoicesArray.length
    let totalConsumption = selectAll ? invoicesSummary.total_consumption : selectedPedingInvoicesArray.reduce((accum, i) => accum + parseFloat(i.consumption), 0);
    let totalPayment = selectAll ? invoicesSummary.total_payment : selectedPedingInvoicesArray.reduce((accum, i) => accum + parseFloat(i.total_payment), 0);
    let dynamicPedingInvoices = selectAll ? notSelectedPedingInvoices : invoicesSummary.total_bills - selectedPedingInvoices
    let dynamicSelectedPedingInvoices = selectAll ? (invoicesSummary.total_bills - notSelectedPedingInvoices) : (selectedPedingInvoices);

    const toggleSelectAll = props.toggleSelectAll;
    const setShowPay = props.setShowPay;
    const handleFilterPendingInvoices = props.handleFilterPendingInvoices;

    const formatNumber = (value) => {
        if (value === 0 || value === undefined || value === null) return "0";
        const string_value = value?.toString();
        if (!string_value.includes(".")) {
            return string_value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        const values_part = string_value.split(".");
        const integerValue = values_part[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const decimalValue = values_part[1].slice(0, 2);
        return `${integerValue}.${decimalValue}`;
    };

    return (
        <div className="row-between align-items-center w-100 gap-3 mb-3">
            {
                !invoicesLoading ? (
                    <div className={`column-start py-3 w-75 surface-bg-surface rounded-16 border-fill-medium ${theme}`} style={{ height: '220px' }}>
                        {
                            !invoicesSummaryLoading ? (
                                <>
                                    <div className="row-between align-items-center w-100 px-3">
                                        <p className={`label-large-semibold content-default-primary ${theme}`}>Resumen</p>
                                        <p className={`label-small-medium content-color-green pe-pointer ${theme}`} onClick={() => { dynamicPedingInvoices === 0 && track('Select all bills button clicked'); toggleSelectAll()}}>{dynamicSelectedPedingInvoices > 0 ? 'Deseleccionar todos los proyectos' : 'Seleccionar todos los proyectos'}</p>
                                    </div>
                                    {
                                        !allAccountsSelected ? (
                                            <p className={`px-3 paragraph-XSmall-regular content-default-secondary ${theme}`}>{props.language.invoices_summary_account_filter_alert_before_bold}<span className="txt-bold">{props.language.invoices_summary_account_filter_alert_bold}</span>{props.language.invoices_summary_account_filter_alert_after_bold}</p>
                                        ) : <></>
                                    }
                                </>
                            )
                                : <></>
                        }
                        <div className="w-100 flex-grow-1 row-between pt-3 px-3 gap-3">
                            {
                                invoicesSummaryLoading ? (
                                    <>
                                        <div className="position-relative w-100 h-100">
                                            <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
                                                <ContentLoader seed={2} backgroundColor={theme === themes.LIGHT ? "#ffffff" : "#141414"} foregroundColor={theme === themes.LIGHT ? "#d4d4d4" : "#262626"} style={{ width: "100%", height: "100%" }}>
                                                    <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
                                                </ContentLoader>
                                            </div>
                                        </div>
                                        <div className="position-relative w-100 h-100">
                                            <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
                                                <ContentLoader seed={2} backgroundColor={theme === themes.LIGHT ? "#ffffff" : "#141414"} foregroundColor={theme === themes.LIGHT ? "#d4d4d4" : "#262626"} style={{ width: "100%", height: "100%" }}>
                                                    <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
                                                </ContentLoader>
                                            </div>
                                        </div>
                                        <div className="position-relative w-100 h-100">
                                            <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
                                                <ContentLoader seed={2} backgroundColor={theme === themes.LIGHT ? "#ffffff" : "#141414"} foregroundColor={theme === themes.LIGHT ? "#d4d4d4" : "#262626"} style={{ width: "100%", height: "100%" }}>
                                                    <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
                                                </ContentLoader>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={`column-center align-items-center surface-bg-on-surface rounded-16 gap-2 p-3 w-100 h-100 ${theme}`}>
                                            <div className={`row-start align-items-center gap-2`}>
                                                <i className={`far fa-circle-bolt txt-8 ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary': 'content-color-green'} ${theme}`}></i>
                                                <p className={`label-small-semibold ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-color-green'} ${theme}`}>{props.language.invoices_selected_invoices_summary_title}</p>
                                            </div>
                                            <div className="row-start align-items-center gap-2">
                                                <p className={`heading-h6-semibold m-0 ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-default-primary'} ${theme}`}>{dynamicSelectedPedingInvoices}</p>
                                                <p className={`label-small-regular m-0 ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-default-secondary'} ${theme}`}>facturas</p>
                                            </div>
                                        </div>
                                        <div className={`column-center align-items-center surface-bg-on-surface hover-gray rounded-16 gap-2 p-3 w-100 h-100 pe-pointer ${theme}`} onClick={() =>{ track('Pending bills button selected'); handleFilterPendingInvoices(!filterPendingInvoices)}}>
                                            <div className="row-start align-items-center gap-2">
                                                <i className={`far fa-circle-bolt txt-8 ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-default-primary'} ${theme}`}></i>
                                                <p className={`label-small-semibold ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-default-primary'} ${theme}`}>{props.language.invoices_pending_invoices_summary_title}</p>
                                            </div>
                                            {
                                                invoicesSummary.total_bills !== 0 && dynamicSelectedPedingInvoices === 0 ? (
                                                    <div className="row-center gap-3">
                                                        <div className="column-start align-items-center gap-2">
                                                            <div className={`py-1 px-3 bg-alpha-orange-32 border-color-orange content-color-orange rounded-pill ${theme}`}>
                                                                {props.language.invoices_state_pending}
                                                            </div>
                                                            <div className="row-start align-items-center gap-2">
                                                                <p className={`label-medium-semibold content-default-primary m-0 ${theme}`}>{invoicesSummary.pending_bills}</p>
                                                                <p className={`label-small-regular content-default-secondary m-0 ${theme}`}>facturas</p>
                                                            </div>
                                                        </div>
                                                        <div className="column-start align-items-center gap-2">
                                                            <div className={`py-1 px-3 bg-alpha-red-32 border-color-red content-color-red rounded-pill ${theme}`}>
                                                                {props.language.invoices_state_overdue}
                                                            </div>
                                                            <div className="row-start align-items-center gap-2">
                                                                <p className={`label-medium-semibold content-default-primary m-0 ${theme}`}>{invoicesSummary.total_bills - invoicesSummary.pending_bills}</p>
                                                                <p className={`label-small-regular content-default-secondary m-0 ${theme}`}>facturas</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : invoicesSummary.total_bills !== 0 && dynamicPedingInvoices === 0 ? (
                                                    <div className="row-start align-items-center gap-2">
                                                        <p className={`px-3 text-center paragraph-XSmall-regular content-default-secondary ${theme}`}>{props.language.invoices_empty_summary_pending_invoices}</p>
                                                    </div>
                                                ) : (
                                                    <div className="row-start align-items-center gap-2">
                                                        <p className={`heading-h6-semibold m-0 ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-default-primary'} ${theme}`}>{dynamicPedingInvoices}</p>
                                                        <p className={`label-small-regular m-0 ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-default-secondary'} ${theme}`}>facturas</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className={`column-center align-items-center surface-bg-on-surface rounded-16 gap-2 p-3 w-100 h-100 ${theme}`}>
                                            <div className="row-start align-items-center gap-2">
                                                <i className={`far fa-circle-bolt txt-8 ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary': 'content-color-green'} ${theme}`}></i>
                                                <p className={`label-small-semibold ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-color-green'} ${theme}`}>Consumo total seleccionado</p>
                                            </div>
                                            <div className="row-start align-items-center gap-2">
                                                <p className={`heading-h6-semibold m-0 ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-default-primary'} ${theme}`}>{totalConsumption < 0 ? 0 : formatNumber(totalConsumption)}</p>
                                                <p className={`label-small-regular m-0 ${invoicesSummary.total_bills === 0 ? 'content-default-tertiary' : 'content-default-secondary'} ${theme}`}>kWh</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <div className="position-relative w-75" style={{ height: '220px' }}>
                        <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
                            <ContentLoader seed={2} backgroundColor={theme === themes.LIGHT ? "#ffffff" : "#141414"} foregroundColor={theme === themes.LIGHT ? "#d4d4d4" : "#262626"} style={{ width: "100%", height: "100%" }}>
                                <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
                            </ContentLoader>
                        </div>
                    </div>
                )
            }
            {
                invoicesLoading || invoicesSummaryLoading ? (
                    <div className="position-relative w-25" style={{ height: '220px' }}>
                        <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
                            <ContentLoader seed={2} backgroundColor={theme === themes.LIGHT ? "#ffffff" : "#141414"} foregroundColor={theme === themes.LIGHT ? "#d4d4d4" : "#262626"} style={{ width: "100%", height: "100%" }}>
                                <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
                            </ContentLoader>
                        </div>
                    </div>
                ) : <></>
            }
            {
                !invoicesLoading && !invoicesSummaryLoading && invoicesSummary.total_bills === 0 ? (
                    <div className="column-center align-items-center px-4 py-4 gap-3 w-25 rounded-16" style={{ height: '220px' }}>
                        <div className={`position-relative row-center align-items-center p-4 surface-bg-on-surface rounded-pill border-fill-light ${theme}`}>
                            <i className={`position-absolute fal fa-face-laugh-beam txt-14 content-color-green ${theme}`} />
                        </div>
                        <p className={`paragraph-XSmall-regular content-color-green ${theme}`}>
                            <b>!Bien hecho!</b> Estas al día con el pago de tus facturas. Hasta hoy, no tienes deudas en tu factura de energía y eso nos llena de felicidad. !Gracias por tu compromiso!
                        </p>
                    </div>
                ) : <></>
            }
            {
                !invoicesLoading && !invoicesSummaryLoading && invoicesSummary.total_bills !== 0 ? (
                    <div className={`column-between align-items-center px-3 py-3 gap-2 w-25 rounded-16 ${dynamicSelectedPedingInvoices !== 0 ? 'border-color-green' : 'border-fill-regular'} ${theme}`} style={{ height: '220px' }}>
                        <div className="flex-grow-1 column-center align-items-center gap-1">
                            <div className="row-start align-items-center gap-2 opacity-60">
                                <i className={`far fa-circle-bolt txt-8 content-default-secondary ${theme}`}></i>
                                <p className={`label-small-semibold content-default-secondary ${theme}`}>Pago total</p>
                            </div>
                            <div className="row-start align-items-center gap-2">
                                {totalPayment <= 0 && <p className={`heading-h5-semibold content-default-primary m-0 ${theme}`}>$0</p>}
                                {totalPayment !== 0 && <p className={`heading-h5-semibold content-default-primary m-0 ${theme}`}>${formatNumber(totalPayment)}</p>}
                                <p className={`label-small-regular content-default-secondary m-0 ${theme}`}>COP</p>
                            </div>
                        </div>
                        <div className="flex-grow-1 column-center align-items-center gap-3">
                            <button
                                className={`row-center gap-2 align-items-center py-2 px-3 rounded-pill ${dynamicSelectedPedingInvoices === 0 ? 'surface-bg-on-surface' : 'button-fill-brand pe-pointer'} ${theme}`}
                                onClick={() => {
                                    if (dynamicSelectedPedingInvoices === 0) return;
                                    setShowPay(true);
                                }}
                            >
                                <i className={`far fa-wallet ${dynamicSelectedPedingInvoices === 0 ? 'button-content-disabled' : 'button-content-equal'} txt-8 ${theme}`}></i>
                                <p className={`label-small-regular pe-pointer ${dynamicSelectedPedingInvoices === 0 ? 'button-content-disabled' : 'button-content-equal'} ${theme}`}>{dynamicSelectedPedingInvoices !== 0 ? 'Pagar facturas' : 'Selecciona tus facturas'}</p>
                            </button>
                            <div className="row-center gap-3">
                                <img className="min-width-50" src={theme === themes.LIGHT ? PseIconLight : PseIconDark} alt="pse-logo" />
                                <div className={`h-100 border-fill-light-right ${theme}`} />
                                <img className="min-width-50" src={theme === themes.LIGHT ? VisaIconLight : VisaIconDark} alt="visa-logo" />
                            </div>
                        </div>
                    </div>
                ) : <></>
            }
        </div>
    )
};

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    invoicesLoading: state.invoices.invoicesLoading,
    invoicesSummary: state.invoices.invoicesSummary,
    invoicesSummaryLoading: state.invoices.invoicesSummaryLoading,
    language: state.shared.language,
    theme: state.theme.theme,
})

const mapDispatchToProps = (dispatch) => ({
    setInvoicesToPay: (payload) =>
        dispatch(invoicesActions.setInvoicesToPay(payload)),
    setShowPay: (payload) =>
        dispatch(invoicesActions.setShowPay(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSummary);
