import React, { useState } from "react"
import { connect } from "react-redux"

import * as servicesActions from "../../../store/actions/Services"
import * as usersActions from "../../../store/actions/Users"

import { Animation } from "rsuite"
import { Popover } from "@idui/react-popover"

import ServicesPopover from "./ServicesPopover"
import ActiveServices from "./ActiveServices"
import UserList from '../../shared/users/Users';
import UserCreate from "../../shared/users/UserCreate"
import ButtonCircle from "../../shared/utilities/ButtonCircle"

import _ from "lodash"

const ServicesEnergy = (props) => {

  let activeServicesEnergy = props.activeServicesEnergy

  const { theme } = props;

  const setShowUsersListModal = props.setShowUsersListModal
  const setUsersAccount = props.setUsersAccount

  const [activeServiceDetail, setActiveServiceDetail] = useState(activeServicesEnergy[0])
  
  return (
    <>
      <div className={`w-100 px-3`}>
        <div id="menu_services" className={`position-relative w-100 column-start rounded-16 p-3 border-fill-medium surface-bg-surface ${theme}`}>
          <div className="row-between align-items-center w-100">
            <div className="column-start gap-3">
              <p className={`label-samll-regular content-default-tertiary ${theme}`}>{props.language.energy_main_title_dashboard}</p>
              <ActiveServices showActiveServices={4} activeServices={activeServicesEnergy} setActiveServices={props.setActiveServicesEnergy} activeServiceDetail={activeServiceDetail} setActiveServiceDetail={setActiveServiceDetail} />
            </div>
            <div id="service_picker">
              <ServicesPopover
                activeServices={activeServicesEnergy}
                selectFunction={(value) => {
                  const filteredPlusServices = value.filter(service => service.type.typeService === 2)
                  props.setActiveServicesEnergy(value)                  
                  if(filteredPlusServices.length) props.setActiveServicesPlus([_.last(filteredPlusServices)])
                }}
                availableTypeServices={["energy", "plus", "generation"]}
                limitSelectServices={3}
                typeComponent={'energy'}
              />
            </div>
          </div>
          <Animation.Collapse in={props.showDetailService} dimension={"height"}>
            <div className="column-start w-100" style={{ minHeight: 150 }}>
              <div className={`row-between w-100 align-items-center my-3 py-3 border-fill-medium-top border-fill-medium-bottom ${theme}`}>
                <div className="row-start align-items-center">
                  <p className={`me-2 label-small-regular content-default-tertiary ${theme}`}>{props.language.energy_adress_title}</p>
                  {activeServiceDetail?.address ?
                    <Popover
                      lazy
                      closeOnEnter
                      fitMaxHeightToBounds
                      fitMaxWidthToBounds
                      trigger={"hover"}
                      placement={"bottomLeft"}
                      arrowColor={"#00000000"}
                      className={`button-fill-disabled ${theme} p-0`}
                      arrowSize={0}
                      offset={[0, 0]}
                      mouseEnterDelay={1000}
                      openingAnimationTranslateDistance={0}
                      animation={{
                        animate: { opacity: 1, scale: 1 },
                        exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } },
                        initial: { opacity: 0, scale: 0.9 },
                      }}
                      content={
                        <div className="w-100">
                          <div className="column-start align-items-center">
                            <div className="row-between w-100 align-items-center px-3 py-2">
                              <div className={`text-nowrap label-small-regular content-default-primary ${theme}`}>{activeServiceDetail?.address}</div>
                            </div>
                          </div>
                        </div>
                      }>
                      <div className={`row-center rounded-pill py-2 px-3 max-width-300 surface-bg-on-surface ${theme}`}>
                        <p className={`text-truncate pe-default label-small-regular content-default-primary ${theme}`}>{activeServiceDetail?.address}</p>
                      </div>
                    </Popover> :
                    <div className={`row-center rounded-pill py-2 px-3 max-width-300 surface-bg-on-surface ${theme}`}>
                      <p className={`text-truncate pe-default label-small-regular content-default-primary ${theme}`}>{props.language.energy_graph_no_data}</p>
                    </div>}
                </div>
                <div className="row-start align-items-center">
                  <p className={`me-2 label-small-regular content-default-tertiary ${theme}`}>{props.language.energy_service_type_title}</p>
                  <div className="row-center gap-2">
                    <div className={`row-center rounded-pill py-2 px-3 max-width-300 surface-bg-on-surface ${theme}`}>
                      <p className={`label-small-regular content-default-primary ${theme}`} style={{textTransform: 'capitalize'}}>{activeServiceDetail?.type.name ?? props.language.energy_graph_no_data}</p>
                      <i className={`fak fa-${activeServiceDetail?.type.name}-symbol txt-8 ms-2 content-color-green ${theme}`}></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-start w-100">
                <div className="row-start w-100 align-items-center w-100">
                  <p onClick={() => props.setShowUsersListModal(true)} className={`label-small-regular content-default-tertiary ${theme}`}>
                    {props.language.energy_users_title}
                  </p>
                </div>
                <div className="row-start align-items-center flex-wrap w-100 gap-2 mt-3">
                  {activeServiceDetail?.users?.length ? activeServiceDetail?.users.map((serviceDetail) => {
                    return (
                      <div key={serviceDetail.idUser} className={`row-center align-items-center rounded-pill p-2 min-height-33 surface-bg-on-surface ${theme}`}>
                        <div className="rounded-circle" style={{ height: 16, width: 16 }}>
                          <div className="row-center align-items-center h-100 w-100">
                            <div className={`row-center align-items-center rounded-circle h-100 w-100 pe-default surface-bg-on-surface ${theme}`}>
                              {!serviceDetail.image ? <i className={`fa-light fa-user txt-8 content-default-tertiary ${theme}`}></i> : <img className="user-img w-100 h-100 overflow-hidden rounded-circle" src={`${process.env.REACT_APP_IMAGES_URL}/profile-photos/${serviceDetail.idUser}/150x150/${serviceDetail.image}`} alt="Foto de perfil del usuario" />}
                            </div>
                          </div>
                        </div>
                        <p className={`txt-capitalize ms-2 pe-default label-small-regular content-default-primary ${theme}`}>
                          {serviceDetail.name} {serviceDetail.lastname}
                        </p>
                      </div>
                    )
                  }) :
                    <div className={`row-center align-items-center rounded-pill py-2 px-4 min-height-33 surface-bg-on-surface ${theme}`}>
                      <p className={`label-small-regular content-default-primary ${theme}`}>{props.language.energy_no_users}</p>
                    </div>}
                  <ButtonCircle icon={"circle-chevron-down"} size={32} onClick={() => {
                    setUsersAccount(activeServiceDetail?.users);
                    setShowUsersListModal(true);
                  }} />
                </div>
              </div>
            </div>
          </Animation.Collapse>
        </div>
      </div>
      <UserList />
      <UserCreate />
    </>
  )
}

const mapStateToProps = (state) => ({
  activeServicesEnergy: state.services.activeServicesEnergy,
  language: state.shared.language,
  user: state.users.user,
  showDetailService: state.energy.showDetailService,
  showBlackScreen: state.shared.showBlackScreen,
  showMenu: state.shared.showMenu,
  theme: state.theme.theme,
})

const mapDispatchToProps = (dispatch) => ({
  setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
  setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
  setShowUsersListModal: (payload) => dispatch(usersActions.setShowUsersListModal(payload)),
  setUsersAccount: (payload) => dispatch(usersActions.setUsersAccount(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ServicesEnergy)
