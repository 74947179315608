import * as genius from '../creators/Genius';

import { GETData, SETData, GETS3PublicUrl } from '../../services/WebServices';

import { Alert } from 'rsuite';

import amplitude from 'amplitude-js';

const url_api = "https://fvichq1b59.execute-api.us-east-1.amazonaws.com/";
const url_api_ppa = "https://el5zcca33f.execute-api.us-east-1.amazonaws.com/";

export const setReportGenius = payload => {
    return {
        type: genius.SET_REPORTS_GENIUS,
        payload
    }
}

export const setGraphs = payload => {
    return {
        type: genius.SET_GRAPHS_REPORT_GENIUS,
        payload
    }
}

export const setActiveReports = payload => {
    return {
        type: genius.SET_ACTIVE_REPORTS,
        payload
    }
}

export const setReportLoading = payload => {
    return {
        type: genius.SET_REPORT_LOADING,
        payload
    }
}

export const setNewReportLoading = payload => {
    return {
        type: genius.SET_NEW_REPORT_LOADING,
        payload
    }
}

export const setReportGenerated = payload => {
    return {
        type: genius.SET_REPORT_GENERATED,
        payload
    }
}

export const setNewReport = payload => {
    return {
        type: genius.SET_NEW_REPORT,
        payload
    }
}

export const setShowContentHeader = payload => {
    return {
        type: genius.SET_SHOW_CONTENT_HEADER,
        payload
    }
}

export const setServiceType = payload => {
    return {
        type: genius.SET_SERVICE_TYPE,
        payload
    }
}

export const setGraphsType = payload => {
    return {
        type: genius.SET_GRAPHS_TYPE,
        payload
    }
}

export const setTotalGraphs = payload => {
    return {
        type: genius.SET_TOTAL_GRAPHS_REPORT_GENIUS,
        payload
    }
}

export const generateReportAsync = (payload) => {
    return (dispatch, getState) => {
        const language = getState().shared.language;
        const user = getState().users.user;
        const type = getState().genius.serviceType;

        const servicesIds = payload.deviceIds;

        const isPPA = payload.isPPA

        const account = getState().services.totalServices.find(s => s.idService === servicesIds[0])?.idAccount;

        const data = {
            source: "app",
            file_name: payload.name,
            start_date: payload.dateFrom,
            end_date: payload.dateTo,
            zoho_crm_id: null,
            zoho_crm_module: null,
            id_account: account,
            id_services: servicesIds,
            graphs: payload.graphIds,
            emails: [`${user.email}`]
        }      

        dispatch(setNewReportLoading(true));

        Alert.info(language.alert_genius_create_report_initially, 10000);

        dispatch(setReportGenerated(true));

        if(!isPPA){
            SETData(`${type && type[0] === 4? 'genius/reports/generation' : 'genius/reports/energy'}`, "POST", data, url_api, true)
                .then(async () => {
    
                    // let counter = 0
    
                    // let interval = setInterval(
                    //     () => {
    
                    //         GETData(`genius/reports/${account}`, 'GET')
                    //         .then((response) => {                                                    
                    //             if (response !== null) {
    
                    //                 let statusReport = response.body.filter(r => r.file_name.split('.')[0] === data.file_name )[0].status;
    
                    //                 switch (statusReport) {
                    //                     case 1:
                    //                         dispatch(setReportGenerated(true));
                    //                         amplitude.getInstance().logEvent('Generate New Report');
                    //                         Alert.info(language.alert_genius_create_report_success);
    
                    //                         clearInterval(interval)
    
                    //                         break
    
                    //                     case 2:
                    //                         Alert.error(language.alert_genius_create_report_failed);
    
                    //                         clearInterval(interval)
    
                    //                         break
    
                    //                     default:
                    //                         break
                    //                 }
    
                    //             }
                    //         })
                    //         .catch(response => console.error(response))
    
                    //         if (counter < 15) {
                    //             counter += 1;
                    //         } else {
                    //             clearInterval(interval);
                    //             Alert.info(language.alert_genius_create_report_pending, 8000)
                    //         }
    
                    //     },
                    //     1000
                    // );
    
                    GETData(`genius/reports/user/${user.idUser}`, 'GET')
                        .then((response) => {
                            if (response !== null) {
    
                                let statusReport = response.filter(r => r.file_name.split('.')[0] === data.file_name)[0].status;
    
                                if( statusReport) {if(statusReport === 1) amplitude.getInstance().logEvent('Generate New Report')}                 
    
                            }
                        })
                        .catch(response => console.error(response))
    
                })
                .catch(response => console.error(response))
                .finally(() => dispatch(setNewReportLoading(false)));

            } else {
            const data = {
                id_account: `${user.idUser}`,
                month: payload.bodyPPA.month,
                year: payload.bodyPPA.year,
                emails: [`${user.email}`]
            }

            SETData("genius/ppa", "POST", data, url_api_ppa, true)
            .catch(response => console.error(response))
            .finally(() => dispatch(setNewReportLoading(false)));
        }

    }
}

export const getGraphsReportAsync = () => {
    return (dispatch) => {

        GETData('genius/graphs', 'GET')
            .then((response) => {
                if (response !== null) { dispatch(setTotalGraphs(response)) }
            })
            .catch(response => console.error(response));
    }
}

export const getReportsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().users.user.idUser;
        // const servicesUser = getState().services.totalServices;

        dispatch(setReportLoading(true));

        GETData(`genius/reports/user/${userId}`, 'GET')
            .then((response) => {
                
                if (response !== null) {
                                        
                    let reports = [];
                    // const serviceUserIds = new Set(servicesUser.map(service => service.idService))
                    
                    // if(accountId === 2280){
                    //     const filteredReports = response.body.filter(report => {
                    //         return report.status === 1 && report?.id_services.some(service => serviceUserIds.has(service.id_service));
                    //     });
                    //     reports = filteredReports;
                    // }else{
                    //     reports = response.body.filter(report => {
                    //         return report.status === 1
                    //     })
                    // };

                    reports = response.filter(report => {
                        return report.status === 1
                    })

                    const data = reports.map((x) => {
                        const url = `${process.env.REACT_APP_GENIUS_REPORTS_DISTRIBUTION}${x.id_account}/${x.file_name}`
                        const new_item = { ...x, url: url };
                        return (new_item)
                    });

                    dispatch(setReportGenius(reports));
                    dispatch(setActiveReports([data[0]]));

                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setReportLoading(false)));
    }
}

export const downloadReportsAsync = (payload) => {    
    return async () => {

        const url = await GETS3PublicUrl(payload.url, process.env.REACT_APP_BUCKET_GENIUS, process.env.REACT_APP_S3_REGION_GENIUS);

        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = payload.filename !== '' ? payload.filename : payload.url;
                link.click();
            })
            .catch(console.error);
    }
}

export const deleteReportAsync = (payload) => {
    return (dispatch) => {

        SETData(`genius/report/${payload}`, 'PATCH')
            .then((response) => {
                if (response !== null) { 
                    Alert.success('El reporte se ha eliminado con éxito');
                    dispatch(setShowContentHeader(false));
                    dispatch(getReportsAsync());
                }else{
                    Alert.error('Algo salió mal')
                }
            })
            .catch(response => console.error(response));
    }
}

export const getGraphsByServiceAsync = (payload) => {
    return (dispatch) => {

        SETData('genius/graphs', 'POST', payload)
            .then((response) => {                
                if (response !== null) { dispatch(setGraphs(response)) }
            })
            .catch(response => console.error(response));
    }
}