import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import * as plusActions from "../../../../store/actions/Plus";

import { Modal } from 'rsuite';
import Input from '../../../shared/utilities/Input';

const showError = (appliance, setFocus, setError, language) => {

    if (appliance.custom_name === null || appliance.custom_name === "") {        
        setFocus(1);
        setError(language.alert_new_device_name);
        return;
    }

    if (appliance.type === null || appliance.type === "") {        
        setFocus(2);
        setError(language.alert_new_device_type);
        return;
    }

    return setFocus(0);
}

const validate = (appliance) => {

    if (appliance.custom_name === null || appliance.custom_name === "") {
        return false;
    } 
    
    if (appliance.type === null || appliance.type === "") {
        return false;
    }

    return true
}

const CreateDevice = (props) => {

    const applianceInit = {
        id_service: null,
        custom_name: null,
        type: null,
        brand: null,
        reference: null
    }

    const {theme} = props;
    const [appliance, setAppliance] = useState(applianceInit);
    const [focus, setFocus] = useState(0);   
    const [error, setError] = useState("");

    let language = props.language;

    useEffect(() => {
        if(props.activeServicesPlus.length){
            setAppliance({...appliance, id_service: props.activeServicesPlus[0]?.idService});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activeServicesPlus])

    useEffect(() => {
        if(focus !== 0) {showError(appliance, setFocus, setError, language)}                      
    }, [appliance, language, focus]);

    return(
        <Modal
            size="xs" 
            overflow={false}
            backdropClassName={`bg-black opacity-80 pe-pointer`}    
            dialogClassName="rounded"                  
            show={props.show}                                
            onHide={() => props.setShow(false)}
        >
                <div className={`column-start w-100 h-100 surface-bg-surface border-fill-medium rounded-16 ${theme}`}>
                    <div className={`w-100 heading-h6-bold content-default-primary text-left ps-3 mt-3 ${theme}`}>{props.language.tag_new_device_main_title}</div> 
                    <div className='column-start p-3 mt-0'>
                        <div className={`label-small-medium content-default-primary ${theme}`}>{props.language.tag_new_device_title_name}</div>
                        <div className="w-100 my-3">
                            <Input theme={theme} label={props.language.tag_new_device_input_label_name} type={'text'} onChange={({target}) => setAppliance({...appliance, custom_name: target.value})} error={focus === 1 ? error : ""}/>
                        </div>
                        <div className={`label-small-medium content-default-primary ${theme}`}>{props.language.tag_new_device_title_type}</div>
                        <div className="w-100 my-3">
                            <Input theme={theme} label={props.language.tag_new_device_input_label_type} type={'text'} onChange={({target}) => setAppliance({...appliance, type: target.value})} error={focus === 2 ? error : ""}/>
                        </div>
                        <div className={`label-small-medium content-default-primary ${theme}`}>{props.language.tag_new_device_title_brand}</div>
                        <div className="w-100 my-3">
                            <Input theme={theme} label={props.language.tag_new_device_input_label_brand} type={'text'} onChange={({target}) => setAppliance({...appliance, brand: target.value})}/>
                        </div>
                        <div className={`label-small-medium content-default-primary ${theme}`}>{props.language.tag_new_device_title_reference}</div>
                        <div className="w-100 my-3">
                            <Input theme={theme} label={props.language.tag_new_device_input_label_reference} type={'text'} onChange={({target}) => setAppliance({...appliance, reference: target.value})}/>
                        </div> 
                        <div className='row-end mt-2'>
                            <button style={{width: 100}} className={`button-fill-error me-3 rounded-pill py-2 px-4 label-small-regular button-content-equal ${theme}`} onClick={() => {props.setShow(false); }}>{props.language.tag_new_device_button_cancel}</button>
                            <button style={{width: 100}} className={`button-fill-brand rounded-pill py-2 px-4 label-small-regular button-content-equal ${theme}`} 
                                onClick={async() => {
                                    if(validate(appliance)){
                                        await props.addAppliance(appliance, props.activeServicesPlus);
                                        props.setShow(false);                                                                                                                                                          
                                    }
                                    else {
                                        showError(appliance, setFocus, setError, language)
                                    }                                    
                                }}>
                                {props.language.tag_new_device_button_create}
                            </button>
                        </div>                       
                    </div>                  
                </div>
                       
        </Modal>

    );
}

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    language: state.shared.language,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
    addAppliance: (payload) => dispatch(plusActions.addAppliance(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDevice);

