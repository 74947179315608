import React from 'react'
import { connect } from 'react-redux';

const InputRounded = ({ placeholder, icon, value, type, required, disabled = false, onChange, onPressEnter, focus = false, error, theme }) => {

    return (
        <div className='column-start w-100'>
            <div className={`row-start align-items-center gap-2 rounded-pill content-default-secondary surface-bg-surface border-fill-regular p-3 w-100 ${theme}`}>
                {icon ? <i className={`fa-light fa-${icon} content-default-secondary label-medium-medium ${theme}`}></i> : null}
                <input disabled={disabled} type={type} autoFocus={focus} className={`w-100 border-0 bg-transparent content-default-secondary label-medium-medium ${theme}`} id="floatingInput" placeholder={placeholder} required={required} value={value} onChange={onChange} onKeyDown={({ keyCode }) => keyCode === 13 && onPressEnter && onPressEnter()} />
            </div>
            {error ? <p className={`label-small-regular content-color-red mt-1 w-100 text-wrap ${theme}`}>{error}</p> : null}
        </div>
    );
}

const mapStateToProps = (state) => ({
    theme: state.theme.theme
})

export default connect(mapStateToProps)(InputRounded)