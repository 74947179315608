import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";

import * as usersActions from "../../../store/actions/Users";

import { useTable, useSortBy, usePagination } from "react-table";

import amplitude from "amplitude-js";
import empty_users from "../../../imgs/empty-users.svg";
import empty_users_light from "../../../imgs/empty-user-light.svg";

const Table = ({ columns, data, actions, page, deleteAction, ...props }) => {
    const { theme } = props;

    const {
        getTableBodyProps,
        headerGroups,
        prepareRow
    } = useTable({ columns, data }, useSortBy, usePagination);

    const [userSelected, setUserSelected] = useState(null);

    useEffect(() => {
        if (userSelected) { actions(userSelected); }
    }, [actions, userSelected]);

    return (
        <Fragment>
            {
                data.length ?
                    <div className="table-overflow ms-3" style={{marginRight: "0.7rem"}}>
                        <table className="table">
                            <thead className={`position-sticky zindex-2 top-0`}>
                                {headerGroups
                                    .map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers
                                                .map((column, index) => (
                                                    column.id !== "id" &&
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`content-default-secondary label-small-medium surface-bg-on-surface ${index === 0 && 'rounded-left-10'} ${index + 1 === headerGroup.headers.length && 'rounded-right-10'} ${theme}`} style={{padding: 0}}>
                                                        <div className={`column-start p-3 align-items-center w-100 ${index !== headerGroup.headers.length - 1 && 'border-fill-medium-right'} ${theme}`} style={{height: '44px'}}>
                                                            <p className="w-100" style={{textAlign: 'start'}}>{column.render("Header")}</p>
                                                            <span className="ms-2">
                                                                {column.isSorted ?
                                                                    column.isSortedDesc
                                                                        ? <i className="fa-solid fa-sort-down txt-10 txt-white pe-pointer" />
                                                                        : <i className="fa-solid fa-sort-up txt-10 txt-white pe-pointer" /> :
                                                                    ""}
                                                            </span>
                                                        </div>
                                                    </th>
                                                ))}
                                        </tr>
                                    ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page
                                    .map((row) => {                                        
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells
                                                    .map((cell) => {
                                                        
                                                        return (
                                                            cell.column.id !== "id" &&
                                                            <td className={`label-medium-medium content-default-primary p-3 ${theme}`} style={{ verticalAlign: "middle", overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} {...cell.getCellProps()}>
                                                                {
                                                                // cell.column.id === "services" ?
                                                                //     <div className={`row-center align-items-center py-2 border-fill-bold rounded-pill button-fill-disabled ${theme}`} style={{ width: 60 }}>
                                                                //         <i className={`fa-solid fa-buildings txt-10 content-default-primary me-2 ${theme}`}></i>
                                                                //         {cell.render("Cell")}
                                                                //     </div> :
                                                                //     cell.column.id === "department" && cell.value ?
                                                                //         <div className="row-start align-items-center">
                                                                //             {cell.value?.length > 1 ?
                                                                //                 <div className="row-start align-items-center">
                                                                //                     <div className="row-center align-items-center py-2 px-3 bg-green-900 border-1 border-color-green-500 txt-green-500 txt-phrase txt-semibold txt-6 rounded-pill">
                                                                //                         {props.language[cell?.value[0]?.valueText]}
                                                                //                     </div>
                                                                //                     <div className="row-center align-items-center py-2 px-3 bg-green-900 border-1 border-color-green-500 txt-green-500 txt-phrase txt-semibold txt-6 rounded-pill ms-1">{`+${cell.value.length - 1}`}</div>
                                                                //                 </div> :
                                                                //                 <div className="row-center align-items-center py-2 px-3 bg-green-900 border-1 border-color-green-500 txt-green-500 txt-phrase txt-semibold txt-6 rounded-pill">
                                                                //                     {props.language[cell?.value[0]?.valueText] ?? 'N/A'}
                                                                //                 </div>}
                                                                //             {/* <div className="row-center align-items-center py-2 px-3 bg-green-900 border-1 border-color-green-500 txt-green-500 txt-phrase txt-semibold txt-6 rounded-pill">
                                                                //                 {cell.value.length > 1 ? `${cell.value[0]?.name} ...+${cell.value.length - 1}` : cell.value[0]?.name ?? 'N/A'}
                                                                //             </div> */}
                                                                //         </div> :
                                                                        cell.column.id === "edit" ?
                                                                            <div className="row-center align-items-center">
                                                                                <div className={`row-center align-items-center rounded-30 me-1 pe-pointer surface-bg-on-surface-alpha border-fill-medium ${theme}`} style={{width: '36px', height: '36px'}} onClick={() => { setUserSelected(cell.row.values); props.setShowCreateUserModal(2); amplitude.getInstance().logEvent("Edit User"); }}>
                                                                                    <i className={`fa-regular fa-edit txt-10 content-default-secondary pe-pointer ${theme}`}></i>
                                                                                </div>
                                                                                <div className={`row-center align-items-center rounded-30 ms-1 pe-pointer surface-bg-on-surface-alpha border-fill-medium ${theme}`} style={{width: '36px', height: '36px'}} onClick={() => { deleteAction(cell.row.values); amplitude.getInstance().logEvent("Delete User"); }}>
                                                                                    <i className={`fa-regular fa-trash txt-10 pe-pointer content-default-secondary ${theme}`}></i>
                                                                                </div>
                                                                            </div> :
                                                                            cell.render("Cell")}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div> :
                    <div className="column-center align-items-center w-100 min-height-300">
                        <img src={theme === 'dark' ? empty_users : empty_users_light} alt="empty_users" style={{ width: 204, height: 100 }} />
                        <div className={`label-medium-semibold content-default-tertiary text-center mt-3 ${theme}`}>{props.language.user_table_title_empty_state}</div>
                        <div className={`text-center w-50 text-wrap mt-3 label-small-regular content-default-tertiary ${theme}`}>{props.language.user_table_description_empty_state}</div>
                        {/* <button className="row-center align-items-center bg-gray-800 py-2 px-4 mt-4 rounded-8 pe-pointer" onClick={() => { props.setShowCreateUserModal(1); props.setUserAccessEdit(null); amplitude.getInstance().logEvent("Add New User"); }}>
                            <i className="fa-solid fa-plus txt-8 txt-white me-2"></i>
                            <p className="txt-phrase txt-bold txt-white txt-8 pe-pointer">{props.language.user_title_create_user}</p>
                        </button> */}
                    </div>
            }
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    theme: state.theme.theme,
})

const mapDispatchToProps = dispatch => ({
    setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
    setUserAccessEdit: (payload) => dispatch(usersActions.setUserAccessEdit(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Table);