import React, { Fragment } from "react";

import { connect } from "react-redux";

import { VictoryChart, VictoryPolarAxis, VictoryLabel, VictoryStack, VictoryBar } from 'victory';

import { Swiper, SwiperSlide } from 'swiper/react';

import _ from 'lodash';

import empty_modules_plus from "../../../../imgs/empty-modules-plus.svg";
import empty_modules_plus_light from "../../../../imgs/empty-modules-plus-light.svg";

import ContentLoader from "react-content-loader";

import { track } from "@amplitude/analytics-browser";

const RenderCardEmpty = ({ language, theme }) => {
    return (
        <div className={`swiper-slider-container column-between align-items-center  rounded-16 p-3`} style={{ height: 200 }}>
            { theme === 'dark' ?
                <img src={empty_modules_plus} alt="empty_radar_data" style={{ width: 154, height: 154 }} /> :
                <img src={empty_modules_plus_light} alt="empty_radar_data" style={{ width: 154, height: 154 }} />  
            }
            <div className={`row-center mx-5 align-items-center content-default-secondary label-XSmall-regular ${theme}`}>
                {language.plus_graph_title_empty_data}
            </div>
        </div>
    )
};

const IndicatorsLoading = props => {
    const {theme} = props;
    return (
    <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
        <ContentLoader
            seed={2}
            backgroundColor= {theme === 'dark' ? "var(--gray-950)" : "var(--white)"}
            foregroundColor={theme === 'dark' ? "#323234" : "var(--gray-200)" }
            style={{ width: "100%", height: "100%" }}>
            <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
        </ContentLoader>
    </div>)
  };

const PowerFactor = (props) => {

    const { theme } = props;
    let deviceActive = props.activeServicesPlus.length ? props.devices.filter(value => value?.id_service === props.activeServicesPlus[0]?.idService) : [];

    return (
        <Fragment>
            {
                props.loadingData ?
                    <div className="position-relative w-100 mt-3" style={{ minHeight: 312 }}><IndicatorsLoading theme={theme}/></div> :
                    !props.dataGraph.length || !('power_factor_1' in _.last(props.dataGraph)) ?
                        <div className={`position-relative column-between align-items-center surface-bg-surface border-fill-medium rounded-16 py-2 w-100 mt-3 ${theme}`}>
                            <p className="txt-phrase txt-8 txt-white txt-bold">{props.language.power_factor_title}</p>
                            <div
                                id="prev-slide-quality"
                                className="position-absolute left-0 row-center align-items-center h-100 pe-pointer"
                                style={{ width: 32, height: 40, zIndex: 1 }}
                                onClick={() => track('Quality widget ll clicked')}>
                                <i className="fa-regular fa-chevron-left txt-14 txt-white pe-pointer" />
                            </div>
                            <div className="position-relative row-center align-items-center w-100 h-100">
                                <Swiper
                                    loop={true}
                                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                                    navigation={{ nextEl: "#next-slide-quality", prevEl: "#prev-slide-quality" }}
                                    slidesPerView={1}>
                                    <SwiperSlide>
                                        <RenderCardEmpty language={props.language} theme={theme}/>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div
                                id="next-slide-quality"
                                className="position-absolute row-center align-items-center h-100 pe-pointer right-0"
                                style={{ width: 32, height: 32 }}
                                onClick={() => track('Quality widget ll clicked')} >
                                <i className="fa-regular fa-chevron-right txt-14 txt-white pe-pointer" />
                            </div>
                        </div> :
                        <div className={`position-relative column-between align-items-center surface-bg-surface border-fill-medium rounded-16 py-2 w-100 mt-3 ${theme}`}>
                            <div className="position-relative row-center align-items-center w-100 h-100">
                                <div className={`position-absolute top-0 row-between align-items-center w-100 px-2 mb-2`}>
                                    <p className={`row-start label-medium-semibold content-default-primary ${theme}`}>{props.language.power_factor_title}</p>
                                    <div className="row-end">
                                        <div
                                            id="prev-slide-quality"
                                            className={`row-center align-items-center me-3 surface-bg-on-surface-alpha rounded-pill border-fill-medium pe-pointer zindex-2 ${theme}`}
                                            style={{ width: 32, height: 32 }}
                                            onClick={() => track('Quality widget ll clicked')} >
                                            <i className={`fa-regular fa-chevron-left txt-14 content-default-primary pe-pointer ${theme}`} />
                                        </div>
                                        <div
                                            id="next-slide-quality"
                                            className={`row-center align-items-center surface-bg-on-surface-alpha rounded-pill border-fill-medium pe-pointer zindex-2 ${theme}`}
                                            style={{ width: 32, height: 32 }}
                                            onClick={() => track('Quality widget ll clicked')} >
                                            <i className={`fa-regular fa-chevron-right txt-14 content-default-primary pe-pointer ${theme}`} />
                                        </div>
                                    </div>
                                </div>
                                <svg style={{ height: 0 }}>
                                    <defs>
                                        <linearGradient id="green">
                                            <stop offset="0.14%" stopColor="var(--brand-primary-500)" />
                                            <stop offset="99.52%" stopColor={theme === 'dark' ? "var(--blue-500)" : "var(--blue-600)"} />
                                        </linearGradient>
                                        <linearGradient id="red">
                                            <stop offset="0.14%" stopColor={theme === 'dark' ? "var(--red-500)" : "var(--red-600)"} />
                                            <stop offset="99.52%" stopColor={theme === 'dark' ? "var(--orange-500)" : "var(--orange-600)"} />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className="position-relative w-100 mt-5">
                                    <Swiper
                                        loop={true}
                                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                                        navigation={{ nextEl: "#next-slide-quality", prevEl: "#prev-slide-quality" }}
                                        slidesPerView={1}>
                                        {(deviceActive[0]?.phases === 2 ? [1, 2] : [1, 2, 3]).map((item) =>
                                            <SwiperSlide key={item}>
                                                <div className={`row-center align-items-center w-100`}>
                                                    <p className={`button-fill-disabled label-medium-regular content-default-primary rounded-pill py-2 px-3 ${theme}`}>{`${props.language.power_factor_label_phase} ${item}`}</p>
                                                </div>
                                                <div className="w-100">
                                                    <VictoryChart polar
                                                        maxDomain={{ x: 360, y: 100 }}
                                                        height={80} width={130}
                                                        padding={{ top: 10, left: 20, right: 20, bottom: 10 }}
                                                        style={{ background: { fill: theme === 'dark' ? "var(--gray-950)" : "var(--white)" }, border: { stroke: "none" } }}
                                                    >
                                                        <VictoryPolarAxis dependentAxis
                                                            style={{
                                                                axis: { stroke: "none" },
                                                                tickLabels: { fill: "none" },
                                                                grid: { 
                                                                    stroke: 
                                                                        (datum) => datum.tick < _.last(props.dataGraph)[`power_factor_${item}`] * 100 ? 
                                                                        (theme === 'dark' ? "var(--gray-800)" : "var(--gray-200)") : 
                                                                        _.last(props.dataGraph)[`power_factor_${item}`] >= 0.92 ? "url(#green)" : _.last(props.dataGraph)[`power_factor_${item}`] >= 0.8 ? (theme === 'dark' ? "var(--orange-500)" : "var(--orange-600)") : "url(#red)", 
                                                                    strokeWidth: (datum) => datum.tick < _.last(props.dataGraph)[`power_factor_${item}`] * 100 ? 3 : 1, 
                                                                    strokeOpacity: 1,
                                                                    strokeDasharray: (datum) => datum.tick >= 100 ? "4,4" : null
                                                                }
                                                            }}
                                                        />
                                                        <VictoryPolarAxis
                                                            tickValues={[0, 360]}
                                                            style={{
                                                                tickLabels: { fill: "none" },
                                                                axis: { stroke: "none" },
                                                                grid: { stroke: "none" }
                                                            }}
                                                        />
                                                        <VictoryStack
                                                            style={{ data: { fill: "none", stroke: _.last(props.dataGraph)[`power_factor_${item}`] >= 0.92 ? "url(#green)" : _.last(props.dataGraph)[`power_factor_${item}`] >= 0.8 ? (theme === 'dark' ? "var(--orange-500)" : "var(--orange-600)") : "url(#red)", strokeWidth: 2, width: 360, opacity: _.last(props.dataGraph)[`power_factor_${item}`] >= 0.7 ? 0.9 : 0.9 } }}
                                                        >
                                                            <VictoryBar data={[{ x: 0, y: _.last(props.dataGraph)[`power_factor_${item}`] * 100 }, { x: 360, y: _.last(props.dataGraph)[`power_factor_${item}`] * 100 }]} />
                                                        </VictoryStack>
                                                        <VictoryLabel
                                                            textAnchor="middle" verticalAnchor="middle"
                                                            x={65} y={40}
                                                            style={{ fontFamily: "Sora", fontSize: _.last(props.dataGraph)[`power_factor_${item}`] >= 0.8 ? 16 : 14, fill: _.last(props.dataGraph)[`power_factor_${item}`] >= 0.92 ? "url(#green)" : _.last(props.dataGraph)[`power_factor_${item}`] >= 0.8 ? (theme === 'dark' ? "var(--orange-500)" : "var(--orange-600)") : "url(#red)", fontWeight: "bold"}}
                                                            text={(_.last(props.dataGraph)[`power_factor_${item}`] ).toFixed(3) + ""}
                                                        />
                                                    </VictoryChart>
                                                </div>
                                                <div className={`row-center mx-2 align-items-center button-fill-disabled label-medium-regular content-default-primary rounded-pill px-2 py-2 text-wrap ${theme}`}>
                                                    {_.last(props.dataGraph)[`power_factor_${item}`] >= 0.92 ?
                                                        props.language.power_factor_label_optime_range :
                                                        _.last(props.dataGraph)[`power_factor_${item}`] >= 0.8 ?
                                                            props.language.power_factor_label_reactive_range :
                                                            props.language.power_factor_label_deficient_range
                                                    }
                                                </div>
                                            </SwiperSlide>)}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
            }
        </Fragment>
    )

};

const mapStateToProps = (state, ownProps) => ({
    dataGraph: state.plus.dataGraph,
    loadingData: state.plus.loadingInitData,
    language: state.shared.language,
    devices: state.plus.devices,
    activeServicesPlus: state.services.activeServicesPlus,
    theme: state.theme.theme,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PowerFactor);