import React from 'react';

const CheckPicker = ({options, labelName, selectedData, keyName, onChange, plusSelected = false, theme, backgroundColor = 'surface-bg-surface', border = false, padding = 'p-3'}) => {
    return <div className={`column-start rounded-8 ${padding} ${backgroundColor} ${border && 'border-fill-medium'} gap-2 ${theme}`}>
        {options?.length && options.map((item, index) => {            
            let isSelected = selectedData.includes(item[keyName]);           
            return <div key={index} className={`row-between align-items-center pe-pointer py-3 px-2 ${isSelected && 'rounded-8 surface-bg-on-surface' } ${theme} `}
                onClick={() => { 
                    if(isSelected){
                        onChange(selectedData.filter(value => value !== item[keyName]));                                
                    }else{                          
                        if (plusSelected || (!plusSelected && !('plus' in item || 'id_device' in item))) onChange([...selectedData, item[keyName]]);                                
                    }
                }}
            >
                <div className="row-between align-items-center w-100">
                    {'plus' in item || 'id_device' in item ? 
                    <div className={`row-between align-items-center w-100 me-2 label-small-medium content-default-primary pe-pointer ${!plusSelected && "opacity-40"} ${theme}`}>
                        {item[labelName]}
                        <div className={`ms-5 px-2 h-50 rounded-16 border-color-green ${theme}`} style={{backgroundColor: 'rgba(158,204,20,0.3)'}}>
                            <p className={`content-color-green ${theme}`}>Plus</p>
                        </div>
                    </div> :
                    <div className={`pe-pointer label-small-medium content-default-primary ${theme}`}>{item[labelName]}</div>}   
                    <i className={`${isSelected ? 'fa-solid fa-check-circle content-color-green' : 'fa-light fa-check-circle content-default-secondary'} txt-10 ${theme}`}></i>
                </div>
            </div>
        })}            
    </div>
    
};

export default CheckPicker;