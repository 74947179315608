import * as energy from '../creators/Energy';

import * as servicesActions from './Services';
import * as invoicesActions from './Invoices';
import * as accountsActions from './Accounts';

import { getUnixTime } from 'date-fns';

import { SETData, GETS3PublicUrl } from '../../services/WebServices';

import { Alert } from 'rsuite';

export const setGraphData = payload => {
  return {
    type: energy.SET_GRAPH_DATA,
    payload,
  };
};

export const setGraphloading= payload => {
  return {
      type: energy.SET_GRAPH_LOADING,
      payload
  }
}

export const setIntervalGraph = payload => {
  return {
    type: energy.SET_INTERVAL_GRAPH,
    payload,
  };
};

export const setGraphDates = payload => {
  return {
      type: energy.SET_GRAPH_DATES,
      payload
  }
}

export const setGraphDatesScroll = payload => {
  return {
      type: energy.SET_GRAPH_DATES_SCROLL,
      payload
  }
}

export const setGraphOptions = payload => {
  return {
      type: energy.SET_GRAPH_OPTIONS,
      payload
  }
}

export const setGraphActive = payload => {
  return {
      type: energy.SET_GRAPH_ACTIVE,
      payload
  }
}

export const setIndicators = payload => {
  return {
      type: energy.SET_INDICATORS,
      payload
  }
}

export const setInvoiceInfo = payload => {
  return {
      type: energy.SET_INVOICE_INFO,
      payload
  }
}

export const setIndicatorsloading= payload => {
  return {
      type: energy.SET_INDICATORS_LOADING,
      payload
  }
}

export const setEnableScrollData= payload => {
  return {
      type: energy.SET_ENABLE_SCROLL_DATA,
      payload
  }
}

export const setShowReportModal= payload => {
  return {
      type: energy.SET_SHOW_REPORT_MODAL,
      payload
  }
}

export const setReportloading= payload => {
  return {
      type: energy.SET_REPORT_LOADING,
      payload
  }
}

export const setScrollActive= payload => {
  return {
      type: energy.SET_SCROLL_ACTIVE,
      payload
  }
}

export const setTariff= payload => {
  return {
      type: energy.SET_TARIFF,
      payload
  }
}

export const setBannersData = payload => {
  return {
      type: energy.SET_BANNERS_DATA,
      payload
  }
}

export const setShowDetailService = payload => {
  return {
      type: energy.SET_SHOW_DETAIL_SERVICE,
      payload
  }
}

export const setDetailDataGraph = payload => {
  return {
    type: energy.SET_DETAIL_DATA_GRAPH,
    payload
  }
}

export const setPrevStateBar = payload => {
  return {
    type: energy.SET_PREV_STATE_BAR,
    payload
  }
}

export const setEnableAverageLine = payload => {
  return {
    type: energy.SET_ENABLE_AVERAGE_LINE,
    payload
  }
}

export const loadGraphAsync = (loadByScroll = false, requestLeft = true) => {
  return async (dispatch, getState) => {

    if(!loadByScroll) dispatch(setGraphloading(true));

    dispatch(setEnableScrollData(false));

    let interval;
    let metadata;
    let data = []

    const graphs = getState().energy.graphs[0]
    let from = getUnixTime(loadByScroll ? getState().energy.graphDateFromScroll : getState().energy.graphDateFrom);
    let to = getUnixTime(loadByScroll ? getState().energy.graphDateToScroll : getState().energy.graphDateTo);
    const activeServicesEnergy = getState().services.activeServicesEnergy;
    const activeGraphs = getState().energy.activeGraphs;

    switch (getState().energy.intervalGraph) {
      case 0:
        interval = 'HOURS';
        if(loadByScroll) {
          if (requestLeft) {
            to = from;
            from -= 86400 * 1;
          }
          else{
            from = to;
            to += 86400 * 1;
          }
        }
        else{
          from -= 86400 * 1;
        }
        dispatch(setGraphDatesScroll({graphDateFrom: new Date(from * 1000), graphDateTo: new Date(to * 1000)}));
        break;
      case 1:
        interval = 'DAYS';
        if(loadByScroll) {
          if (requestLeft) {
            to = from;
            from -= 604800 * 1;
          }
          else{
            from = to;
            to += 604800 * 1;
          }
        }
        else{
          from -= 604800 * 1;
        }
        dispatch(setGraphDatesScroll({graphDateFrom: new Date(from * 1000), graphDateTo: new Date(to * 1000)}));
        break;
      case 2:
        interval = 'DAYS';
        if(loadByScroll) {
          if (requestLeft) {
            to = from;
            from -= 2592000 * 1;
          }
          else{
            from = to;
            to += 2592000 * 1;
          }
        }
        else{
          from -= 2592000 * 1;
        }
        dispatch(setGraphDatesScroll({graphDateFrom: new Date(from * 1000), graphDateTo: new Date(to * 1000)}));
        break;
      case 3:
        interval = 'MONTH';
        if(loadByScroll) {
          if (requestLeft) {
            to = from;
            from -= 31536000 * 1;
          }
          else{
            from = to;
            to += 31536000 * 1;
          }
        }
        else{
          from -= 31536000 * 1;
        }
        dispatch(setGraphDatesScroll({graphDateFrom: new Date(from * 1000), graphDateTo: new Date(to * 1000)}));
        break;
      default:
        break;
    }

    const services = activeServicesEnergy      
      .map(s => {
        return {
          id_service: s.idService,
          name: s.name
        }
      });

    const language = getState().shared.language;

    let graphsOptions = graphs.options.filter(x => activeGraphs.includes(x.value)).map((graph) => {      
      return {
        'graph_type': `${graph.value}_${(graph.value === "PREDICTION_ACTIVE_ENERGY" && getState().energy.intervalGraph === 2) || graph.value === "REACTIVE_CAPACITIVE_EXCESS_ENERGY" ? "MONTH" : interval}`,
        'label': language[`energy_graph_label_${graph.value.toLowerCase()}`],
        'unit': language[`energy_graph_unit_${graph.value.toLowerCase()}`],
        'value': graph.value,
        'group': graph.graph_type
      }
    });

    if (graphsOptions.length){

      metadata = {
        services,
        graphs_options: graphsOptions,
      };      

      const metadataTariff = {
        id_services: services.map(s => s.id_service),        
        startdate: from,
        enddate: to
      };      
      
      await SETData('tariffs/services', 'POST', metadataTariff)
      .then(response => {          
          if (response !== null) {
            dispatch(setTariff(response));
          } else {
            dispatch(setTariff([]));
          }
      })
      .catch(response => console.error(response))

      await SETData(`energy/data/${from}/${to}/?version=2`, 'POST', metadata)
        .then(energyResponse => {

            if (energyResponse !== null) {  
              if (loadByScroll) {
                const currentGraphData = getState().energy.graphData;
  
                function mergeAndRemoveDuplicates(arr1, arr2, uniqueKey) {                
                  const uniqueKeysSet = new Set(arr1.map(item => item[uniqueKey]));    
                  
                  const filteredArr2 = arr2.filter(item => !uniqueKeysSet.has(item[uniqueKey]));              
                  
                  const result = [...arr1, ...filteredArr2];
                
                  return result;
                };
  
                data = currentGraphData.map(value => {
                  const newResponseData = energyResponse.find(x => x.id === value.id)?.data || [];
                  const existingData = value.data;        
                  return {
                    id: value.id,
                    data: requestLeft ? mergeAndRemoveDuplicates(newResponseData, existingData, 'x_sec') : mergeAndRemoveDuplicates(existingData, newResponseData, 'x_sec'),
                    value: value.value
                  };
                });              
              } 
              else {               
                data = [...data, ...energyResponse]
              }
              dispatch(setEnableScrollData(true));
            }

        })
        .catch(response => console.error(response));

    }    
    dispatch(setGraphData(data));
    dispatch(setGraphloading(false));

  };
};

export const loadGraphOptionsAsync = (byAlerts = false) => {
  return (dispatch, getState) => {

      let groups = [];

      const language = getState().shared.language;

      getState().services.activeServicesEnergy.some(s => s.type.name === "energy" || s.type.name === "plus") && groups.push("CONSUMPTION");
      getState().services.activeServicesEnergy.some(s => s.type.name === "generation") && groups.push(...["GENERATION", "WEATHER"]);

      if (byAlerts) {
        groups = ["CONSUMPTION","GENERATION"]
      }

      const metadata = {
        groups
      };

      SETData(`energy/graphs/group/${language.language_locale}`, "POST", metadata)
        .then((response) => {
          if (response !== null) {
            dispatch(setGraphActive([response.options[0].value]))
            /* To translate the key 'group' for the search input in GraphTypePicker ⬇️ */
            const translatedGraphs = response.graphsGroups.map((graph) => {
              const translationKey = `energy_graph_group_${graph.group.toLowerCase()}`
              const translatedGroup = language[translationKey]

              return {
                ...graph,
                translatedGroup: translatedGroup,
              }
            })
            /* Adding the new array with the translation */
            const graphResponse = response
            graphResponse["graphsGroups"] = translatedGraphs

            dispatch(setGraphOptions(graphResponse))
          }
        })
        .catch((response) => console.error(response))

  }
}

export const loadBannersAsync = () => {
  return (dispatch, getState) => {

    const user = getState().users.user;
    const account = getState().accounts.account;

    const metadata = {
      id_account: account.idAccount,
      id_user: user.idUser,
      service: account.typeservices,
      job_area: user.job?.name,
    };

    SETData('banners', "POST", metadata)
      .then(response => {
          if (response !== null)
            dispatch(setBannersData(response));
      })
      .catch(response => console.error(response));

  }
}

export const loadIndicatorsAsync = () => {
  return async (dispatch, getState) => {

    dispatch(setIndicatorsloading(true));

    let indicatorsData = [];

    const userId = getState().users.user.idUser;
    const language = getState().shared.language.language_locale;
    const activeServicesEnergy = getState().services.activeServicesEnergy;

    let servicesIds = activeServicesEnergy
      .filter(s => s.type.name !== "generation")
      .map(s => s.idService);

    const totalServicesIds = getState().services.totalServices
      .map(s => s.idService);

    let metadata = {
      id_services: servicesIds,
      type: "WEB"
    };

    const metadataInvoice = {
      id_user: userId,
      id_services: totalServicesIds
    };

    let promises = [];

    promises.push(Promise.resolve(SETData(`energy/indicators/${language}`, 'POST', metadata)));
    promises.push(Promise.resolve(SETData('bills/latest/summary', 'POST', metadataInvoice)));

    await Promise.all(promises).then(([indicatorsResponse, invoicesResponse]) => {

      if (indicatorsResponse !== null) {
        indicatorsData = [...indicatorsData, ...indicatorsResponse]
      }

      if (invoicesResponse.length > 0) {
        dispatch(setInvoiceInfo(invoicesResponse[0]));
        dispatch(invoicesActions.setInvoicesDate(new Date(invoicesResponse[0].year, invoicesResponse[0].month - 1, 1)));
      }

    })
    .catch(response => console.error(response));

    servicesIds = activeServicesEnergy
      .filter(s => s.type.name === "generation")
      .map(s => s.idService);

    if (servicesIds.length) {

      metadata = {
        id_services: servicesIds,
        type: "WEB"
      };

      await SETData(`generation/indicators/${language}`, 'POST', metadata, process.env.REACT_APP_GENERATION_API_URL)
      .then(response => {

        if (response !== null) {
          indicatorsData = [...indicatorsData, ...response]
        }
      })
      .catch(response => console.error(response));
    }

    dispatch(setIndicators(indicatorsData));
    dispatch(setIndicatorsloading(false));

  };
};

export const createReportAsync = (payload) => {
  return async (dispatch, getState) => {

    dispatch(setReportloading(true));

    let interval;
    let metadata;    

    const graphs = getState().energy.graphs[0];
    const userId = getState().users.user.idUser; 
    const language = getState().shared.language; 
    const from = getUnixTime(payload.startTime);
    const to = getUnixTime(payload.endTime);

    const activeServicesEnergy = getState().services.activeServicesEnergy;
    const activeGraphs = getState().energy.activeGraphs;

    switch (getState().energy.intervalGraph) {
      case 0:
        interval = 'HOURS';
        break;
      case 1:
        interval = 'DAYS';
        break;
      case 2:
        interval = 'DAYS';
        break;
      case 3:
        interval = 'MONTH';
        break;
      default:
        break;
    }

    const services = activeServicesEnergy      
      .map(s => {
        return {
          id_service: s.idService,
          name: s.name
        }
      });

    let graphsOptions = graphs.options.filter(x => activeGraphs.includes(x.value)).map((graph) => {      
      return {
        'graph_type': `${graph.value}_${interval}`,
        'label': graph.label,
        'unit': graph.unit,
        'type': graph.type,
        'group': graph.graph_type,
        'value': graph.value        
      }
    });    

    if (graphsOptions.length){

      metadata = {
        id_user: userId,
        report_name: payload.report_name,
        services,
        graphs_options: graphsOptions,
        format_type: payload.format_type,
        graph_type: interval        
      };     

      await SETData(`energy/reports/${from}/${to}?version=2`, "POST", metadata)
        .then(async response => {
            if (response !== null) {
              if(response.currentstatus === 1) {    

                const url = await GETS3PublicUrl(`reports/${userId}/${response.filename}`);
                
                const anchor = document.createElement("a");
                anchor.href= url;
                anchor.download= "name";
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                
                dispatch(setShowReportModal(false));  
              } 
              else {
                Alert.error(language.alert_download_report_error)
              }           
            }
        })
        .catch(response => console.error(response))
        .finally(() => dispatch(setReportloading(false)));
    }
  }
}

export const loadDetailService = (payload) => {
  return (dispatch, getState) => {
    
    const services = getState().services.totalServices;
    const accounts = getState().accounts.totalAccounts

    let service = services.find(s => s.idService === parseInt(payload.idService));    
    
    if(service) {
      dispatch(accountsActions.setAccount(accounts.find(s => s.idAccount === parseInt(service?.idAccount))));
      dispatch(servicesActions.setActiveServicesEnergy([service]));      
      if(service?.type?.typeService === 2) {
        dispatch(servicesActions.setActiveServicesPlus([service]));
      }else{
        dispatch(servicesActions.setActiveServicesPlus([]));
      }  
    }

  }
}