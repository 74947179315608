import React from "react";
import { connect } from 'react-redux';

import ContentLoader from "react-content-loader"

import * as servicesActions from '../../../../store/actions/Services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';

import Services from "../../../shared/Services";

import _ from 'lodash';

const servicesLoading = (
    <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
        <ContentLoader seed={2} backgroundColor="#161617" foregroundColor="#323234" style={{ width: "100%", height: "100%" }}>
            <rect x="0" y="0" width="220" height="33" rx="17" ry="17"></rect>
        </ContentLoader>
    </div>
)

const ServicesPlus = (props) => {
    const { theme } = props;
    return (
        <div className="row-start align-items-center w-100 px-3">
            <div className={`position-relative row-between align-items-center w-100 surface-bg-surface border-fill-medium rounded-16 p-3 ${theme}`}>
                <div className="column-start gap-3">
                    <p className={`label-small-regular content-default-tertiary ${theme}`}>{props.language.energy_main_title_dashboard}</p>
                    {props.activeServicesPlus.length ?
                        <div className={`row-center align-items-center rounded-pill surface-bg-on-surface px-3 ${theme}`} style={{ height: 32 }}>
                            <FontAwesomeIcon icon={fal[props.activeServicesPlus[0]?.style ? props.activeServicesPlus[0]?.style.icon : "faBuilding"]} className={`label-small-regular content-default-tertiary ${theme}`} />
                            <div className={`ms-3 label-small-regular content-default-primary text-nowrap ${theme}`}>{props.activeServicesPlus[0]?.name}</div>
                        </div> :
                        <div className="position-relative" style={{ height: 33, width: 280 }}>{servicesLoading}</div>
                    }
                </div>
                <Services availableTypeServices={["plus"]} activeServices={props.activeServicesPlus} module={props.module} selectFunction={value => { props.setActiveServicesPlus([_.last(value)]); props.setActiveServicesEnergy([_.last(value)]) }} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    language: state.shared.language,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
    setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
    setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPlus);