import React from "react";
import { connect } from 'react-redux';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

const User = (props) => {

    let user = props.user;

    const { theme } = props;

    const getProgressStyle = () => {
        
        return {
            path: { stroke: theme === 'dark' ? '#9ecc14'  : '#7da60c' },
            trail: { stroke: theme === 'dark' ? '#262626'  : '#fafafa' }
        }
    }

    return (
        <div className="w-100 px-4">
            <div className="overflow-hidden">
                <div className="d-inline-flex align-items-center" onClick={() => props.setShowAccount(true)}>
                    <div className="mx-3" style={{ height: 54, width: 54 }}>
                        <CircularProgressbarWithChildren value={props.progress * 100} strokeWidth={6} styles={getProgressStyle()}>
                            <div className="row-center align-items-center h-100 w-100" style={{ padding: 6 }}>
                                <div className="row-center align-items-center rounded-circle bg-gray-400 h-100 w-100">
                                    {!user.image ?
                                        <i className="fa-solid fa-user txt-gray-500 txt-12"></i> :
                                        <img className="user-img w-100 h-100 overflow-hidden rounded-circle" src={`${process.env.REACT_APP_IMAGES_URL}/profile-photos/${user.idUser}/150x150/${user.image}`} alt="user-profile" />
                                    }
                                </div>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className="column-center align-items-start">
                        <div className="txt-phrase txt-bold txt-8 txt-white pe-pointer text-nowrap">{user.name} {user.lastname}</div>
                    </div>
                </div>
            </div>
        </div>);
}

const mapStateToProps = (state) => ({
    user: state.users.user,
    progress: state.users.profileProgress,
    account: state.accounts.account,
    theme: state.theme.theme,
})

export default connect(mapStateToProps)(User)