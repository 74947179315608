import React from "react";

export const RadialBlueGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <radialGradient id="radialBlueGradient" gradientTransform="translate(-0.5 -0.5) scale(2, 2)">
                <stop offset="0%" stopColor="transparent" />
                <stop offset="100%" stopColor="var(--blue-500)" />
            </radialGradient>
        </defs>
    </svg>
)

export const RadialBlueLightGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <radialGradient id="radialBlueLightGradient" gradientTransform="translate(-0.5 -0.5) scale(2, 2)">
                <stop offset="0%" stopColor="white" />
                <stop offset="100%" stopColor="var(--blue-600)" />
            </radialGradient>
        </defs>
    </svg>
)

export const RadialGreenGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <radialGradient id="radialGreenGradient" gradientTransform="translate(-0.5 -0.5) scale(2, 2)">
                <stop offset="0%" stopColor="transparent" />
                <stop offset="100%" stopColor="var(--brand-primary-500)" />
            </radialGradient>
        </defs>
    </svg>
)

export const RadialGreenLightGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <radialGradient id="radialGreenLightGradient" gradientTransform="translate(-0.5 -0.5) scale(2, 2)">
                <stop offset="0%" stopColor="white" />
                <stop offset="100%" stopColor="var(--brand-primary-500)" />
            </radialGradient>
        </defs>
    </svg>
)

export const RadialPurpleGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <radialGradient id="radialPurpleGradient" gradientTransform="translate(-0.5 -0.5) scale(2, 2)">
                <stop offset="0%" stopColor="transparent" />
                <stop offset="100%" stopColor="var(--purple-500)" />
            </radialGradient>
        </defs>
    </svg>
)

export const RadialPurpleLightGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <radialGradient id="radialPurpleLightGradient" gradientTransform="translate(-0.5 -0.5) scale(2, 2)">
                <stop offset="0%" stopColor="white" />
                <stop offset="100%" stopColor="var(--purple-600)" />
            </radialGradient>
        </defs>
    </svg>
)

export const LinearRedOrangeGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <linearGradient id="linearRedOrangeGradient" x1="1" y1="0.5" x2="0" y2="0.5">
                <stop offset="0" stopColor="#FC3535" />
                <stop offset="100%" stopColor="#FFAA00" />
            </linearGradient>
        </defs>
    </svg>
)