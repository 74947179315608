import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Drawer } from 'rsuite';

import _ from 'lodash';

import CheckPicker from '../shared/utilities/CheckPicker';
import BackButton from '../shared/utilities/BackButton';

const days = language => [
    {
        id_day: 1,
        label: language.alerts_select_days_monday
    },
    {
        id_day: 2,
        label: language.alerts_select_days_tuesday
    },
    {
        id_day: 3,
        label: language.alerts_select_days_wednesday
    },
    {
        id_day: 4,
        label: language.alerts_select_days_thursday
    },
    {
        id_day: 5,
        label: language.alerts_select_days_friday
    },
    {
        id_day: 6,
        label: language.alerts_select_days_saturday
    },
    {
        id_day: 7,
        label: language.alerts_select_days_sunday
    },
];

const checkTimePlus = language => [
    {
        minutes: 1,
        label: language.alerts_select_check_one_minute
    },
    {
        minutes: 5,
        label: language.alerts_select_check_five_minute
    },
    {
        minutes: 10,
        label: language.alerts_select_check_ten_minute
    },
    {
        minutes: 15,
        label: language.alerts_select_check_fifteen_minute
    },
];

const intervals = language => [
    {
        id_interval: 4,
        label: language.alerts_select_interval_secondBySecond,
        plus: true
    },
    {
        id_interval: 0,
        label: language.alerts_select_interval_hourly
    },
    {
        id_interval: 1,
        label: language.alerts_select_interval_daily
    },
    {
        id_interval: 2,
        label: language.alerts_select_interval_weekly
    },
    {
        id_interval: 3,
        label: language.alerts_select_interval_monthly
    }
];

const IntervalTime = (props) => {

    const initAlertTime = {
        dayIds: [],
        fromHour: "00",
        fromMinutes: "00",
        fromPartOfDay: 'am',
        toHour: "11",
        toMinutes: "59",
        toPartOfDay: 'pm',
        intervalIds: [],
        dates: [],
        timingPlus: -1,
    }

    const [alertTime, setAlertTime] = useState(initAlertTime);
    const [showTimePlus, setShowTimePlus] = useState(false);

    const theme = props.theme

    useEffect(() => {
        if (props.intervalTime) setAlertTime(props.intervalTime);
    }, [props.intervalTime])

    useEffect(() => {
        if (alertTime.intervalIds.length) {
            if (alertTime.intervalIds[0] === 4) {
                setShowTimePlus(true)
            }
            else {
                setShowTimePlus(false)
            };
        }
    }, [alertTime])

    const format = (value, hour) => {
        value = parseInt(value, 10);
        value = value < 0 && hour ? 0 : value > 12 && hour ? 12 : value;
        value = value < 0 && !hour ? 0 : value > 59 && !hour ? 59 : value;
        value = value < 10 ? '0' + String(value) : String(value);
        return value;
    }

    const RenderDays = ({ options, labelName, selectedData, keyName, onChange }) => {
        return (
            <div className='row-start gap-2 w-100 flex-wrap align-items-center my-2'>
                {options.length && options.map((item, index) => {
                    let isSelected = selectedData.includes(item[keyName]);
                    return <button key={index} className={`row-between align-items-center rounded-16 surface-bg-on-surface py-2 px-3 ${isSelected ? 'border-color-green' : 'border-surface-bg-on-surface'} mt-2 ${theme}`} style={{ width: 'fit-content' }}
                        onClick={() => {
                            if (isSelected) {
                                onChange(selectedData.filter(value => value !== item[keyName]));
                            } else {
                                onChange([...selectedData, item[keyName]]);
                            }
                        }}
                    >
                        <p className={`${isSelected ? 'content-color-green' : 'content-default-primary'} label-small-regular ${theme}`}>{item[labelName]}</p>
                        {isSelected && <i className={`ms-2 fa-light fa-check-circle content-color-green txt-10 ${theme}`}></i>}
                    </button>
                })}
            </div>
        )
    }

    const RenderTiming = ({ options, labelName, selectedData, keyName, onChange }) => {
        return (
            <div className='row-start gap-2 w-100 flex-wrap w-100 align-items-center my-2'>
                {options.length && options.map((item, index) => {
                    let isSelected = selectedData === item[keyName];
                    return <button key={index} className={`row-between align-items-center rounded-16 surface-bg-on-surface py-2 px-3 ${isSelected ? 'border-color-green' : 'border-surface-bg-on-surface'} mt-2 ${theme}`} style={{ width: 'fit-content' }}
                        onClick={() => {
                            if (isSelected) {
                                onChange(-1);
                            } else {
                                onChange(item[keyName]);
                            }
                        }}
                    >
                        <p className={`txt-truncate ${isSelected ? 'content-color-green' : 'content-default-primary'} label-small-regular ${theme}`}>{item[labelName]}</p>
                        {isSelected && <i className={`ms-2 fa-light fa-check-circle content-color-green txt-10 ${theme}`}></i>}
                    </button>
                })}
            </div>
        )
    }

    return (
        <Drawer
            show={props.showAlertPeriod}
            size={'xs'}
            placement={'right'}
            backdropClassName={`pe-pointer ${theme}`}
            onHide={() => { props.setShowPeriodAlert(false) }}>
            <div className={`position-relative vh-100 w-100 overflow-auto surface-bg-surface ${theme}`}>
                <div className='align-items-start py-3 px-4'>
                    <div className='row-between w-100 align-items-center my-3'>
                        <BackButton theme={theme} onClick={() => props.setShowPeriodAlert(false)}></BackButton>
                        <div className='row-center align-items-center gap-2'>
                            <p className={`heading-h6-bold content-default-primary ${theme}`}>{props.language.alerts_title_interval_time}</p>
                        </div>
                    </div>
                    <p className={`paragraph-small-regular content-default-secondary ${theme}`}>{props.language.alerts_interval_time_body_frequency}</p>
                    <div className='w-100 my-3'>
                        <CheckPicker
                            keyName='id_interval'
                            labelName='label'
                            options={intervals(props.language)}
                            selectedData={alertTime.intervalIds}
                            onChange={(value) => { setAlertTime({ ...alertTime, intervalIds: [_.last(value)] }) }}
                            plusSelected={props.plusSelected}
                            theme={theme}
                            backgroundColor='surface-bg-on-surface-alpha'
                            border={true}
                        />
                    </div>
                    {showTimePlus ?
                        <div className='w-100 mb-4'>
                            <p className={`label-large-semibold content-default-secondary ${theme}`}>{props.language.alerts_interval_time_title_plus_check}</p>
                            <RenderTiming
                                keyName='minutes'
                                labelName='label'
                                options={checkTimePlus(props.language)}
                                selectedData={alertTime.timingPlus}
                                onChange={(value) => { setAlertTime({ ...alertTime, timingPlus: value }) }}
                            />
                        </div> : null}
                    <div className='w-100'>
                        <p className={`label-large-semibold content-default-secondary ${theme}`}>{props.language.alerts_interval_time_title_days}</p>
                        <RenderDays
                            keyName='id_day'
                            labelName='label'
                            options={days(props.language)}
                            selectedData={alertTime.dayIds}
                            onChange={(value) => { setAlertTime({ ...alertTime, dayIds: value }) }}
                        />
                    </div>
                    <p className={`mt-3 label-large-semibold content-default-secondary ${theme}`}>{props.language.alerts_interval_time_title_range}</p>
                    <p className={`w-100 text-wrap paragraph-small-regular content-default-secondary ${theme}`}>{props.language.alerts_interval_time_title_body}</p>
                    <div className='w-100 pb-5'>
                        <div className={`row-between align-items-center px-3 py-2 surface-bg-on-surface rounded-8 w-100 mt-3 ${theme}`}>
                            <div className='row-start align-items-center'>
                                <p className={`me-5 paragraph-small-regular content-default-primary ${theme}`}>{props.language.alerts_title_initial_time}</p>
                                <div className={`row-center surface-bg-surface rounded-8 p-2 gap-2 align-items-center ${theme}`}>
                                    <i className={`fas fa-clock txt-10 content-color-green ${theme}`}></i>
                                    <input placeholder={"00"} type="number" value={alertTime.fromHour} style={{ width: 20, border: 'none' }} className={`surface-bg-surface ${alertTime.fromHour ? 'content-default-primary' : 'content-default-secondary'} ${theme}`} name="number" step="1" onChange={({ target }) => { setAlertTime({ ...alertTime, fromHour: format(target.value, true) }) }} />
                                    <p className={`txt-phrase txt-regular content-default-primary txt-8 ${theme}`}>:</p>
                                    <input placeholder={"00"} type="number" value={alertTime.fromMinutes} style={{ width: 20, border: 'none' }} className={`surface-bg-surface ${alertTime.fromMinutes ? 'content-default-primary' : 'content-default-secondary'} ${theme}`} name="number" step="1" onChange={({ target }) => { setAlertTime({ ...alertTime, fromMinutes: format(target.value, false) }) }} />
                                    <select value={alertTime?.fromPartOfDay} onChange={item => setAlertTime({ ...alertTime, fromPartOfDay: item.target.value })} className={`surface-bg-surface content-default-primary ${theme}`} style={{ outline: 'none', border: 'none' }}>
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row-between align-items-center px-3 py-2 surface-bg-on-surface rounded-8 w-100 mt-3'>
                            <div className='row-start align-items-center'>
                                <p className={`me-5 paragraph-small-regular content-default-primary ${theme}`}>{props.language.alerts_title_final_time}</p>
                                <div className={`row-center surface-bg-surface rounded-8 p-2 gap-2 align-items-center ${theme}`}>
                                    <i className={`fas fa-clock txt-10 content-color-green ${theme}`}></i>
                                    <input placeholder={"00"} type="number" value={alertTime.toHour} style={{ width: 20, border: 'none' }} className={`surface-bg-surface ${alertTime.toHour ? 'content-default-primary' : 'content-default-secondary'} ${theme}`} name="number" step="1" onChange={({ target }) => { setAlertTime({ ...alertTime, toHour: format(target.value, true) }) }} />
                                    <p className={`txt-phrase txt-regular content-default-primary txt-8 ${theme}`}>:</p>
                                    <input placeholder={"00"} type="number" value={alertTime.toMinutes} style={{ width: 20, border: 'none' }} className={`surface-bg-surface ${alertTime.toMinutes ? 'content-default-primary' : 'content-default-secondary'} ${theme}`} name="number" step="1" onChange={({ target }) => { setAlertTime({ ...alertTime, toMinutes: format(target.value, false) }) }} />
                                    <select value={alertTime?.toPartOfDay} onChange={item => setAlertTime({ ...alertTime, toPartOfDay: item.target.value })} className={`surface-bg-surface content-default-primary ${theme}`} style={{ outline: 'none', border: 'none' }}>
                                        <option value="am">{props.language.alert_time_am}</option>
                                        <option value="pm">{props.language.alert_time_pm}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`position-fixed bottom-0 w-100 row-end align-items-center p-3 border-fill-medium-top surface-bg-surface ${theme}`}>
                    <button
                        className={`row-center align-items-center py-2 px-3 rounded-30 button-fill-brand button-content-equal label-small-regular ${theme}`}
                        onClick={() => { props.onChange(alertTime); props.setShowPeriodAlert(false) }}>
                        Guardar intervalo
                    </button>
                </div>
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(IntervalTime);