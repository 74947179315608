import React from "react"
import { Modal } from "rsuite"

const DeleteAlertModal = ({ openModal, setOpenModal, deleteAlertAsync, alert, language, theme }) => {
    return (
        <Modal
            size="xs"
            show={openModal}
            overflow={false}
            backdropClassName={`pe-pointer ${theme}`}
            dialogClassName="rounded"
            onHide={() => setOpenModal(false)}>
            <div className={`column-start w-100 h-100 px-4 py-3 surface-bg-surface border-fill-medium rounded-16 ${theme}`}>
                <div>
                    <p className={`heading-h6-bold content-default-primary ${theme}`}>
                        <i className="fa-solid fa-do-not-enter"></i>{" "}
                        {language.alerts_messager_delete_title_confirmation}
                    </p>
                </div>
                <p className={`label-medium-medium content-default-primary py-3 ${theme}`}>{language.alerts_messager_delete_confirmation}</p>
                <div className="row-end align-items-center gap-3">
                    <button className={`button-fill-brand rounded-30 py-2 px-3 ${theme}`} onClick={() => { setOpenModal(false) }}>
                     <p className={`label-small-regular pe-pointer button-content-equal ${theme}`}>{language.alerts_messager_keep_option}</p>
                    </button>
                    <button className={`py-2 px-3 rounded-30 button-fill-error ${theme}`} onClick={async () => { setOpenModal(false); await deleteAlertAsync(alert.id_alert); }}>
                        <p className={`label-small-regular pe-pointer txt-white ${theme}`}>{language.alerts_messager_delete_option}</p>
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteAlertModal