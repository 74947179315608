import React, { useCallback, useEffect, useMemo, useState } from "react";

import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import Tooltip from "../utils/TooltipCustom";

import { track } from "@amplitude/analytics-browser";

const joyrideStyles = {
    options: {
        arrowColor: '#1f1f20',
        overlayColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 10000
    },
};

export default function useTour(props) {
    // const {
    //     steps,
    //     localStorageKey,
    //     isOpenDrawer,
    //     controlledOnboarding = null,
    //     setShowPulseAnimation = null,
    //     //setControlledOnboarding,        
    //     runTour,
    //     setRunTour,
    //     key,
    //     onTourFinish,
    // } = props;
    // const [run, setRun] = useState(false);
    // const [stepIndex, setStepIndex] = useState(0);
    // const [stepIndexControlled, setStepIndexControlled] = useState(0);

    // useEffect(
    //     function () {
    //         const tourViewed = window.localStorage.getItem(localStorageKey);
    //         if (tourViewed) {
    //             return;
    //         }
    //         window.localStorage.setItem(localStorageKey, "show");
    //         if (isOpenDrawer === null) { setRun(true); }
    //     },
    //     [localStorageKey, isOpenDrawer]
    // );

    // useEffect(
    //     function () {
    //         const tourViewed = window.localStorage.getItem(localStorageKey);

    //         if (isOpenDrawer && tourViewed === 'show') {
    //             if (setRunTour && key) {
    //                 if (key === 'new_user' || key === 'new_alert') {
    //                     setTimeout(() => {
    //                         setRunTour({ key: key, value: true });
    //                     }, 500);
    //                 } else {
    //                     setRunTour({ key: key, value: true });
    //                 }
    //             }
    //             return;
    //         };
    //     },
    //     [localStorageKey, isOpenDrawer, setRunTour, key]
    // );

    // useEffect(
    //     function () {
    //         if (controlledOnboarding) {
    //             setRun(false);
    //             setStepIndex(stepIndex + 1);
    //             setStepIndexControlled(stepIndex + 1);
    //         }
    //     },
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     [controlledOnboarding]
    // );

    // useEffect(() => {
    //     if (runTour) {
    //         setRun(true);
    //     }
    // }, [runTour]);

    // const handleJoyrideCallback = useCallback(function (data) {        
    //     const { action, index, type, status } = data;
    //     const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];        

    //     if (data.step?.pulseEffect) {
    //         if (setShowPulseAnimation) {
    //             setShowPulseAnimation(true);
    //         }
    //     } else {
    //         if (setShowPulseAnimation) {
    //             setShowPulseAnimation(false);
    //         }
    //     }

    //     if (finishedStatuses.includes(status)) {

    //         setRun(false);
    //         setStepIndex(0);

    //         if (onTourFinish) {
    //             const tourViewed = window.localStorage.getItem(localStorageKey);
    //             if (tourViewed !== 'delivered') { onTourFinish() }
    //         };

    //         if (setRunTour && key) setRunTour({ key: key, value: false });

    //         window.localStorage.setItem(localStorageKey, 'delivered');

    //         if (status === 'skipped') {
    //             setRunTour({ key: 'walktrough', value: true });
    //             track('Onboarding skip clicked');
    //         }

    //     } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {

    //         const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

    //         if (controlledOnboarding && index === (stepIndexControlled - 1)) {
    //             setTimeout(() => {
    //                 setRun(true);
    //             }, 400);
    //         }
    //         else {
    //             setStepIndex(nextStepIndex);
    //         }
    //     }
    // }, [controlledOnboarding, localStorageKey, setStepIndex, stepIndexControlled, setRunTour, key, onTourFinish, setShowPulseAnimation]);

    // const tour = useMemo(
    //     () => (
    //         <Joyride
    //             tooltipComponent={Tooltip}
    //             callback={handleJoyrideCallback}
    //             continuous={true}
    //             run={run}
    //             scrollToFirstStep={true}
    //             showSkipButton={true}
    //             steps={steps}
    //             styles={joyrideStyles}
    //             disableScrolling={true}
    //             stepIndex={stepIndex}
    //             floaterProps={{ styles: { arrow: { spread: 32 } } }}
    //             disableCloseOnEsc={true}                
    //         />
    //     ),
    //     [steps, handleJoyrideCallback, run, stepIndex]
    // );

    // return tour;
    return <></>;
}