import React, { useState, Fragment } from "react";

import { Popover } from "@idui/react-popover";

import Tooltip from "../shared/utilities/Tooltip";

import { useWindowSize } from "../shared/utilities/useWindowSize";

const SubMenu = ({
  data,
  selectedData,
  newData,
  language,
  onChange,
  keyName,
  labelName,
  theme,
}) => {
  const [show, setShow] = useState(false);
  const [activatedAllOptions, setActivatedAllOptions] = useState(false);

  function checkMultipleExist(arr, values) {
    const multipleExist = values.every((value) => {
      return arr.includes(value.id_graph);
    });

    return multipleExist;
  }

  function mergeObjectsWithoutDuplicateIds(selected, list) {
    const mergedIds = selected.concat(list.map((item) => item.id_graph));
    const uniqueIds = mergedIds.filter(
      (id, index) => mergedIds.indexOf(id) === index,
    );
    return uniqueIds;
  }

  const screenSize = useWindowSize();

  return (
    <Popover
      lazy
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"click"}
      isOpen={show}
      placement="right"
      guessBetterPosition
      className={`p-0 rounded-8 genius-picker surface-bg-surface ${theme}`}
      withArrow={true}
      arrowColor={theme === 'dark' ? '#141414' : '#ffff'}
      arrowSize={20}
      width="400px"
      offset={screenSize.width < 1400 ? [0, 10] : [0, 0]}
      openingAnimationTranslateDistance={0}
      spaceBetweenPopoverAndTarget={30}
      onChangeOpen={(state) => {
        setActivatedAllOptions(checkMultipleExist(selectedData, data));
        setShow(state);
      }}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={
        <Fragment>
          <div className="w-100 px-3 overflow-auto border-none-scroll max-height-230 max-height-500-2xl">
            {data.length ? (
              <div className="column-start graphs-picker-container mb-3">
                <div className="row-between align-items-center w-100 mt-3 mb-2">
                  <p
                    className={`pe-pointer label-small-regular content-color-green ${theme}`}
                    onClick={() => {
                      if (!activatedAllOptions || !selectedData.length) {
                        onChange(
                          mergeObjectsWithoutDuplicateIds(selectedData, data),
                        );
                      } else {
                        onChange(
                          selectedData.filter(
                            (id) => !data.some((item) => item.id_graph === id),
                          ),
                        );
                      }
                    }}
                  >
                    {language.invoices_text_selec_all}
                  </p>
                </div>
                {data.map((item) => {
                  let isSelected = selectedData.includes(item[keyName]);
                  let isNew = false;

                  if (newData.length)
                    isNew = newData.findIndex((i) => i === item[keyName]) >= 0;

                  return (
                    <div
                      key={item.id_report}
                      className={`row-between align-items-center pe-pointer p-2 my-1 rounded-8 ${
                        isSelected
                          ? "surface-bg-on-surface"
                          : "surface-bg-surface"
                      } ${theme}`}
                      onClick={() => {
                        if (isSelected) {
                          onChange(
                            selectedData.filter(
                              (value) => value !== item[keyName],
                            ),
                          );
                        } else {
                          onChange([...selectedData, item[keyName]]);
                        }
                      }}
                    >
                      <div className="row-start align-items-center">
                        <div
                          className={`pe-pointer me-2 label-small-medium content-default-primary ${theme}`}
                        >
                          {language
                            ? language[item[labelName]]
                            : item[labelName]}
                        </div>
                        {language[item.tooltip] !== "" ? (
                          <Tooltip
                            text={`${language[`${item.tooltip}`]}`}
                            fontIcon={8}
                            fontSize={6}
                            typeIcon="light"
                            colorIcon={`content-default-primary ${theme}`}
                            theme={theme}
                          />
                        ) : null}
                        {isNew ? (
                          <div className="ms-2 px-2 py-1 rounded-pill border-1 border-color-green-550 bg-gray-450">
                            <p className={`txt-phrase txt-regular txt-6 content-color-green ${theme}`}>
                              {language.genius_new_item}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <i
                        className={`fal fa-check-circle ${
                          isSelected ? "content-color-green" : "content-default-secondary"
                        } txt-10 ${theme}`}
                      ></i>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div
            className={`row-end py-3 px-1 mx-3 border-fill-medium-top ${theme}`}
          >
            <button
              className={`p-2 rounded-16 label-small-regular button-fill-brand button-content-equal ${theme}`}
              onClick={() => setShow(false)}
            >
              {language.genius_done_button}
            </button>
          </div>
        </Fragment>
      }
    >
      <i
        className={`fa-light fa-circle-chevron-right txt-10 ${
          show ? "content-color-green" : "content-default-secondary"
        } ${theme}`}
      ></i>
    </Popover>
  );
};

export default SubMenu;
