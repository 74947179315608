import React from "react";
import Popover from "@idui/react-popover";

const ExtraItemsPopover = ({
  theme,
  content,
  button,
  placement = "bottomLeft",
  withArrow = true,
}) => {
  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"hover"}
      placement={placement}
      withArrow={withArrow}
      className={`p-0 surface-bg-surface ${theme}`}
      arrowColor={"#00000000"}
      arrowSize={20}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={content}
    >
      {button}
    </Popover>
  );
};

export default ExtraItemsPopover;
