import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";

import * as accountsActions from "../../store/actions/Accounts";
import * as notificationsActions from "../../store/actions/Notifications";
import * as themeActions from "../../store/actions/Theme";

import Support from "./Support";
import Neugets from "./Neugets";
import Notifications from "../notifications/Notifications";

import BackButton from "./utilities/BackButton";

import Chatbot from "./Chatbot";
import { themes } from "../../utils/enums";

const Header = (props) => {
  let history = useHistory();

  let account = props.account;
  let user = props.user;
  let totalNotificationsInfo = props.totalNotificationsInfo;

  const loadNeugetsByAccountAsync = props.loadNeugetsByAccountAsync;
  const loadTotalNotificationsAsync = props.loadTotalNotificationsAsync;

  const [showSupport, setShowSupport] = useState(false);
  const [showNeugets, setShowNeugets] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showChatBot, setShowChatBot] = useState(false);
  const { theme, setTheme } = props;

  const toggleTheme = () => {
    const newTheme = theme === themes.LIGHT ? themes.DARK : themes.LIGHT;
    setTheme(newTheme);
  };

  useEffect(() => {
    if (account !== null) loadNeugetsByAccountAsync();
  }, [account, loadNeugetsByAccountAsync]);

  useEffect(() => loadTotalNotificationsAsync(), [loadTotalNotificationsAsync]);

  return (
    <Fragment>
      <header
        id="navigate_dashboard"
        className={`row-between align-items-center w-100 px-3 surface-bg-fill ${theme}`}
      >
        <div className="row-center align-items-center gap-3 py-3">
          {props.backToButton && (
            <BackButton onClick={() => history.goBack()} theme={theme} />
          )}
          <span
            className={`content-default-primary max-width-400 heading-h4-bold  ${theme}`}
          >
            {props.moduleName}
          </span>
        </div>
        <div className="row-start align-items-center">
          {
            <Chatbot
              showChatBot={showChatBot}
              setShowChatBot={setShowChatBot}
              enableChatBot={user?.capabilities?.show_chatbot}
            />
          }
          {user.capabilities?.show_header_emergency_button && (
            <div
              id="navigate_emergency"
              className={`button-support pe-pointer surface-bg-on-surface border-fill-medium rounded-pill overflow-hidden me-3 ${theme}`}
              onClick={() => {
                window.open(
                  user.userType.idUserType === 11
                    ? "https://api.whatsapp.com/send?phone=50765229500&text=%C2%A1Estamos%20felices%20de%20poder%20ayudarte!"
                    : user.capabilities?.emergency_line,
                );
              }}
            >
              <div className="row-center align-items-center h-100">
                <div className="button-icon row-center align-items-center">
                  <i
                    className={`fa-light fa-light-emergency-on txt-12 content-default-primary ${theme}`}
                  />
                </div>
                <span
                  className={`txt-phrase txt-regular txt-8 txt-overflow-unset content-default-primary ${theme} pe-pointer`}
                >
                  {props.language.support_emergency_button}
                </span>
              </div>
            </div>
          )}
          {user.capabilities?.show_header_support_button && (
            <div
              id="navigate_support"
              className={`button-support pe-pointer surface-bg-on-surface border-fill-medium rounded-pill overflow-hidden me-3 button-content-inverse ${theme}`}
              onClick={() => {
                setShowSupport(true);
              }}
            >
              <div className="row-center align-items-center h-100">
                <div className="button-icon row-center align-items-center">
                  <i
                    className={`fa-light fa-headset txt-12 content-default-primary ${theme}`}
                  />
                </div>
                <span
                  className={`txt-phrase txt-regular txt-8 txt-overflow-unset content-default-primary  pe-pointer ${theme}`}
                >
                  {props.language.support_help_button}
                </span>
              </div>
            </div>
          )}
          {user.capabilities?.show_header_notifications_button && (
            <div
              id="navigate_notification"
              className={`button-support pe-pointer surface-bg-on-surface border-fill-medium rounded-pill overflow-hidden me-3 ${theme}`}
              onClick={() => {
                setShowNotifications(true);
              }}
            >
              <div className="row-center align-items-center h-100">
                <div className="position-relative button-icon row-center align-items-center">
                  <i
                    className={`fa-light fa-bell txt-12 content-default-primary ${theme}`}
                  />
                  <i
                    className={`button-icon-notification trans-fade fa-solid fa-circle txt-2 content-color-green dark opacity-${
                      totalNotificationsInfo?.recently_unread > 0 ? "100" : "0"
                    }`}
                  />
                </div>
                <span
                  className={`txt-phrase txt-regular txt-8 txt-overflow-unset content-default-primary pe-pointer ${theme}`}
                >
                  {props.language.notifications_title}
                </span>
              </div>
            </div>
          )}
          {user.capabilities?.show_header_notifications_button && (
            <div
              id="navigate_notification"
              className={`surface-bg-on-surface border-fill-medium rounded-pill overflow-hidden me-3  ${theme}`}
              onClick={toggleTheme}
            >
              <div className="row-center align-items-center h-100">
                <div className="position-relative button-icon row-center align-items-center">
                  <i
                    className={`fa-light fa-${
                      theme === "dark" ? "sun-bright" : "moon"
                    } txt-12 content-default-primary ${theme}`}
                  />
                </div>
                {/* <span button-support pe-pointer 
                className={`txt-phrase txt-regular txt-8 txt-overflow-unset content-default-primary pe-pointer ${theme}`}
              >
                {theme === "dark"
                  ? props.language.dark
                  : props.language.light}
              </span> */}
              </div>
            </div>
          )}
        </div>
      </header>
      <Support showSupport={showSupport} setShowSupport={setShowSupport} />
      <Neugets showNeugets={showNeugets} setShowNeugets={setShowNeugets} />
      <Notifications
        showNotifications={showNotifications}
        setShowNotifications={setShowNotifications}
      />
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  moduleName: ownProps.moduleName,
  backToButton: ownProps.backToButton,
  language: state.shared.language,
  neugets: state.accounts.neugets,
  account: state.accounts.account,
  totalNotificationsInfo: state.notifications.totalNotificationsInfo,
  user: state.users.user,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  loadNeugetsByAccountAsync: () =>
    dispatch(accountsActions.loadNeugetsByAccountAsync()),
  loadTotalNotificationsAsync: () =>
    dispatch(notificationsActions.loadTotalNotificationsAsync()),
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
