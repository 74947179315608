import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Drawer } from 'rsuite';

import NewAlert from './NewAlert';
import PopoverAlerts from './PopoverAlerts';
import * as alertsActions from '../../store/actions/Alerts';
import * as plus from '../../store/actions/Plus';
import * as energyActions from '../../store/actions/Energy';

import emptyDataAlerts from '../../imgs/empty_state_alerts.svg';
import emptyDataAlertsLight from '../../imgs/empty_state_alerts_light.svg';
import { track } from "@amplitude/analytics-browser";
import DeleteAlertModal from './DeleteAlertModal';
import BackButton from '../shared/utilities/BackButton';

import ContentLoader from "react-content-loader";

const AlertsLoading = ({theme}) => {

    const height = 85;

    return (
        <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1 py-1">           
            <ContentLoader
                seed={2}
                backgroundColor={theme === "light" ? "#ffffff" : "#141414"} 
                foregroundColor={theme === "light" ? "#d4d4d4" : "#262626"}
                style={{ width: "100%", height: "100%" }}>
                {Array.from({ length: 5 }, (_x, i) => i).map((i) => <rect key={i} x="0" y={`${i * (height + 10)}`} width="100%" height={height} rx="8" ry="8"></rect>)}
            </ContentLoader>
        </div>
    )
};

const RenderAlert = ({ language, alert, setShowNewAlert, setAlertSelected, setEditAlert, setOpenDeleteModal, theme }) => {
    return (
        <div className={`column-start rounded-8 p-3 surface-bg-on-surface border-fill-medium ${theme}`} style={{height: '8rem'}}>
            <div className='row-between w-100 align-items-center'>
                <i className={`fa-solid fa-square-bolt txt-16 content-color-green ${theme}`}></i>
                <PopoverAlerts
                    onChangeEdit={() => { setAlertSelected(alert); setShowNewAlert(true); setEditAlert(true) }}
                    onChangeDuplicate={() => { setAlertSelected(alert); setShowNewAlert(true); setEditAlert(false) }}
                    setOpenDeleteModal={setOpenDeleteModal}
                />
            </div>
            <p className={`col-10 my-3 label-small-semibold content-default-primary txt-truncate ${theme}`}>{alert?.alert_name ? alert?.alert_name : language.alerts_label_no_name + alert?.id_alert}</p>
            <div className='col-10 row-start align-items-center'>
                <i className={`fal fa-building txt-10 me-2 content-default-tertiary ${theme}`}></i>
                <p className={`label-XSmall-regular content-default-tertiary ${theme}`}>{alert?.services[0]?.name}</p>
            </div>
        </div>
    )
}

const Alerts = (props) => {

    const [showNewAlert, setShowNewAlert] = useState(false);
    const [alertSelected, setAlertSelected] = useState(null);
    const [editAlert, setEditAlert] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    let showBlackScreenDrawer = props.showBlackScreenDrawer;
    let showDrawerAlerts = props.showDrawerAlerts;
    let devices = props.devices;

    const loadAlertsAsync = props.loadAlertsAsync;
    const loadDevices = props.loadDevices;
    const loadGraphOptionsAsync = props.loadGraphOptionsAsync;
    const theme = props.theme;

    useEffect(() => loadAlertsAsync(), [loadAlertsAsync]);  
    useEffect(() => loadGraphOptionsAsync(true), [loadGraphOptionsAsync]);   
    useEffect(() => {
        if (!devices.length) loadDevices();
    }, [loadDevices, devices]);

    return (
        <Drawer
            show={showDrawerAlerts}
            size={'xs'}
            placement={'right'}
            backdropClassName={`pe-pointer ${theme}`}
            onHide={() => { props.setShowDrawerAlerts(false) }}
            onEntering={() => track('Alert viewed', {Project_Type:props.activeServicesEnergy.map(function (service) { return service?.type?.name })})}>
            <div id='alerts_container' className={`column-start align-items-center vh-100 w-100 overflow-auto surface-bg-surface ${theme}`}>
                {
                    showBlackScreenDrawer ?
                        <div className="position-absolute left-0 top-0 h-100 w-100 zindex-5">
                            <div className={`w-100 h-100 surface-bg-blackout ${theme}`}/>
                        </div> : null
                }
                <div className='column-start align-items-start px-4'>
                    <div className='row-between w-100 align-items-center my-3'>
                        <BackButton theme={theme} onClick={() => { props.setShowDrawerAlerts(false); }} />
                        <p className={`flex-grow-1 text-end heading-h6-bold content-default-primary ${theme}`}>{props.language.alerts_label_main_title_drawer}</p>
                    </div>
                    <div className={`column-center w-100 align-items-center p-3 mt-3 rounded-16 surface-bg-on-surface border-fill-medium ${theme}`}>
                        <i className={`fas fa-sensor-on txt-18 content-default-tertiary ${theme}`}></i>
                        <p className={`w-100 text-wrap text-center mt-3 paragraph-small-regular content-default-primary ${theme}`}>{props.language.alerts_label_creation_indications}</p>
                        <button id='added_alert' className={`row-center align-items-center p-2 gap-2 button-fill-brand rounded-30 w-100 mt-3 ${theme} ${props.newAlert && props.showPulseAnimation && 'pulse'}`} onClick={() => { setShowNewAlert(true) }}>
                            <i className={`fas fa-plus txt-10 button-content-equal ${theme}`}></i>
                            <p className={`pe-pointer label-small-regular button-content-equal ${theme}`}>{props.language.alerts_button_add_new_alert}</p>
                        </button>
                    </div>
                    <p className={`mt-3 mb-2 pb-2 label-medium-semibold content-default-primary ${theme}`}>{props.language.alerts_label_recently_created}</p>
                    {props.loading ?                        
                        <div className="position-relative w-100 h-100 min-height-300"><AlertsLoading theme={theme}/></div>:
                        props.alerts.length ?
                            <div className="alert-container w-100" style={{ overflowY: "auto" }}>
                                {props.alerts.map((alert) => (
                                    <div key={alert.id_alert}>
                                        <RenderAlert
                                            language={props.language}
                                            alert={alert}
                                            setShowNewAlert={setShowNewAlert}
                                            setAlertSelected={setAlertSelected}
                                            setEditAlert={setEditAlert}
                                            setOpenDeleteModal={setOpenDeleteModal}
                                            theme={theme}
                                            key={alert.id_alert} />
                                        <DeleteAlertModal theme={theme} openModal={openDeleteModal} setOpenModal={setOpenDeleteModal} alert={alert} deleteAlertAsync={props.deleteAlertAsync} language={props.language} />
                                    </div>
                                ))}
                            </div> :
                            <div className="w-100 h-100 column-center align-items-center px-3 overflow-hidden">
                                <img src={theme === 'dark' ? emptyDataAlerts : emptyDataAlertsLight} className="my-4" alt="logo-empty-data-alerts" />
                                <div className={`text-center text-wrap label-small-semibold content-default-tertiary ${theme}`}>{props.language.alerts_label_title_empty_state}</div>
                                <div className={`text-center py-3 label-small-regular content-default-tertiary ${theme}`}>{props.language.alerts_label_body_empty_state}</div>
                            </div>}
                    {props.newAlert && <div className='txt-white txt-14 w-100 bg-gray-500' style={{ height: 300 }} />}
                </div>
                <NewAlert showNewAlert={showNewAlert} setShowNewAlert={setShowNewAlert} alertSelected={alertSelected} setAlertSelected={setAlertSelected} setEditAlert={setEditAlert} editAlert={editAlert} />
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    showBlackScreenDrawer: state.shared.showBlackScreenDrawer,
    alerts: state.alerts.alerts,
    loading: state.alerts.loading,
    showDrawerAlerts: state.alerts.showDrawerAlerts,
    newAlert: state.shared.startOnboarding.new_alert,
    showPulseAnimation: state.shared.showPulseAnimation,
    activeServicesEnergy: state.services.activeServicesEnergy,
    devices: state.plus.devices,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
    loadAlertsAsync: () => dispatch(alertsActions.loadAlertsAsync()),
    deleteAlertAsync: (payload) => dispatch(alertsActions.deleteAlertAsync(payload)),
    setShowDrawerAlerts: (payload) => dispatch(alertsActions.setShowDrawerAlerts(payload)),
    loadDevices: () => dispatch(plus.loadDevices()),
    loadGraphOptionsAsync: payload => dispatch(energyActions.loadGraphOptionsAsync(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);