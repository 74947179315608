import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";

import { useLocation, useHistory } from 'react-router-dom';

import * as servicesActions from "../../store/actions/Services";
import * as invoicesActions from "../../store/actions/Invoices";
import * as sharedActions from "../../store/actions/Shared";

import { format, subMonths } from "date-fns";
import { es } from "date-fns/locale";

import { Loader } from "rsuite";

import { stringFormatDate } from "../../services/CalendarHandler";
import { GETS3PublicUrl, SETBlobFile } from "../../services/WebServices";

import { Transition } from 'react-transition-group';

import useTour from "../onboarding/hook/useTour";

import InvoiceModal from "./InvoiceModal";
import InvoiceTable from "./InvoiceTable";
import InvoiceSummary from "./InvoiceSummary";
import AccountDrawer from "./account/AccountDrawer";
import ContentLoader from "react-content-loader";
import Header from "../shared/Header";
import Pay from "./pay/Pay";
import CalendarInvoices from "../shared/utilities/CalendarInvoices";

import { stepsPayment } from "../onboarding/steps/PaymentSteps";
import { stepsInvoice } from "../onboarding/steps/InvoiceSteps";
import { track } from "@amplitude/analytics-browser";


const Invoices = (props) => {

  let _invoices = props.invoices;
  let _totalServices = props.totalServices;
  let activeServicesInvoices = props.activeServicesInvoices;
  let activeServicesEnergy = props.activeServicesEnergy;
  let showCalendar = props.showCalendar;
  let account = props.account;
  let invoiceShow = props.invoiceShow;
  let invoicesPeriods = props.invoicesPeriods;
  let invoicesLoading = props.invoicesLoading;
  let user = props.user;
  let totalAccounts = props.totalAccounts;
  let energyAccounts = totalAccounts.filter(currentAccount => currentAccount.typeservices.includes('energy'));
  let currentPage = props.currentPage;
  let sortField = props.sortField;
  let sortOrder = props.sortOrder;
  let invoicesDate = props.invoicesDate;
  let moreInvoicesLoading = props.moreInvoicesLoading;
  let invoicesSummary = props.invoicesSummary;
  let theme = props.theme;

  const setShowBlackScreen = props.setShowBlackScreen;
  const loadMoreInvoicesAsync = props.loadMoreInvoicesAsync;
  const loadInvoicesAsync = props.loadInvoicesAsync;
  const loadInvoicesSummaryAsync = props.loadInvoicesSummaryAsync;
  const setShowCalendar = props.setShowCalendar;
  const loadServicesAsync = props.loadServicesAsync;
  const setInvoiceShow = props.setInvoiceShow;
  const setInvoiceShowIndex = props.setInvoiceShowIndex;
  const setInvoicesContent = props.setInvoicesContent;
  const setInvoicesContentLoading = props.setInvoicesContentLoading;
  const loadInvoicesPeriodsAsync = props.loadInvoicesPeriodsAsync;
  const setCurrentPage = props.setCurrentPage;
  const _setInvoices = props.setInvoices;
  const setActiveServicesInvoices = props.setActiveServicesInvoices;
  const setEmptyPaginationResult = props.setEmptyPaginationResult;
  const setInvoicesSummary = props.setInvoicesSummary;

  const history = useHistory();
  const location = useLocation();

  const [filteredByActiveServices, setFilteredByActiveServices] = useState(false);
  const [zoomIn, setZoomIn] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const [debounceSearchInvoices, setDebounceSearchInvoices] = useState('');
  const [searchInvoices, setSearchInvoices] = useState('');
  const [openAccountDrawer, setOpenAccountDrawer] = useState(false);
  const [openInvoiceStateModal, setOpenInvoiceStateModal] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [cleanDate, setCleanDate] = useState(false);
  const [invoiceModalIndexes, setInvoicesModalIndexes] = useState(null);
  const [selectAll, setSelectAll] = useState(true);
  const [filterPendingInvoices, setFilterPendingInvoices] = useState(false);
  const [fromEnergyAccount, setFromEnergyAccount] = useState(false);

  const searchInputContainerRef = useRef(null);
  const searchInputRef = useRef(null);
  const invoicesContainerRef = useRef(null);
  const invoiceStateModal = useRef(null);
  const accountPickerRef = useRef(null);

  const handleCalendar = (value) => {
    setShowCalendar(false);
    props.setInvoicesDate(value.graphDateFrom);
    setCurrentPage(0);
    setEmptyPaginationResult(false)
    setInvoicesContentLoading(true);
    setInvoicesContent([]);
    setCleanDate(false);
  };

  const handleDocumentClick = (event) => {
    if (searchInputContainerRef.current && !searchInputContainerRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  }

  const normalizeInvoices = () => {
    return [..._invoices].map((currentInvoice, index) => {
      const oldInvoice = index < invoices.length;
      const selectedCurrentInvoice = !currentInvoice.loadingDelay && currentInvoice.state === 0;
      return {
        ...currentInvoice,
        isSelected: (oldInvoice && !invoices[index].loadingDelay) ?
          invoices[index].isSelected
          : selectedCurrentInvoice
      }
    })
  }

  const toggleSelectAll = () => {
    const notSelectedPedingInvoices = invoices.filter(currentInvoice => !currentInvoice.loadingDelay && currentInvoice.state === 0 && !currentInvoice.isSelected)
    const selectedPedingInvoices = invoices.filter(currentInvoice => !currentInvoice.loadingDelay && currentInvoice.state === 0 && currentInvoice.isSelected)
    const selectedInvoices = selectAll ? (invoicesSummary.total_bills - notSelectedPedingInvoices.length) : (selectedPedingInvoices.length);
    const id_bills_array = invoicesSummary.id_bills_array.map(bill => ({ ...bill, selected: !selectAll }))
    if (selectedInvoices > 0) {
      const invoicesToRevert = !selectAll ? selectedPedingInvoices : notSelectedPedingInvoices;
      const revertSummary = invoicesToRevert.reduce((accum, i) => {
        if (selectAll) {
          accum.total_consumption = accum.total_consumption + parseFloat(i.consumption)
          accum.total_payment = accum.total_payment + parseFloat(i.total_payment)
        } else {
          accum.total_consumption = accum.total_consumption - parseFloat(i.consumption)
          accum.total_payment = accum.total_payment - parseFloat(i.total_payment)
        }
        return accum
      }, { total_consumption: parseFloat(invoicesSummary.total_consumption), total_payment: parseFloat(invoicesSummary.total_payment) })
      setInvoicesSummary({
        ...invoicesSummary,
        ...revertSummary,
        id_bills_array
      })
      setSelectAll(false)
      setInvoices(invoices.map(currentInvoice => {
        return ({
          ...currentInvoice,
          isSelected: false
        })
      }))
    } else {
      if (selectAll) {
        const revertSummary = notSelectedPedingInvoices.reduce((accum, i) => {
          accum.total_consumption = accum.total_consumption + parseFloat(i.consumption)
          accum.total_payment = accum.total_payment + parseFloat(i.total_payment)
          return accum
        }, { total_consumption: 0, total_payment: 0 })
        setInvoicesSummary({
          ...invoicesSummary,
          ...revertSummary,
          id_bills_array
        })
      }
      setSelectAll(true)
      setInvoices(invoices.map(currentInvoice => {
        return ({
          ...currentInvoice,
          isSelected: !currentInvoice.loadingDelay && currentInvoice.state === 0
        })
      }))
    }
  }

  const downloadConsolidate = async () => {
    try {
      setLoadingDownload(true);
      const servicesIds = activeServicesInvoices.map(s => s.idService)
      const blob = await SETBlobFile(
        'bills/historical',
        'POST',
        JSON.stringify({ id_user: user.idUser, month: invoicesDate.getMonth() + 1, year: invoicesDate.getFullYear(), id_services: servicesIds }),
      )
      if (!blob) throw new Error('Error getting blob')
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `consolidate-${format(invoicesDate, 'MMMM', { locale: es })}-de-${format(invoicesDate, 'yyyy')}.xlsx`
      link.click();

      window.URL.revokeObjectURL(url);
      setLoadingDownload(false);
      track('Bill matrix downloaded')
    } catch (err) {
      console.error(err);
    }
  }

  const handleInvoicesContainerScroll = () => {
    if (invoicesContainerRef.current && !moreInvoicesLoading) {
      const { scrollTop, scrollHeight, clientHeight } = invoicesContainerRef.current;

      if (scrollTop + clientHeight >= scrollHeight) {
        setCurrentPage(currentPage + 1);
      }
    }
  }

  const clearDate = (e) => {
    e.stopPropagation();
    props.setInvoicesDate(subMonths(new Date(), 1));
    setShowCalendar(false);
    setCurrentPage(0);
    setEmptyPaginationResult(false)
    setCleanDate(true);
    track('Billing all month selected')
  }

  const groupDataByAccountAndDate = (invoices) => {
    const groupedData = invoices.reduce((result, customer) => {
      let accountGroup = result.find(group => group.account === customer.account);
      if (!accountGroup) {
        accountGroup = { account: customer.account, id_account: customer.id_account, dates: [] };
        result.push(accountGroup);
      }
      const customerDate = new Date(customer?.year, customer?.month - 1, 1, 12).toISOString()

      let dateGroup = accountGroup.dates.find(group => group.date === customerDate);
      if (!dateGroup) {
        dateGroup = { date: customerDate, invoices: [] };
        accountGroup.dates = [...accountGroup.dates, dateGroup].sort();
      }

      dateGroup.invoices = [...dateGroup.invoices, { ...customer }];
      return result.sort((a, b) => a.id_account - b.id_account);
    }, []);
    return groupedData;
  };

  const handleOpenModal = async (rowData, accountIndex, dateGroupIndex, invoiceIndex) => {
    setInvoicesModalIndexes({ accountIndex, dateGroupIndex, invoiceIndex })
    if (rowData.type_content === "pdf") {
      const result = await GETS3PublicUrl(rowData.pdf_path);
      setInvoiceUrl(result);
    } else {
      setInvoiceUrl('');
      setInvoiceShow(rowData)
    }
    setZoomIn(true)
  }

  const handleClickOutside = (event) => {
    if (invoiceStateModal.current && !invoiceStateModal.current.contains(event.target)) {
      setOpenInvoiceStateModal(false);
    }
    if (accountPickerRef.current && !accountPickerRef.current.contains(event.target)) {
      setOpenAccountDrawer(false);
    }
  };

  const handleFilterPendingInvoices = (value) => {
    setFilterPendingInvoices(value)
    setCurrentPage(0);
    setEmptyPaginationResult(false);
  }

  useEffect(() => {
    track('Billing viewed')
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShowBlackScreen(showCalendar);
  }, [setShowBlackScreen, showCalendar]);

  useEffect(() => {
    setShowBlackScreen(openInvoiceStateModal);
  }, [setShowBlackScreen, openInvoiceStateModal]);

  useEffect(() => {
    setShowBlackScreen(openAccountDrawer);
  }, [setShowBlackScreen, openAccountDrawer]);

  useEffect(() => {
    if (account && !activeServicesInvoices.length) {
      loadServicesAsync();
    }
    // eslint-disable-next-line
  }, [account, loadServicesAsync]);

  useEffect(() => {
    if (activeServicesInvoices.length) {
      loadInvoicesPeriodsAsync();
    }
    // eslint-disable-next-line
  }, [activeServicesInvoices]);

  useEffect(() => {
    if (activeServicesEnergy.length && !filteredByActiveServices) {
      if (location.state?.fromEnergy) {
        history.replace({
          pathname: location.pathname,
          state: null
        })
        const activeAccountsIds = activeServicesEnergy.map(s => s.idAccount);
        props.setInvoicesDate(subMonths(invoicesDate, 1))
        setActiveServicesInvoices(_totalServices.filter(currentService =>
          activeAccountsIds.some(currentIdAccount => currentService.idAccount === currentIdAccount)
        ));
        setFromEnergyAccount(true);
      }
      setFilteredByActiveServices(true);
    }
    // eslint-disable-next-line
  }, [activeServicesEnergy, _totalServices])

  useEffect(() => {
    if (!invoicesPeriods.length) { _setInvoices([]) }

    if (activeServicesInvoices.length && invoicesPeriods?.length && filteredByActiveServices) {
      const invoicesPerPage = 20;
      if (currentPage > 0) {
        loadMoreInvoicesAsync({
          offset: (invoicesPerPage * currentPage),
          limit: invoicesPerPage,
          searchText: debounceSearchInvoices,
          sortOrder: sortOrder,
          sortField: sortField,
          month: !cleanDate ? invoicesDate.getMonth() + 1 : null,
          year: !cleanDate ? invoicesDate.getFullYear() : null,
          pendingInvoices: filterPendingInvoices
        });
      } else {
        loadInvoicesAsync({
          searchText: debounceSearchInvoices,
          sortOrder: sortOrder,
          sortField: sortField,
          month: !cleanDate ? invoicesDate.getMonth() + 1 : null,
          year: !cleanDate ? invoicesDate.getFullYear() : null,
          pendingInvoices: filterPendingInvoices
        });
      }
      setInvoiceShowIndex(0);
    } else {
      setInvoices([])
    }
    // eslint-disable-next-line
  }, [activeServicesInvoices, filteredByActiveServices, invoicesPeriods, currentPage, debounceSearchInvoices, sortOrder, sortField, invoicesDate, filterPendingInvoices]);

  useEffect(() => {
    if (cleanDate) {
      loadInvoicesSummaryAsync({ selectAll })
      return;
    }
    loadInvoicesSummaryAsync({
      month: invoicesDate.getMonth() + 1,
      year: invoicesDate.getFullYear(),
      selectAll
    })
    // eslint-disable-next-line
  }, [activeServicesInvoices, invoicesDate, cleanDate])

  useEffect(() => {
    setInvoices(normalizeInvoices())
    // eslint-disable-next-line
  }, [_invoices])

  useEffect(() => {
    if (invoiceShow) {
      if (invoiceShow?.type_content === "pdf") {
        setInvoiceUrl(`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-2.amazonaws.com/${invoiceShow?.pdf_path}`);
        setInvoicesContentLoading(false);
      }
    } else {
      setInvoiceUrl("");
    }
    // eslint-disable-next-line
  }, [invoiceShow, invoices, account]);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick)
    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [isExpanded])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounceSearchInvoices(searchInvoices)
      setCurrentPage(0)
      setEmptyPaginationResult(false)
    }, 2000)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [searchInvoices])

  const handleClosePay = () => {
    props.setStartOnboarding({ key: "payment", value: false });
  };

  const tourPayment = useTour({
    steps: stepsPayment(props.language),
    localStorageKey: "payment-tutorial",
    isOpenDrawer: props.invoicesToPay.length !== 0,
    runTour: props.payment,
    setRunTour: props.setStartOnboarding,
    key: "payment",
  });

  const tourInvoice = useTour({
    steps: stepsInvoice(props.language),
    localStorageKey: "invoice-tutorial",
    isOpenDrawer:
      !props.invoicesContentLoading && props.invoicesToPay.length === 0,
    runTour: props.invoiceOnboarding,
    setRunTour: props.setStartOnboarding,
    onTourFinish: handleClosePay,
    key: "invoice",
  });

  const activeAccounts = [...new Set(activeServicesInvoices.filter(i => i.type.name.includes('energy')).map(invoice => invoice.idAccount))];

  // eslint-disable-next-line
  const groupedData = useMemo(() => groupDataByAccountAndDate(invoices), [invoices, sortField, sortOrder]);

  const setPreviousInvoiceModal = () => {
    if (!invoiceModalIndexes) return;
    const { accountIndex, invoiceIndex, dateGroupIndex } = invoiceModalIndexes;
    let newAccountIndex = null;
    let newInvoiceIndex = null;
    let newDateGroupIndex = null;
    if (invoiceIndex !== 0) {
      newAccountIndex = accountIndex;
      newDateGroupIndex = dateGroupIndex;
      newInvoiceIndex = invoiceIndex - 1;
      return;
    } else if (dateGroupIndex !== 0) {
      newAccountIndex = accountIndex;
      newDateGroupIndex = dateGroupIndex - 1;
      const previousDate = groupedData[newAccountIndex].dates[newDateGroupIndex]
      newInvoiceIndex = previousDate.invoices.length - 1;
    } else if (accountIndex !== 0) {
      newAccountIndex = accountIndex - 1;
      const previousAccount = groupedData[newAccountIndex]
      newDateGroupIndex = previousAccount.dates.length - 1;
      const previousDate = previousAccount.dates[newDateGroupIndex]
      newInvoiceIndex = previousDate.invoices.length - 1;
    }
    if (newAccountIndex !== null && newDateGroupIndex !== null && newInvoiceIndex !== null) {
      const newInvoice = groupedData[newAccountIndex].dates[newDateGroupIndex].invoices[newInvoiceIndex];
      handleOpenModal(newInvoice, newAccountIndex, newDateGroupIndex, newInvoiceIndex)
    }
  }

  const setFollowingInvoiceModal = () => {
    if (!invoiceModalIndexes) return;
    const { accountIndex, invoiceIndex, dateGroupIndex } = invoiceModalIndexes;
    let newAccountIndex = null;
    let newInvoiceIndex = null;
    let newDateGroupIndex = null;
    const currentAccount = groupedData[accountIndex];
    const currentDate = currentAccount.dates[dateGroupIndex];
    if (invoiceIndex !== currentDate.invoices.length - 1) {
      newAccountIndex = accountIndex;
      newDateGroupIndex = dateGroupIndex;
      newInvoiceIndex = invoiceIndex + 1;
      return;
    } else if (dateGroupIndex !== currentAccount.dates.length - 1) {
      newAccountIndex = accountIndex;
      newDateGroupIndex = dateGroupIndex + 1;
      const previousDate = groupedData[newAccountIndex].dates[newDateGroupIndex]
      newInvoiceIndex = previousDate.invoices.length - 1;
    } else if (accountIndex !== groupedData.length - 1) {
      newAccountIndex = accountIndex + 1;
      const previousAccount = groupedData[newAccountIndex]
      newDateGroupIndex = previousAccount.dates.length - 1;
      const previousDate = previousAccount.dates[newDateGroupIndex]
      newInvoiceIndex = previousDate.invoices.length - 1;
    }
    if (newAccountIndex !== null && newDateGroupIndex !== null && newInvoiceIndex !== null) {
      const newInvoice = groupedData[newAccountIndex].dates[newDateGroupIndex].invoices[newInvoiceIndex];
      handleOpenModal(newInvoice, newAccountIndex, newDateGroupIndex, newInvoiceIndex)
    }
  }

  const getMonthLabel = () => {
    const strDate = stringFormatDate(props.invoicesDate, 2);
    if (!strDate || strDate.length === 0) return '';
    return strDate[0].toUpperCase() + strDate.slice(1, strDate.length)
  }

  return (
    <section className="column-start align-items-center vh-100 w-100">
      {tourPayment}
      {tourInvoice}
      <Header
        moduleName={`${props.language.invoices_main_title_dashboard}`}
        backToButton={true}
      />
      <div className={`column-start flex-grow-1 w-100 surface-bg-fill px-3 gap-3 overflow-auto ${theme}`}>
        {
          invoicesLoading ? (
            <div className="position-relative flex-grow-1 w-100">
              <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
                <ContentLoader seed={2} backgroundColor={theme === "light" ? "#ffffff" : "#141414"}
                  foregroundColor={theme === "light" ? "#d4d4d4" : "#262626"} style={{ width: "100%", height: "100%" }}>
                  <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
                </ContentLoader>
              </div>
            </div>
          ) : (
            <>
              <div
                ref={invoicesContainerRef}
                id="invoices-table-scroll"
                className={`position-relative column-start flex-grow-1 gap-3 surface-bg-surface rounded-16 overflow-auto w-100 border-fill-medium ${theme}`}
                onScroll={handleInvoicesContainerScroll}
              >
                <div className="position-sticky left-0 pt-4">
                  <p className={`px-3 label-large-semibold content-default-primary ${theme}`}>{props.language.invoices_title}</p>
                </div>
                <div className={`position-sticky left-0 row-between align-items-center px-3 pb-2 w-100 ${props.showBlackScreen ? 'zindex-5' : 'zindex-2'}`}>
                  <div className={`position-relative row-start align-items-center gap-2 p-2 surface-bg-on-surface rounded-30 ${!props.showBlackScreen ? 'border-fill-medium' : 'border-fill-solid'} ${theme}`}>
                    <Transition
                      in={props.showBlackScreen}
                      timeout={{
                        appear: 100,
                        enter: 0,
                        exit: 0,
                      }}>
                      {state => (
                        <div className={`position-absolute w-100 h-100 left-0 top-0 surface-bg-blackout rounded-30 trans-fade trans-fade-${state} ${theme}`} />
                      )}
                    </Transition>
                    <div
                      className={`position-relative row-between align-items-center gap-2 py-2 px-3 surface-on-surface-hover hover-gray border-fill-medium rounded-pill pe-pointer ${theme}`}
                      onClick={() => setOpenAccountDrawer(true)}
                    >
                      <p className={`label-medium-regular content-default-primary pe-pointer ${theme}`}>
                        {
                          energyAccounts.length === activeAccounts.length ? props.language.invoices_empty_account_selection
                            : `${activeAccounts.length} ${props.language.invoices_account_total_selection_separator} ${energyAccounts.length} ${props.language.invoices_account_total_selection}`
                        }
                      </p>
                      <div className={`position-relative row-center align-items-center pe-pointer ${openInvoiceStateModal && "zindex-4"}`}>
                        <i className={`fal fa-chevron-down content-default-primary txt-8 ${theme}`}></i>
                        <AccountDrawer fromEnergyAccount={fromEnergyAccount} openAccountDrawer={openAccountDrawer} accountPickerRef={accountPickerRef} handleClose={() => setOpenAccountDrawer(false)} />
                      </div>
                    </div>
                    <div
                      className={`row-between align-items-center gap-2 py-2 px-3 surface-on-surface-hover hover-gray border-fill-medium rounded-pill pe-pointer ${theme}`}
                      onClick={() => setShowCalendar(true)}
                    >
                      <div className={`w-100 label-medium-regular content-default-primary pe-pointer ${theme}`}>
                        {cleanDate ? props.language.invoices_empty_date_selection : getMonthLabel()}
                      </div>
                      <div className={`position-relative row-center align-items-center pe-pointer ${showCalendar ? "zindex-4" : ''} `}>
                        <i className={`fal fa-chevron-down content-default-primary txt-8 ${theme}`} />
                        <CalendarInvoices
                          show={showCalendar}
                          start={props.invoicesDate}
                          end={props.invoicesDate}
                          interval={2}
                          setDates={handleCalendar}
                          clear={clearDate}
                          cleanDate={cleanDate}
                          setShowCalendar={setShowCalendar}
                        />
                      </div>
                    </div>
                    <div
                      className={`row-between align-items-center gap-2 py-2 px-3 surface-on-surface-hover hover-gray border-fill-medium rounded-pill pe-pointer ${theme}`}
                      onClick={() => setOpenInvoiceStateModal(true)}
                    >
                      <p className={`w-100 label-medium-regular content-default-primary pe-pointer ${theme}`}>
                        {filterPendingInvoices ? props.language.invoices_pending_filter_option : props.language.invoices_empty_filter_selection}
                      </p>
                      <div className={`position-relative row-center align-items-center pe-pointer ${openInvoiceStateModal && "zindex-4"}`}>
                        <i className={`fal fa-chevron-down content-default-primary txt-8 ${theme}`}></i>
                        <Transition
                          in={openInvoiceStateModal}
                          timeout={50}>
                          {state => (
                            <div className={`position-absolute column-start align-items-center mt-3 top-100 zindex-6 trans-fade trans-fade-${state}`} style={{ width: 300 }}>
                              <div className={`invoice-state-arrow surface-bg-surface ${theme}`}></div>
                              <div ref={invoiceStateModal} className={`p-3 surface-bg-surface rounded ${theme}`}>
                                <div className={`row-between align-items-center px-2 py-3 rounded ${!filterPendingInvoices ? 'surface-bg-on-surface' : ''} ${theme}`} onClick={() => {track('Pending bills filter selected', {Bill_status_ID: 'Todas las facturas'}); handleFilterPendingInvoices(false)}}>
                                  <p className={`label-small-medium content-default-primary pe-pointer ${theme}`}>Todas las facturas</p>
                                  <i className={`txt-8 ${!filterPendingInvoices ? 'fas fa-circle-check content-color-green' : 'fal fa-circle-check content-default-secondary'} ${theme}`}></i>
                                </div>
                                <div className={`row-between align-items-center px-2 py-3 rounded ${filterPendingInvoices ? 'surface-bg-on-surface' : ''} ${theme}`} onClick={() => {track('Pending bills filter selected', {Bill_status_ID: 'Por pagar'}); handleFilterPendingInvoices(true)}}>
                                  <p className={`label-small-medium content-default-primary pe-pointer ${theme}`}>Por pagar</p>
                                  <div className="row-end align-items-center gap-2">
                                    <span className={`px-3 py-1 rounded-pill label-small-regular content-default-primary button-fill-disabled ${theme}`}>{invoicesSummary.total_bills}</span>
                                    <i className={`txt-8 ${filterPendingInvoices ? 'fas fa-circle-check content-color-green' : 'fal fa-circle-check content-default-secondary'} ${theme}`}></i>
                                  </div>
                                </div>
                              </div>
                            </div>)}
                        </Transition>
                      </div>
                    </div>
                  </div>
                  <div className={`position-relative row-start align-items-center gap-2 p-2 surface-bg-on-surface rounded-30 ${!props.showBlackScreen ? 'border-fill-medium' : 'border-fill-solid'} ${theme}`}>
                    <Transition
                      in={props.showBlackScreen}
                      timeout={{
                        appear: 100,
                        enter: 0,
                        exit: 0,
                      }}>
                      {state => (
                        <div className={`position-absolute w-100 h-100 left-0 top-0 surface-bg-blackout rounded-30 trans-fade trans-fade-${state} zindex-2 ${theme}`} />
                      )}
                    </Transition>
                    <div
                      className={`min-width-170 row-center align-items-center py-2 px-3 gap-2 border-fill-medium surface-on-surface-hover hover-gray rounded-pill pe-pointer ${theme}`}
                      onClick={downloadConsolidate}
                    >
                      {
                        loadingDownload ? (
                          <>
                            <Loader inverse size="xs" />
                            <p className={`label-medium-regular content-default-secondary pe-pointer ${theme}`}>
                              Cargando
                            </p>
                          </>
                        ) : (
                          <>
                            <i className={`fal fa-download content-default-primary txt-8 ${theme}`}></i>
                            <p className={`label-medium-regular content-default-secondary pe-pointer ${theme}`}>
                              Descargar consolidado
                            </p>
                          </>
                        )
                      }
                    </div>
                    <div
                      ref={searchInputContainerRef}
                      className={`position-relative ${isExpanded ? 'row-start' : 'row-center'} align-items-center gap-2 px-2 py-3 surface-on-surface-hover hover-gray rounded-pill pe-pointer overflow-hidden ${isExpanded ? 'border-fill-bold' : 'border-fill-medium'} ${theme}`}
                      style={{ width: isExpanded ? '200px' : '35px', transitionProperty: 'all', transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', transitionDuration: '300ms' }}
                      onClick={(e) => {
                        setIsExpanded(true)
                      }}
                    >
                      <i className={`position-absolute fal fa-search content-default-primary txt-6 ${theme}`}></i>
                      {
                        isExpanded && (
                          <div className="position-absolute left-0 flex-grow-1 row-start align-items-center ps-4">
                            <input
                              className={`max-width-130 bg-transparent border-0 txt-white label-medium-regular pe-pointer ${theme}`}
                              value={searchInvoices}
                              ref={searchInputRef}
                              onChange={(e) =>{ track('Billing searchbar clicked'); setSearchInvoices(e.target.value)}} placeholder="Buscar"
                            />
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
                <InvoiceTable
                  setInvoices={setInvoices}
                  groupedData={groupedData}
                  handleOpenModal={handleOpenModal}
                  cleanDate={cleanDate}
                />
              </div>
            </>
          )
        }
        <InvoiceSummary 
          handleFilterPendingInvoices={handleFilterPendingInvoices} 
          filterPendingInvoices={filterPendingInvoices} 
          invoices={invoices} 
          toggleSelectAll={toggleSelectAll} 
          selectAll={selectAll} 
          allAccountsSelected={energyAccounts.length === activeAccounts.length}
        />
      </div>
      <Pay
        _invoices={invoices}
        selectAll={selectAll}
      />
      {invoiceShow || invoiceUrl ? (
        <InvoiceModal
          showInvoice={zoomIn}
          setShowInvoice={setZoomIn}
          invoiceUrl={invoiceUrl}
          setPreviousInvoiceModal={setPreviousInvoiceModal}
          setFollowingInvoiceModal={setFollowingInvoiceModal}
          invoiceModalIndexes={invoiceModalIndexes}
          groupedData={groupedData}
          setInvoicesModalIndexes={setInvoicesModalIndexes}
        />
      ) : null}
    </section>
  );
};

const mapStateToProps = (state) => ({
  totalServices: state.services.totalServices,
  invoices: state.invoices.invoices,
  invoicesLoading: state.invoices.invoicesLoading,
  intervalGraph: state.energy.intervalGraph,
  invoicesDate: state.invoices.invoicesDate,
  moreInvoicesLoading: state.invoices.moreInvoicesLoading,
  activeServicesInvoices: state.services.activeServicesInvoices,
  activeServicesEnergy: state.services.activeServicesEnergy,
  showCalendar: state.shared.showCalendar,
  language: state.shared.language,
  showBlackScreen: state.shared.showBlackScreen,
  account: state.accounts.account,
  totalAccounts: state.accounts.totalAccounts,
  invoicesContentLoading: state.invoices.invoicesContentLoading,
  invoiceShow: state.invoices.invoiceDetails,
  invoicesToPay: state.invoices.invoicesToPay,
  invoicesSummary: state.invoices.invoicesSummary,
  payment: state.shared.startOnboarding.payment,
  invoiceOnboarding: state.shared.startOnboarding.invoice,
  billsStatus: state.invoices.billsStatus,
  invoicesPeriods: state.invoices.invoicesPeriods,
  currentPage: state.invoices.currentPage,
  sortField: state.invoices.sortField,
  sortOrder: state.invoices.sortOrder,
  user: state.users.user,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setShowBlackScreen: (payload) =>
    dispatch(sharedActions.setShowBlackScreen(payload)),
  setInvoicesDate: (payload) =>
    dispatch(invoicesActions.setInvoicesDate(payload)),
  setInvoicesLoading: (payload) =>
    dispatch(invoicesActions.setInvoicesLoading(payload)),
  loadInvoicesAsync: (payload) =>
    dispatch(invoicesActions.loadInvoicesAsync(payload)),
  loadInvoicesSummaryAsync: (payload) =>
    dispatch(invoicesActions.loadInvoicesSummaryAsync(payload)),
  loadMoreInvoicesAsync: (payload) =>
    dispatch(invoicesActions.loadMoreInvoicesAsync(payload)),
  setActiveServicesInvoices: (payload) =>
    dispatch(servicesActions.setActiveServicesInvoices(payload)),
  setInvoicesToPay: (payload) =>
    dispatch(invoicesActions.setInvoicesToPay(payload)),
  setShowCalendar: (payload) =>
    dispatch(sharedActions.setShowCalendar(payload)),
  loadServicesAsync: (payload) =>
    dispatch(servicesActions.loadServicesAsync(payload)),
  downloadInvoiceAsync: (payload) =>
    dispatch(invoicesActions.downloadInvoiceAsync(payload)),
  setInvoiceShow: (payload) =>
    dispatch(invoicesActions.setShowInvoiceDetails(payload)),
  setInvoiceShowIndex: (payload) =>
    dispatch(invoicesActions.setShowInvoiceDetailsIndex(payload)),
  setInvoicesContent: (payload) =>
    dispatch(invoicesActions.setInvoicesContent(payload)),
  setInvoicesContentLoading: (payload) =>
    dispatch(invoicesActions.setInvoicesContentLoading(payload)),
  setStartOnboarding: (payload) =>
    dispatch(sharedActions.setStartOnboarding(payload)),
  loadInvoicesPeriodsAsync: () =>
    dispatch(invoicesActions.loadInvoicesPeriodsAsync()),
  setFilterStatus: (payload) =>
    dispatch(invoicesActions.setFilterStatus(payload)),
  setInvoices: (payload) =>
    dispatch(invoicesActions.setInvoices(payload)),
  setCurrentPage: (payload) =>
    dispatch(invoicesActions.setCurrentPage(payload)),
  setEmptyPaginationResult: (payload) =>
    dispatch(invoicesActions.setEmptyPaginationResult(payload)),
  setInvoicesSummary: (payload) =>
    dispatch(invoicesActions.setInvoicesSummary(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
