import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Link, useLocation } from "react-router-dom";

import * as sharedActions from "../../store/actions/Shared";
import * as themeActions from "../../store/actions/Theme";

import { Transition } from "react-transition-group";

import User from "./User";

import { track } from "@amplitude/analytics-browser";

import LogoLight from '../../imgs/logo-enrg-light.svg'
import LogoDark from '../../imgs/logo-enrg-dark.svg'

import { themes } from "../../utils/enums";

const getActivePath = (location) => {
  const parts = location.pathname.split("/");
  return parts[1];
};

const Menu = (props) => {
  let user = props.user;
  const { theme } = props;
  const location = useLocation();

  const [active, setActive] = useState(getActivePath(location));

  useEffect(() => setActive(getActivePath(location)), [location]);

  return (
    <Fragment>
      <Transition in={props.showMenu} timeout={100}>
        {(state) => (
          <div
            className={`position-fixed left-0 top-0 w-100 vh-100 zindex-4 trans-fade trans-fade-${state}`}
            onClick={() => props.setMenu(false)}
          >
            <div className={`w-100 h-100 surface-bg-blackout ${theme}`}></div>
          </div>
        )}
      </Transition>
      <Transition in={props.showMenu} timeout={150}>
        {(state) => (
          <aside
            id="navigate_modules"
            style={{ paddingTop: 28, paddingBottom: 28 }}
            className={`position-fixed column-between align-items-center left-0 top-0 vh-100 surface-bg-surface border-fill-medium-horizontal zindex-4 pe-pointer trans-menu trans-menu-${state} ${theme}`}
            onMouseEnter={() => props.setMenu(true)}
            onMouseLeave={() => props.setMenu(false)}
          >
            <Link to={"/home"}>
              <img src={theme === themes.DARK ? LogoDark : LogoLight} alt="logo-neu-energy" style={{ width: 80 }} />
            </Link>
            <div className="column-start w-100 px-5 gap-4">
              <Link to={"/home"}>
                <div
                  className="overflow-hidden rounded-8"
                  onClick={() => {
                    props.setCurrentModule(0);
                  }}
                >
                  <div
                    className={`row-start align-items-center p-2 ${
                      active === "home" && `surface-bg-on-surface ${theme}`
                    }`}
                  >
                    <i
                      className={`fak txt-14 fa-energy-symbol ${
                        active === "home"
                          ? `content-color-green `
                          : "content-default-primary"
                      } ${theme}`}
                    ></i>
                    <div
                      className={`txt-8 txt-phrase ms-3 ${
                        active === "home"
                          ? `content-color-green`
                          : "content-default-primary"
                      } ${theme}`}
                    >
                      {props.language.menu_main_dashboard}
                    </div>
                  </div>
                </div>
              </Link>
              {user.capabilities?.show_section_genius && (
                <Link to={"/genius"}>
                  <div
                    className="overflow-hidden rounded-8"
                    onClick={() => {
                      props.setCurrentModule(3);
                      track("Genius viewed");
                    }}
                  >
                    <div
                      className={`row-start align-items-center p-2 ${
                        active === "genius" && `surface-bg-on-surface ${theme}`
                      }`}
                    >
                      <i
                        className={`fak txt-16 fa-genius-symbol ${
                          active === "genius"
                            ? "content-color-green"
                            : "content-default-primary"
                        } ${theme}`}
                      ></i>
                      <div
                        className={`txt-8 txt-phrase ms-3 ${
                          active === "genius"
                            ? "content-color-green"
                            : "content-default-primary"
                        } ${theme}`}
                      >
                        {props.language.menu_genius_service}
                      </div>
                    </div>
                  </div>
                </Link>
              )}
              {user.capabilities?.show_section_efficiency && (
                <Link to={"/efficiency"}>
                  <div
                    className="overflow-hidden rounded-8"
                    onClick={() => {
                      props.setCurrentModule(1);
                    }}
                  >
                    <div
                      className={`row-start align-items-center p-2 ${
                        active === "efficiency" &&
                        `surface-bg-on-surface ${theme}`
                      }`}
                    >
                      <i
                        className={`fak txt-14 fa-efficiency-symbol ${
                          active === "efficiency"
                            ? "content-color-green "
                            : "content-default-primary"
                        } ${theme}`}
                      ></i>
                      <div
                        className={`txt-8 txt-phrase ms-3 ${
                          active === "efficiency"
                            ? "content-color-green"
                            : "content-default-primary"
                        } ${theme}`}
                      >
                        {props.language.menu_efficiency_service}
                      </div>
                    </div>
                  </div>
                </Link>
              )}
              {user.capabilities?.show_section_plus && false && (
                <Link to={"/plus"}>
                  <div
                    className="overflow-hidden rounded-8"
                    onClick={() => {
                      props.setCurrentModule(2);
                    }}
                  >
                    <div
                      className={`row-start align-items-center p-2 bg-${
                        active === "plus" && "black"
                      }`}
                    >
                      <i
                        className={`fak txt-14 fa-plus-symbol txt-${
                          active === "plus" ? "green-500" : "white"
                        }`}
                      ></i>
                      <div
                        className={`txt-8 txt-white txt-phrase ms-3 txt-${
                          active === "plus" ? "green-500" : "white"
                        }`}
                      >
                        {props.language.menu_plus_service}
                      </div>
                    </div>
                  </div>
                </Link>
              )}
            </div>
            <User
              size={50}
              progress={100}
              trackWidth={2}
              indicatorWidth={2}
              indicatorColor={"#9ecc14"}
              setShowAccount={props.setShowAccount}
            />
          </aside>
        )}
      </Transition>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  showMenu: state.shared.showMenu,
  language: state.shared.language,
  user: state.users.user,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setMenu: (payload) => dispatch(sharedActions.setMenu(payload)),
  setCurrentModule: (payload) =>
    dispatch(sharedActions.setCurrentModule(payload)),
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
