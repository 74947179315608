import React from 'react'
import { connect } from 'react-redux';

import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from "date-fns/locale/en-US"

import { Link } from 'react-router-dom';

import empty_card_bill from "../../../imgs/empty-card-bill.svg";

const Invoice = (props) => {

    let locale = props.language.language_locale === "es" ? esLocale : enLocale

    const { theme } = props;

    return (
        <div className={`position-relative column-center align-items-center h-100 rounded-16 bg-green-blue-gradient ${theme}`} style={{ width: 350 }}>
            {
                props.invoiceInfo ?
                    <Link to={{ pathname: "/invoices", state: { fromEnergy: true }}} className="positon-absolute column-between column-start w-100 h-100 p-3">
                        <div className="row-between align-items-center">
                            <div className="row-start align-items-center pe-pointer gap-2">
                                <i className={`fa-solid fa-wallet txt-10 pe-pointer content-color-green ${theme}`}></i>
                                <p className={`pe-pointer label-XSmall-regular content-color-green ${theme}`}>{props.language.energy_invoice_show_bills}</p>
                            </div>
                            <p className={`pe-pointer label-XSmall-regular content-color-blue ${theme}`} style={{textTransform: 'capitalize'}}>{format(new Date(props.invoiceInfo.year, props.invoiceInfo.month - 1, 1), "MMMM yyyy", { locale: locale })}</p>
                        </div>
                        <div className="row-between align-items-center">
                            <p className={`pe-pointer heading-h6-semibold content-color-green ${theme}`}>{props.invoiceInfo.total_payment}</p>
                            {
                                props.invoiceInfo.state ?
                                    <div className="row-end align-items-end">
                                        <i className="fa-light fa-check-double txt-12 txt-green-500"></i>
                                        <p className="txt-phrase txt-semibold txt-8 txt-green-500 ps-3">{props.language.energy_invoice_state_paid}</p>
                                    </div> :
                                    <div className={`row-start align-items-center rounded-30 py-2 px-3 pe-pointer button-fill-brand ${theme}`}>
                                        <i className={`fa-regular fa-wallet txt-10 pe-pointer button-content-equal ${theme}`}></i>
                                        <p className={`ps-3 pe-pointer label-small-regular button-content-equal ${theme}`}>{props.language.energy_invoice_state_to_pay}</p>
                                    </div>
                            }
                        </div>
                    </Link> :
                    <div className="w-100 h-100 px-3 py-2 column-center align-items-center bg-gray-500 rounded-16">
                        <img src={empty_card_bill} alt="empty_card_bill" style={{ width: 105, height: 63 }} />
                        <div className="txt-gray-600 txt-phrase txt-semibold txt-8 text-wrap mt-2">{props.language.energy_invoice_no_bills}</div>
                    </div>
            }
        </div>)
}

const mapStateToProps = (state) => ({
    invoiceInfo: state.energy.invoiceInfo,
    language: state.shared.language,
    theme: state.theme.theme,
});

export default connect(mapStateToProps)(Invoice);