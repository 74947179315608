import * as invoices from '../creators/Invoices';

import { SETData, GETData, GETS3PublicUrl } from '../../services/WebServices';

export const setInvoices = payload => {
  return {
    type: invoices.SET_INVOICES,
    payload
  }
}

export const setInvoicesSummary = payload => {
  return {
    type: invoices.SET_INVOICES_SUMMARY,
    payload
  }
}

export const setInvoicesSummaryLoading = payload => {
  return {
    type: invoices.SET_INVOICES_SUMMARY_LOADING,
    payload
  }
}

export const setMoreInvoices = payload => {
  return {
    type: invoices.SET_MORE_INVOICES,
    payload
  }
}

export const updateInvoices = payload => {
  return {
    type: invoices.UPDATE_INVOICES,
    payload
  }
}

export const setInvoicesLoading = payload => {
  return {
    type: invoices.SET_INVOICES_LOADING,
    payload
  }
}

export const setDelayedInvoicesLoading = payload => {
  return {
    type: invoices.SET_DELAYED_INVOICES_LOADING,
    payload
  }
}

export const setMoreInvoicesLoading = payload => {
  return {
    type: invoices.SET_MORE_INVOICES_LOADING,
    payload
  }
}

export const setInvoicesContentLoading = payload => {
  return {
    type: invoices.SET_INVOICES_CONTENT_LOADING,
    payload
  }
}

export const setInvoicesDate = payload => {
  return {
    type: invoices.SET_INVOICES_DATE,
    payload
  }
}

export const setSelectedInvoices = payload => {
  return {
    type: invoices.SET_SELECTED_INVOICES,
    payload
  }
}

export const setBanks = payload => {
  return {
    type: invoices.SET_INVOICES_BANKS,
    payload
  }
}

export const setInvoicesToPay = payload => {
  return {
    type: invoices.SET_INVOICES_TO_PAY,
    payload
  }
}

export const setInvoicesContent = payload => {
  return {
    type: invoices.SET_INVOICES_CONTENT,
    payload
  }
}

export const setShowInvoiceDetails = payload => {
  return {
    type: invoices.SET_SHOW_INVOICE_DETAILS,
    payload
  }
}

export const setShowInvoiceDetailsContent = payload => {
  return {
    type: invoices.SET_SHOW_INVOICE_DETAILS_CONTENT,
    payload
  }
}

export const setShowInvoiceDetailsIndex = payload => {
  return {
    type: invoices.SET_SHOW_INVOICE_DETAILS_INDEX,
    payload
  }
}

export const setInvoicesPeriods = payload => {
  return {
    type: invoices.SET_INVOICES_PERIODS,
    payload
  }
}

export const setFilterStatus = payload => {
  return {
    type: invoices.SET_INVOICES_FILTER_STATUS,
    payload
  }
}

export const setTotalInvoicesByPeriod = payload => {
  return {
    type: invoices.SET_INVOICES_TOTAL_INVOICES_BY_PERIOD,
    payload
  }
}

export const setCurrentPage = payload => {
  return {
    type: invoices.SET_INVOICES_CURRENT_PAGE,
    payload
  }
}

export const setSortField = payload => {
  return {
    type: invoices.SET_INVOICES_SORT_FIELD,
    payload
  }
}

export const setSortOrder = payload => {
  return {
    type: invoices.SET_INVOICES_SORT_ORDER,
    payload
  }
}

export const setShowPay = payload => {
  return {
    type: invoices.SET_SHOW_PAY,
    payload
  }
}

export const setEmptyPaginationResult = payload => {
  return {
    type: invoices.SET_EMPTY_PAGINATION_RESULT,
    payload
  }
}

export const loadInvoicesAsync = (payload = null) => {
  return async (dispatch, getState) => {
    let emptyPaginationResult = getState().invoices.emptyPaginationResult;
    if (emptyPaginationResult) return;

    dispatch(setInvoices([]))
    dispatch(setMoreInvoicesLoading(true))
    let invoicesPeriods = getState().invoices.invoicesPeriods;
    const userId = getState().users.user.idUser;

    const servicesIds = getState().services.activeServicesInvoices
      .map(s => s.idService);

    const loadInvoices = () => {
      const metadata = {
        id_user: userId,
        id_services: servicesIds,
        sortField: payload?.sortField ? payload.sortField : 'id_service',
        sortOrder: payload?.sortOrder ? payload.sortOrder : 0,
        limit: 20,
        offset: 0,
        searchText: payload?.searchText ? payload?.searchText : '',
        pendingInvoices: payload.pendingInvoices,
        month: payload?.month ? payload.month : null,
        year: payload?.year ? payload.year : null,
      };

      SETData('bills/detail', 'POST', metadata)
        .then(response => {
          if (response !== null) {
            if (response.length === 0) {
              dispatch(setEmptyPaginationResult(true))
              return;
            };
            let periodId = response[0].id_period;

            let index = invoicesPeriods.findIndex(i => i.id_period === periodId);
            invoicesPeriods[index].invoices = response;
            setInvoicesPeriods([...invoicesPeriods]);
            const invoices = response;
            dispatch(setInvoices(JSON.parse(JSON.stringify(invoices)).map(i => ({ ...i, loadingDelay: true }))));
            dispatch(setDelayedInvoicesLoading(true))
            SETData('bills/detail/delay', 'POST', metadata)
              .then(response => {
                if (response === null) throw new Error("Error getting delay detail invoices");
                if (response.length === 0) {
                  dispatch(setEmptyPaginationResult(true))
                  return;
                };
                if (invoices.length !== response.length) throw new Error("Delayed and not delayed detail are not the same.");
                dispatch(updateInvoices(response));
              })
              .catch(err => {
                console.error(err);
              })
              .finally(() => { dispatch(setDelayedInvoicesLoading(false)) })
          };
        })
        .catch(response => console.error(response))
        .finally(() => {
          dispatch(setInvoicesLoading(false))
          dispatch(setMoreInvoicesLoading(false))
        });
    };

    loadInvoices()
  };
};

export const loadInvoicesSummaryAsync = (payload = null) => {
  return async (dispatch, getState) => {
    dispatch(setInvoicesSummaryLoading(true))

    const userId = getState().users.user.idUser;

    const servicesIds = getState()
      .services
      .activeServicesInvoices
      .map(s => s.idService);

    const metadata = {
      id_user: userId,
      id_services: servicesIds,
      month: payload?.month ? payload.month : null,
      year: payload?.year ? payload.year : null,
    }
    SETData('bills/detail/summary', 'POST', metadata)
      .then(response => {
        if (!response.length) return;
        const summary = response[0];
        dispatch(setInvoicesSummary({
          total_bills: parseInt(summary?.total_bills || 0),
          pending_bills: parseInt(summary?.pending_bills || 0),
          total_consumption: summary?.total_consumption ? parseFloat(parseFloat(summary.total_consumption).toFixed(2)) : 0,
          total_payment: summary?.total_payment ? parseFloat(parseFloat(summary.total_payment).toFixed(2)) : 0,
          id_bills_array: summary?.id_bills_array ? summary?.id_bills_array.map(s => ({ value: parseInt(s), selected: payload.selectAll })) : []
        }))
      })
      .catch(response => console.error(response))
      .finally(() => { dispatch(setInvoicesSummaryLoading(false)) })
  }
}

export const loadMoreInvoicesAsync = (payload = null) => {
  return async (dispatch, getState) => {

    let emptyPaginationResult = getState().invoices.emptyPaginationResult;
    if (emptyPaginationResult) return;
    const moreInvoicesLoading = getState().invoices.moreInvoicesLoading;
    if (moreInvoicesLoading) return;
    dispatch(setMoreInvoicesLoading(true))
    let invoicesPeriods = getState().invoices.invoicesPeriods;

    const userId = getState().users.user.idUser;

    const servicesIds = getState().services.activeServicesInvoices
      .map(s => s.idService);

    const loadInvoices = () => {
      const metadata = {
        id_user: userId,
        id_services: servicesIds,
        sortField: payload?.sortField ? payload.sortField : 'id_service',
        sortOrder: payload?.sortOrder ? payload.sortOrder : 0,
        limit: payload.limit ? payload.limit : 20,
        offset: payload.offset,
        searchText: payload?.searchText ? payload.searchText : '',
        pendingInvoices: payload.pendingInvoices,
        month: payload?.month ? payload.month : null,
        year: payload?.year ? payload.year : null,
      };
      SETData('bills/detail', 'POST', metadata)
        .then(response => {
          if (response !== null) {

            if (response.length === 0) {
              dispatch(setEmptyPaginationResult(true))
              return;
            };

            let periodId = response[0].id_period;

            let index = invoicesPeriods.findIndex(i => i.id_period === periodId);
            invoicesPeriods[index].invoices = response;
            setInvoicesPeriods([...invoicesPeriods]);
            const invoices = response;
            const clonededInvoices = JSON.parse(JSON.stringify(invoices));
            dispatch(setMoreInvoices(clonededInvoices.map(i => ({ ...i, loadingDelay: true }))));
            dispatch(setDelayedInvoicesLoading(true))
            SETData('bills/detail/delay', 'POST', metadata)
              .then(response => {
                if (response === null) throw new Error("Error getting delay detail invoices");
                if (response.length === 0) {
                  dispatch(setEmptyPaginationResult(true));
                  return;
                }
                if (invoices.length !== response.length) throw new Error("Delayed and not delayed detail are not the same.");
                dispatch(updateInvoices(response));
              })
              .catch(err => {
                console.error(err);
              })
              .finally(() => { dispatch(setDelayedInvoicesLoading(false)) })
          } else {
            dispatch(setInvoices([]));
          }
        })
        .catch(response => console.error(response))
        .finally(() => dispatch(setMoreInvoicesLoading(false)));
    };
    loadInvoices()
  };
};

export const loadInvoicesPeriodsAsync = () => {
  return (dispatch, getState) => {

    const userId = getState().users.user.idUser;

    const servicesIds = getState().services.activeServicesInvoices
      .map(s => s.idService);

    const metadata = {
      id_user: userId,
      id_services: servicesIds
    };

    SETData('bills/periods/total', 'POST', metadata)
      .then(response => {
        if (response !== null) {
          dispatch(setInvoicesPeriods(response));
        }
      })
      .catch(response => console.error(response))

  };
};

export const loadBankDataAsync = () => {
  return (dispatch) => {

    GETData("payvalida/banks", "GET")
      .then(response => {
        if (response !== null) {
          dispatch(setBanks(response.DATA));
        }
      })
      .catch(response => console.error(response));

  }
};

export const loadInvoiceDetailsContent = () => {
  return (dispatch, getState) => {

    let invoicesPeriods = getState().invoices.invoicesPeriods;

    const date = getState().invoices.invoicesDate;
    const invoicesContent = getState().invoices.invoicesContent;
    const invoiceDetails = getState().invoices.invoiceDetails;
    const activeServicesInvoices = getState().services.activeServicesInvoices.sort((a, b) => a.idService - b.idService);

    let periodInfo = invoicesPeriods.find(i => i.id_period === invoiceDetails.id_period);
    let content = periodInfo.invoicesContent?.find(s => s.id_bill === invoiceDetails.id_bill);

    const periodDate = new Date(periodInfo.billing_date);

    if (content !== undefined) {

      dispatch(setShowInvoiceDetailsContent(content));
      dispatch(setInvoicesContentLoading(false));

    } else {

      dispatch(setInvoicesContentLoading(true));

      const userId = getState().users.user.idUser;

      const servicesIdsLoaded = periodInfo.invoicesContent?.map(i => i.id_service) || [];

      const servicesIds = activeServicesInvoices
        .filter(i => !servicesIdsLoaded.includes(i.idService))
        .map(s => s.idService).slice(0, 6);

      servicesIds.push(invoiceDetails.id_service)

      const metadata = {
        id_user: userId,
        id_services: [...new Set(servicesIds)],
        month: periodDate.getMonth() + 1,
        year: periodDate.getFullYear()
      };

      SETData('bills/content', 'POST', metadata)
        .then(response => {
          if (response !== null) {

            if (periodDate.getMonth() !== date.getMonth() && periodDate.getFullYear() !== date.getFullYear()) {
              dispatch(setInvoicesContent(response));
            } else {
              dispatch(setInvoicesContent([...invoicesContent, ...response]));
            }

            content = response.find(s => s.id_service === invoiceDetails.id_service);
            dispatch(setShowInvoiceDetailsContent(content ? content : null));
            content && dispatch(setInvoicesContentLoading(false));

            let index = invoicesPeriods.findIndex(i => i.id_period === invoiceDetails.id_period);
            invoicesPeriods[index].invoicesContent = [...invoicesPeriods[index].invoicesContent || [], ...response];
            setInvoicesPeriods([...invoicesPeriods]);

          }
        })
        .catch(response => console.error(response));

    }

  }
}

export const downloadInvoiceAsync = (payload) => {
  return async () => {

    const url = await GETS3PublicUrl(payload);

    await fetch(url)
      .then(response => response.blob())
      .then(blob => {

        const n = payload.lastIndexOf('/');
        const filename = payload.substring(n + 1);

        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }
}

