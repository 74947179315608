import { Popover } from "@idui/react-popover";

import * as sharedActions from "../../store/actions/Shared";
import * as chatbotActions from "../../store/actions/Chatbot";
import * as servicesActions from "../../store/actions/Services";
import * as accountsActions from "../../store/actions/Accounts";

import React, { useState } from "react";
import { connect } from "react-redux";

import moment from "moment";

import _ from "lodash";

import { useHistory, useLocation } from "react-router-dom";

const ChatPopover = (props) => {
  const [focus, setFocus] = useState(false);
  const [show, setShow] = useState(false);

  let conversations = props.conversations;

  const loadActiveChat = props.loadActiveChat;
  const setMessages = props.setMessages;
  const setShowBlackScreen = props.setShowBlackScreen;
  const setShowBlackScreenDrawer = props.setShowBlackScreenDrawer;
  const setActiveServicesEnergy = props.setActiveServicesEnergy;
  const setActiveServicesPlus = props.setActiveServicesPlus;
  const setAccount = props.setAccount;
  const loadServicesAsync = props.loadServicesAsync;
  const theme = props.theme;

  let currentAccount = props.account;
  let totalAccounts = props.totalAccounts;
  let totalServices = props.totalServices;

  const history = useHistory();
  const location = useLocation();

  const loadConversations = async (id, services) => {
    await loadActiveChat(id);
    if (services.length) {
      await handleActiveMessage(services);
    }

    setShow(false);

    setShowBlackScreen(false);
    setShowBlackScreenDrawer(false);

    setFocus(false);
  };

  const chatHandler = () => {
    setMessages([]);
    setShow(false);
    setShowBlackScreen(false);
    setShowBlackScreenDrawer(false);
    setFocus(false);
  };

  const handleActiveMessage = async (services) => {
    const account = totalAccounts.filter((account) => account?.services?.map((x) => x?.idService).includes(services[0]));

    if (account.length && account[0]?.idAccount !== currentAccount?.idAccount) {
      setAccount(account[0]);
      await loadServicesAsync(services);
    } else {
      const filteredServices = totalServices.filter((service) => services.includes(service.idService));

      setActiveServicesEnergy(filteredServices);

      setActiveServicesPlus([_.last(filteredServices)]);
    }

    if (location.pathname === "/home") history.push("home/detail");
  };

  const ConversationComponent = ({ item, id, theme }) => {
    return (
      <div
        className={`row-between align-items-center gap-2 mb-2 pe-pointer`}
        key={id}
        onClick={() => loadConversations(item?.id_conversation, item?.services)}>
        {item?.services?.length ? (
          <Popover
            zIndex={99999}
            className={`p-0 surface-bg-on-surface shadow-none ${theme}`}
            width={240}
            trigger={"hover"}
            placement={"left"}
            arrowColor={`surface-bg-on-surface ${theme}`}
            height={180}
            offset={[-20, 0]}
            withArrow={false}
            openingAnimationTranslateDistance={0}
            content={
              <div className="w-100 p-3">
                <div className={`content-default-primary paragraph-XSmall-bold ${theme} my-2`}>Servicios consultados</div>
                <div className={`my-2 text-wrap paragraph-XSmall-regular content-default-secondary ${theme}`}>Estos son los servicios que consultaste en tu pregunta</div>
                {item?.services?.length &&
                  totalAccounts
                    .filter((account) => account?.services?.map((x) => x?.idService).includes(item?.services[0]))[0]
                    ?.services?.filter((service) => item?.services?.includes(service?.idService))
                    ?.map((service, i) => (
                      <div
                        key={i}
                        className={`flex-grow-1 my-2 text-left rounded-8 p-2 surface-bg-surface paragraph-XSmall-regular content-default-primary ${theme}`}>
                        {service?.name}
                      </div>
                    ))}
              </div>
            }>
            <div
              className={`row-center text-center align-items-center rounded-pill txt-6 txt-medium pe-pointer content-default-primary border-1 border-color-content-default-primary ${theme}`}
              style={{ width: 24, height: 24, minWidth: 24, minHeight: 24 }}>
              {item?.services?.length}
            </div>
          </Popover>
        ) : (
          <div
            className={`row-center text-center align-items-center rounded-pill txt-6 txt-medium pe-pointer content-default-primary border-1 border-color-content-default-primary ${theme}`}
            style={{ width: 24, height: 24, minWidth: 24, minHeight: 24 }}>
            {item?.services?.length}
          </div>
        )}
        <div className={`flex-grow-1 text-left rounded-pill p-2 paragraph-medium-regular pe-pointer surface-bg-on-surface content-default-primary ${theme}`}>{item?.message}</div>
      </div>
    );
  };

  return (
    <div
    className={`w-50 h-100 p-3 surface-bg-surface ${theme}`}
    style={{ overflowY: "scroll" }}>
    <div
      className={`row-center align-items-center button-fill-brand gap-2 px-3 mb-3 rounded-30 flex-grow-1 pe-pointer ${theme}`}
      style={{ height: 45 }}
      onClick={() => chatHandler()}>
      <i className={`fas fa-sparkles txt-regular txt-10 pe-pointer button-content-equal ${theme}`} />
      <p className={`label-small-regular button-content-equal ${theme}`}>Nuevo chat</p>
    </div>
    <div className={`mb-3 heading-h4-bold content-default-primary ${theme}`}>Tus chats</div>
    {conversations?.filter((item) => !moment(item?.date)?.isBefore(moment().subtract(7, "days"), "day")).length && <div className={`paragraph-XSmall-regular mb-2 mt-2 content-default-secondary ${theme}`}>Últimos 7 días</div>}
    {conversations
      ?.filter((item) => !moment(item?.date)?.isBefore(moment().subtract(7, "days"), "day"))
      .map((item, id) => (
        <ConversationComponent
          key={id}
          item={item}
          id={id}
          theme={theme}
        />
      ))}
    {conversations?.filter((item) => moment(item?.date)?.isBefore(moment().subtract(30, "days"), "day")).length && <div className={`paragraph-XSmall-regular mt-4 mb-2 content-default-secondary ${theme}`}>Últimos 30 días</div>}
    {conversations
      ?.filter((item) => moment(item?.date)?.isBefore(moment().subtract(30, "days"), "day"))
      .map((item, id) => (
        <ConversationComponent
          key={id}
          item={item}
          id={id}
          theme={theme}
        />
      ))}
  </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  language: state.shared.language,
  conversations: state.chatbot.conversations,
  totalAccounts: state.accounts.totalAccounts,
  totalServices: state.services.totalServices,
  account: state.accounts.account,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  loadActiveChat: (payload) => dispatch(chatbotActions.loadActiveChat(payload)),
  setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
  setShowBlackScreenDrawer: (payload) => dispatch(sharedActions.setShowBlackScreenDrawer(payload)),
  setMessages: (payload) => dispatch(chatbotActions.setMessages(payload)),
  setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
  setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
  setAccount: (payload) => dispatch(accountsActions.setAccount(payload)),
  loadServicesAsync: (payload) => dispatch(servicesActions.loadServicesAsync(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatPopover);
